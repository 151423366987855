/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Icons4 } from "../../icons/Icons4";
import { Icons5 } from "../../icons/Icons5";
import { Icons6 } from "../../icons/Icons6";
import { Icons7 } from "../../icons/Icons7";
import { Icons8 } from "../../icons/Icons8";
import "./style.css";

export const Button = ({ type, size, className, buttonBaseClassName, textClassName, text = "Button CTA" }) => {
  return (
    <button className={`button type-${type} ${size} ${className}`}>
      <div className={`button-base-5 ${buttonBaseClassName}`}>
        {["big", "large", "medium", "small", "x-small"].includes(size) && (
          <div
            className={`text-6 ${
              (size === "big" && type === "primary") ||
              (size === "medium" && type === "primary") ||
              (size === "small" && type === "primary") ||
              (size === "x-small" && type === "primary") ||
              type === "error" ||
              type === "outlined" ||
              type === "secondary" ||
              type === "text"
                ? textClassName
                : undefined
            }`}
          >
            {text}
          </div>
        )}

        {type === "primary" && size === "tiny" && <Icons8 className="icons-2" />}

        {type === "secondary" && size === "tiny" && <Icons7 className="icons-2" />}

        {type === "error" && size === "tiny" && <Icons6 className="icons-2" />}

        {type === "outlined" && size === "tiny" && <Icons5 className="icons-2" />}

        {type === "text" && size === "tiny" && <Icons4 className="icons-2" />}
      </div>
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["secondary", "primary", "text", "outlined", "error"]),
  size: PropTypes.oneOf(["large", "x-small", "tiny", "big", "small", "medium"]),
  text: PropTypes.string,
};
