/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const StateDefaultWrapper = ({ stateProp, className, frameClassName, frame = "/img/frame-40379-3.svg" }) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "default",
  });

  return (
    <div
      className={`state-default-wrapper state-3-${state.state} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className={`frame-3 ${frameClassName}`}>
        <img className="frame-4" alt="Frame" src={state.state === "hover" ? "/img/frame-40379-2.svg" : frame} />
        <div className="frame-5">
          <div className="frame-6">
            <div className="text-wrapper-2">Products</div>
            <p className="discover-the-best">
              Discover the best tools used by millions of entrepreneurs&nbsp;&nbsp;and professionals everyday
            </p>
          </div>
          <button className="button-2">
            <div className="button-base-2">
              <div className="text-3">Explore Now</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };
  }

  return state;
}

StateDefaultWrapper.propTypes = {
  stateProp: PropTypes.oneOf(["hover", "default"]),
  frame: PropTypes.string,
};
