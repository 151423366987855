/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icon2 = ({ className }) => {
  return (
    <svg
      className={`icon-2 ${className}`}
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3.66443 4.21289L6.42849 6.97695L9.19255 4.21289"
        stroke="#075A5E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.15169"
      />
    </svg>
  );
};
