/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Text = ({ property1 }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <div
      className={`text property-1-4-${state.property1}`}
      onClick={() => {
        dispatch("click");
      }}
    >
      <div className="lorem-ipsum-is">
        {state.property1 === "default" && (
          <>
            <p className="span-wrapper">
              <span className="span">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took{" "}
              </span>
            </p>
            <p className="span-wrapper">
              <span className="text-wrapper-11">a galley of type and scrambled it to make a type specimen book</span>
            </p>
            <p className="span-wrapper">
              <span className="span">
                . It has survived not only five centuries, but also the leap into electronic typesetting, remaining
                essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including
                versions of Lorem Ipsum.
              </span>
            </p>
          </>
        )}

        {["variant-2", "variant-3", "variant-4", "variant-5"].includes(state.property1) && (
          <>
            <img
              className="rectangle"
              alt="Rectangle"
              src={
                state.property1 === "variant-3"
                  ? "/img/rectangle-46-2.svg"
                  : state.property1 === "variant-4"
                  ? "/img/rectangle-46-1.svg"
                  : state.property1 === "variant-5"
                  ? "/img/rectangle-46.svg"
                  : "/img/rectangle-46-3.svg"
              }
            />
            <p className="lorem-ipsum-is-2">
              <span className="text-wrapper-12">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took{" "}
              </span>
              <span className="text-wrapper-11">a gallery of type and scrambled it to make a type specimen book</span>
              <span className="text-wrapper-12">
                . It has survived not only five centuries, but also the leap into electronic typesetting, remaining
                essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including
                versions of Lorem Ipsum.
              </span>
            </p>
            <div className="highlight">
              <div className="polygon-wrapper">
                <img className="polygon" alt="Polygon" src="/img/polygon-1.svg" />
              </div>
              <div className="frame-15">
                <div className="frame-16">
                  <div className="ellipse" />
                </div>
                <img className="line" alt="Line" src="/img/line-20-1.svg" />
                <div
                  className="frame-16"
                  onClick={() => {
                    dispatch("click_4936");
                  }}
                >
                  <div className="ellipse-wrapper">
                    <div className="ellipse-2" />
                  </div>
                </div>
                <img className="line" alt="Line" src="/img/line-20-1.svg" />
                <div
                  className="frame-16"
                  onClick={() => {
                    dispatch("click_4966");
                  }}
                >
                  <div className="ellipse-3" />
                </div>
                <img className="line" alt="Line" src="/img/line-20-1.svg" />
                <div
                  className="frame-16"
                  onClick={() => {
                    dispatch("click_4996");
                  }}
                >
                  <div className="ellipse-4" />
                </div>
                <img className="line" alt="Line" src="/img/line-20-1.svg" />
                <div className="text-wrapper-13">NOTE</div>
                <img className="line" alt="Line" src="/img/line-20-1.svg" />
                <div className="text-wrapper-13">SHARE</div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

function reducer(state, action) {
  if (state.property1 === "default") {
    switch (action) {
      case "click":
        return {
          property1: "variant-2",
        };
    }
  }

  if (state.property1 === "variant-2") {
    switch (action) {
      case "click_4936":
        return {
          property1: "variant-3",
        };
    }
  }

  if (state.property1 === "variant-3") {
    switch (action) {
      case "click_4966":
        return {
          property1: "variant-4",
        };
    }
  }

  if (state.property1 === "variant-4") {
    switch (action) {
      case "click_4996":
        return {
          property1: "variant-5",
        };
    }
  }

  return state;
}

Text.propTypes = {
  property1: PropTypes.oneOf(["default", "variant-5", "variant-2", "variant-3", "variant-4"]),
};
