import React, { useEffect } from "react";
import { Avatar } from "../../components/Avatar";
import { Button } from "../../components/Button";
import { ButtonBase } from "../../components/ButtonBase";
import { ButtonPrimaryWith } from "../../components/ButtonPrimaryWith";
import { Component } from "../../components/Component";
import { Divider } from "../../components/Divider";
import { Header } from "../../components/Header";
import { IconSearchFill } from "../../components/IconSearchFill";
import { NavLabels } from "../../components/NavLabels";
import { ShopiconsRegular } from "../../components/ShopiconsRegular";
import { StateDefaultWrapper } from "../../components/StateDefaultWrapper";
import { Tab } from "../../components/Tab";
import { Text } from "../../components/Text";
import { Toolbar } from "../../components/Toolbar";
import { Upvote } from "../../components/Upvote";
import { Icon2 } from "../../icons/Icon2";
import { Icon3 } from "../../icons/Icon3";
import { Icons } from "../../icons/Icons";
import { Icons1 } from "../../icons/Icons1";
import "./style.css";

export const Element = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = `top left`;
  });
  return (
    <div className="element">
      <div className="div-3">
        <div className="overlap">
          <div className="overlap-group-wrapper">
            <div className="overlap-group-2">
              <div className="ellipse-5" />
              <img
                className="apple-multi-device"
                alt="Apple multi device"
                src="/img/apple-multi-device-website-mockup-template-64d16953f51c025dd0861.png"
              />
            </div>
          </div>
          <div className="frame-20">
            <div className="heading-wrapper">
              <div className="heading">
                <div className="text-wrapper-15">Humble</div>
                <p className="text-wrapper-16">
                  Community driven platform by the makers for the makers
                </p>
              </div>
            </div>
            <div className="group-2">
              <div className="frame-21">
                <div className="UIX">
                  <div className="frame-wrapper">
                    <div className="frame-22">
                      <div className="group-3">
                        <div className="overlap-group-3">
                          <img
                            className="line-2"
                            alt="Line"
                            src="/img/line-18-4.svg"
                          />
                          <img
                            className="line-3"
                            alt="Line"
                            src="/img/line-19-5.svg"
                          />
                        </div>
                      </div>
                      <div className="text-wrapper-17"> SaaS</div>
                    </div>
                  </div>
                </div>
                <div className="UIX-2">
                  <div className="frame-wrapper">
                    <div className="frame-22">
                      <div className="group-3">
                        <div className="overlap-group-3">
                          <img
                            className="line-2"
                            alt="Line"
                            src="/img/line-18-4.svg"
                          />
                          <img
                            className="line-3"
                            alt="Line"
                            src="/img/line-19-5.svg"
                          />
                        </div>
                      </div>
                      <div className="text-wrapper-17">Mobile App</div>
                    </div>
                  </div>
                </div>
                <div className="UIX-3">
                  <div className="frame-wrapper">
                    <div className="frame-22">
                      <div className="group-3">
                        <div className="overlap-group-3">
                          <img
                            className="line-2"
                            alt="Line"
                            src="/img/line-18-4.svg"
                          />
                          <img
                            className="line-3"
                            alt="Line"
                            src="/img/line-19-4.svg"
                          />
                        </div>
                      </div>
                      <div className="text-wrapper-17">Web App</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-18">Industry</div>
            </div>
          </div>
        </div>
        <div className="frame-23">
          <p className="text-wrapper-19">
            99xStudio set new bars for impeccable designs, setting us up for a
            successful market launch of our platform. We absolutely loved
            working with them they were highly responsive to address any
            concerns promptly and efficiently.
          </p>
          <div className="div-4">
            <div className="frame-24">
              <div className="frame-25">
                <div className="text-wrapper-20">Ronak Rai</div>
                <div className="text-wrapper-21">
                  Creative Director, Dallas Inc
                </div>
              </div>
            </div>
            <a
              className="text-wrapper-22"
              href="https://clutch.co/profile/99xstudio#review-2173818"
              rel="noopener noreferrer"
              target="_blank"
            >
              Read the complete review
            </a>
          </div>
        </div>
        <div className="frame-26">
          <div className="overlap-2">
            <img
              className="homepage"
              alt="Homepage"
              src="/img/homepage-2.png"
            />
            <div className="rectangle-2" />
            <img
              className="homepage-2"
              alt="Homepage"
              src="/img/homepage-1.png"
            />
          </div>
          <div className="frame-27">
            <div className="frame-28">
              <div className="text-wrapper-23">Meet Humble</div>
              <p className="a-community-driven">
                A community-driven platform designed for the next-gen users and
                creators to access content - whether it’s products, blogs,
                podcasts, or videos - You got it here in all one place from
                topics ranging from money to business to mental health — The
                best part is it’s all curated! <br />
                <br />
                Humble also offers a space for creators and makers to take their
                products and content to create, share and monetise their
                knowledge and expertise in any medium in front of a user to whom
                it matters the most.
              </p>
            </div>
          </div>
        </div>
        <div className="frame-29">
          <div className="text-wrapper-24">Our strategy</div>
          <p className="text-wrapper-25">
            We started with our research to identify the gaps in the current
            market and get a holistic understanding of the users to define the
            need and prioritise the product features. Our research, gave us
            clear indications for the need for a curated catalogue for content -
            We built information architecture around that idea of allowing users
            to seamlessly switch between content and context and modern
            accessibility features making content snack-able and consumable for
            outcome. and for the creators to target their audience with seamless
            content creation features, to enable optimal eyeballs and reach the
            audience they want to. From here, we started drawing a clean
            storyline with — the Lo-fid wireframes.
          </p>
        </div>
        <div className="frame-30">
          <div className="text-wrapper-26">
            Meticulously crafted visual language
          </div>
          <div className="overlap-3">
            <img className="img-3" alt="Element" src="/img/6.png" />
            <img className="img-4" alt="Element" src="/img/8.png" />
            <img className="img-5" alt="Element" src="/img/11.png" />
            <img className="img-6" alt="Element" src="/img/12.png" />
            <img className="img-7" alt="Element" src="/img/9.png" />
            <img className="img-8" alt="Element" src="/img/10.png" />
          </div>
        </div>
        <img className="ipad-pro" alt="Ipad pro" src="/img/ipad-pro-12-2.png" />
        <img
          className="ipad-pro-2"
          alt="Ipad pro"
          src="/img/ipad-pro-12-3.png"
        />
        <div className="frame-31">
          <div className="frame-32">
            <div className="frame-33">
              <div className="text-wrapper-27">The Challenge</div>
            </div>
            <div className="frame-34">
              <p className="every-entrepreneur">
                Every entrepreneur can relate to the overwhelming nature of the
                process of building something - you start with an idea, and then
                you hit a roadblock - A quick search on Google or youtube leaves
                you more confused than ever with the sheer amount of content,
                and products, services and people with 200 solutions.
                <br />
                <br />
                Now it&#39;s more confusing than ever as to how to fix the
                problem statement, who we listen to, and even if we figure those
                out, go through the painful trial and error - if only there were
                a curated platform that gives you curated insights to act on.
              </p>
              <p className="as-a-creator-and">
                As a creator and maker, you need more avenues to gain traction
                and eyeballs even if you do - connecting all your content in one
                - is a significant challenge considering the platforms, mediums,
                monetisation standards, metrics, parameters, and so
                on.&nbsp;&nbsp; <br />
                <br />
                Building a product and getting it in front of the user to whom
                it matters is more complex than ever.&nbsp;&nbsp;
                <br />
                <br />
                If only there were a single viewpoint/space for all your
                content, with a focused user group that cares.
              </p>
            </div>
          </div>
        </div>
        <div className="frame-35">
          <div className="home-page">
            <div className="overlap-4">
              <div className="footers">
                <div className="frame-36">
                  <div className="frame-37">
                    <div className="frame-38">
                      <div className="image-wrapper">
                        <img
                          className="image-2"
                          alt="Image"
                          src="/img/divider.png"
                        />
                      </div>
                      <img
                        className="social-buttons"
                        alt="Social buttons"
                        src="/img/divider.png"
                      />
                    </div>
                    <div className="frame-39">
                      <div className="div-5">
                        <div className="text-wrapper-28">Heading 1</div>
                        <div className="text-wrapper-29">Our Team</div>
                        <div className="text-wrapper-29">Company Vision</div>
                        <div className="text-wrapper-29">Our History</div>
                        <div className="text-wrapper-29">Instrutors</div>
                      </div>
                      <div className="div-5">
                        <div className="text-wrapper-28">Heading 2</div>
                        <div className="text-wrapper-30">The Blog</div>
                        <div className="text-wrapper-29">Downloads</div>
                        <div className="text-wrapper-30">Change Log</div>
                        <div className="text-wrapper-30">Slack Channel</div>
                      </div>
                      <div className="div-5">
                        <div className="text-wrapper-28">Heading 3</div>
                        <div className="text-wrapper-29">New Features</div>
                        <div className="text-wrapper-29">Roadmap</div>
                        <div className="text-wrapper-29">Product Demo</div>
                        <div className="text-wrapper-29">Pricing</div>
                      </div>
                      <div className="div-5">
                        <div className="text-wrapper-28">Heading 4</div>
                        <div className="text-wrapper-29">New Features</div>
                        <div className="text-wrapper-29">Roadmap</div>
                        <div className="text-wrapper-29">Product Demo</div>
                        <div className="text-wrapper-29">Pricing</div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="divider-2"
                    alt="Divider"
                    src="/img/divider-2.png"
                  />
                  <div className="text-wrapper-31">
                    Copyright 2023 humble.inc
                  </div>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-41">
                  <div className="text-wrapper-32">
                    Frequently Asked Questions
                  </div>
                  <div className="frame-42">
                    <div className="frame-43">
                      <img
                        className="line-4"
                        alt="Line"
                        src="/img/divider-2.png"
                      />
                      <div className="div-4">
                        <div className="frame-44">
                          <div className="text-wrapper-33">
                            What is Explore Utilities
                          </div>
                          <p className="there-are-over">
                            <span className="text-wrapper-34">
                              There are over 500 perks you can use with Humble+.
                              <br />
                            </span>
                            <span className="text-wrapper-35">
                              These perks are the result of a global
                              collaboration between business and service brands
                              to give you access to some of the most
                              sought-after travel, entertainment, products,
                              experiences and services.
                            </span>
                          </p>
                        </div>
                        <div className="frame-45">
                          <div className="overlap-5">
                            <div className="ellipse-6" />
                            <div className="frame-46">
                              <div className="rectangle-3" />
                              <div className="rectangle-4" />
                            </div>
                          </div>
                          <div className="overlap-group-4">
                            <img
                              className="vector-5"
                              alt="Vector"
                              src="/img/divider.png"
                            />
                            <img
                              className="vector-6"
                              alt="Vector"
                              src="/img/divider.png"
                            />
                          </div>
                        </div>
                      </div>
                      <img
                        className="line-5"
                        alt="Line"
                        src="/img/divider-2.png"
                      />
                      <div className="div-4">
                        <p className="text-wrapper-36">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry
                        </p>
                        <div className="frame-45">
                          <div className="ellipse-7" />
                          <ShopiconsRegular />
                        </div>
                      </div>
                      <img
                        className="line-6"
                        alt="Line"
                        src="/img/divider-2.png"
                      />
                      <div className="div-4">
                        <p className="text-wrapper-36">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry
                        </p>
                        <div className="frame-45">
                          <div className="ellipse-7" />
                          <ShopiconsRegular />
                        </div>
                      </div>
                      <img
                        className="line-7"
                        alt="Line"
                        src="/img/divider-2.png"
                      />
                      <div className="frame-47">
                        <p className="text-wrapper-36">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry
                        </p>
                        <div className="frame-45">
                          <div className="ellipse-7" />
                          <ShopiconsRegular />
                        </div>
                      </div>
                      <img
                        className="line-8"
                        alt="Line"
                        src="/img/divider-2.png"
                      />
                      <div className="frame-47">
                        <p className="text-wrapper-36">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry
                        </p>
                        <div className="frame-45">
                          <div className="ellipse-7" />
                          <ShopiconsRegular />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-6">
              <div className="rectangle-5" />
              <div className="frame-48">
                <div className="text-wrapper-32">What&#39;s hot.</div>
                <p className="text-wrapper-37">
                  Here are some of the most in-demand deals according to our
                  users.
                </p>
              </div>
              <div className="frame-49">
                <div className="frame-50">
                  <div className="frame-51">
                    <div className="frame-52">
                      <div className="rectangle-6" />
                      <div className="frame-53">
                        <div className="text-wrapper-38">Explore Legal</div>
                        <p className="text-wrapper-39">
                          500+ exclusive perks for Entrepreneurs
                        </p>
                      </div>
                    </div>
                    <div className="text-wrapper-40">Save up to ₹2000</div>
                    <p className="text-wrapper-41">
                      90% off the 1st year (then 50% off the 2nd year)
                    </p>
                    <div className="overlap-group-5">
                      <img
                        className="line-9"
                        alt="Line"
                        src="/img/divider.png"
                      />
                      <img
                        className="rectangle-7"
                        alt="Rectangle"
                        src="/img/divider.png"
                      />
                    </div>
                    <div className="frame-54">
                      <div className="text-wrapper-42">humble+</div>
                    </div>
                  </div>
                  <div className="frame-51">
                    <div className="overlap-group-6">
                      <div className="frame-55">
                        <div className="rectangle-6" />
                        <div className="frame-53">
                          <div className="text-wrapper-38">Explore Design</div>
                          <p className="text-wrapper-39">
                            500+ exclusive perks for Entrepreneurs
                          </p>
                        </div>
                      </div>
                      <div className="text-wrapper-43">New!</div>
                    </div>
                    <div className="text-wrapper-44">Save up to ₹2000</div>
                    <p className="text-wrapper-45">
                      200 in credits for 3 months
                    </p>
                    <div className="overlap-7">
                      <img
                        className="line-9"
                        alt="Line"
                        src="/img/divider.png"
                      />
                      <img
                        className="rectangle-7"
                        alt="Rectangle"
                        src="/img/divider.png"
                      />
                    </div>
                    <div className="frame-54">
                      <div className="text-wrapper-42">humble+</div>
                    </div>
                  </div>
                  <div className="frame-51">
                    <div className="frame-52">
                      <div className="rectangle-6" />
                      <div className="frame-53">
                        <div className="text-wrapper-38">Explore Sales</div>
                        <p className="text-wrapper-39">
                          500+ exclusive perks for Entrepreneurs
                        </p>
                      </div>
                    </div>
                    <div className="overlap-8">
                      <div className="overlap-9">
                        <div className="text-wrapper-46">Save up to ₹2000</div>
                        <img
                          className="line-10"
                          alt="Line"
                          src="/img/line-1-17.svg"
                        />
                      </div>
                      <img
                        className="rectangle-8"
                        alt="Rectangle"
                        src="/img/rectangle-8-9.png"
                      />
                    </div>
                    <p className="text-wrapper-41">
                      90% off the 1st year (then 50% off the 2nd year)
                    </p>
                  </div>
                  <div className="frame-51">
                    <div className="frame-52">
                      <div className="rectangle-6" />
                      <div className="frame-53">
                        <div className="text-wrapper-38">Explore Finance</div>
                        <p className="text-wrapper-39">
                          500+ exclusive perks for Entrepreneurs
                        </p>
                      </div>
                    </div>
                    <div className="overlap-group-7">
                      <div className="text-wrapper-47">Save up to ₹2000</div>
                      <img
                        className="line-11"
                        alt="Line"
                        src="/img/line-1-16.svg"
                      />
                      <div className="frame-56">
                        <div className="text-wrapper-42">humble+</div>
                      </div>
                      <img
                        className="rectangle-9"
                        alt="Rectangle"
                        src="/img/rectangle-8-8.png"
                      />
                    </div>
                    <p className="text-wrapper-41">
                      90% off the 1st year (then 50% off the 2nd year)
                    </p>
                  </div>
                </div>
                <div className="frame-50">
                  <div className="frame-51">
                    <div className="frame-52">
                      <div className="rectangle-6" />
                      <div className="frame-53">
                        <div className="text-wrapper-38">Explore Legal</div>
                        <p className="text-wrapper-39">
                          500+ exclusive perks for Entrepreneurs
                        </p>
                      </div>
                    </div>
                    <div className="text-wrapper-40">Save up to ₹2000</div>
                    <p className="text-wrapper-41">
                      90% off the 1st year (then 50% off the 2nd year)
                    </p>
                    <div className="overlap-group-8">
                      <img
                        className="line-9"
                        alt="Line"
                        src="/img/divider.png"
                      />
                      <img
                        className="rectangle-7"
                        alt="Rectangle"
                        src="/img/divider.png"
                      />
                    </div>
                  </div>
                  <div className="frame-51">
                    <div className="overlap-group-6">
                      <div className="frame-55">
                        <div className="rectangle-6" />
                        <div className="frame-53">
                          <div className="text-wrapper-38">Explore Design</div>
                          <p className="text-wrapper-39">
                            500+ exclusive perks for Entrepreneurs
                          </p>
                        </div>
                      </div>
                      <div className="text-wrapper-43">New!</div>
                    </div>
                    <div className="text-wrapper-44">Save up to ₹2000</div>
                    <p className="text-wrapper-45">
                      200 in credits for 3 months
                    </p>
                    <div className="overlap-10">
                      <img
                        className="line-9"
                        alt="Line"
                        src="/img/divider.png"
                      />
                      <img
                        className="rectangle-7"
                        alt="Rectangle"
                        src="/img/divider.png"
                      />
                    </div>
                  </div>
                  <div className="frame-51">
                    <div className="frame-52">
                      <div className="rectangle-6" />
                      <div className="frame-53">
                        <div className="text-wrapper-38">Explore Sales</div>
                        <p className="text-wrapper-39">
                          500+ exclusive perks for Entrepreneurs
                        </p>
                      </div>
                    </div>
                    <div className="text-wrapper-40">Save up to ₹2000</div>
                    <p className="text-wrapper-41">
                      90% off the 1st year (then 50% off the 2nd year)
                    </p>
                    <img
                      className="line-12"
                      alt="Line"
                      src="/img/line-1-13.svg"
                    />
                    <img
                      className="rectangle-10"
                      alt="Rectangle"
                      src="/img/rectangle-8-5.png"
                    />
                  </div>
                  <div className="frame-51">
                    <div className="frame-52">
                      <div className="rectangle-6" />
                      <div className="frame-53">
                        <div className="text-wrapper-38">Explore Finance</div>
                        <p className="text-wrapper-39">
                          500+ exclusive perks for Entrepreneurs
                        </p>
                      </div>
                    </div>
                    <div className="overlap-group-7">
                      <div className="text-wrapper-47">Save up to ₹2000</div>
                      <img
                        className="line-11"
                        alt="Line"
                        src="/img/line-1-12.svg"
                      />
                      <div className="frame-56">
                        <div className="text-wrapper-42">humble+</div>
                      </div>
                      <img
                        className="rectangle-9"
                        alt="Rectangle"
                        src="/img/rectangle-8-4.png"
                      />
                    </div>
                    <p className="text-wrapper-41">
                      90% off the 1st year (then 50% off the 2nd year)
                    </p>
                  </div>
                </div>
                <button className="button-5">
                  <div className="button-base-6">
                    <div className="text-7">View all Products</div>
                  </div>
                </button>
              </div>
            </div>
            <div className="frame-57">
              <div className="div-4">
                <div className="frame-58">
                  <div className="text-wrapper-48">Home</div>
                  <div className="text-wrapper-49">System</div>
                  <div className="text-wrapper-49">Trending</div>
                  <div className="text-wrapper-49">Marketplace</div>
                  <div className="text-wrapper-49">Resource Centre</div>
                  <div className="text-wrapper-49">Mental Health</div>
                  <div className="text-wrapper-49">Members</div>
                  <div className="text-wrapper-49">News</div>
                </div>
                <div className="frame-59">
                  <div className="text-wrapper-50">Sign In</div>
                  <button className="button-6">
                    <button className="button-base-7">
                      <div className="text-8">Sign Up</div>
                    </button>
                  </button>
                </div>
              </div>
            </div>
            <div className="frame-60">
              <div className="div-4">
                <div className="frame-61">
                  <div className="image-wrapper">
                    <img
                      className="image-3"
                      alt="Image"
                      src="/img/divider.png"
                    />
                  </div>
                  <div className="searchbar">
                    <div className="frame-62">
                      <IconSearchFill
                        className="icon-search-fill-false"
                        iconSearchFill="/img/divider.png"
                      />
                      <div className="text-wrapper-51">Search products</div>
                    </div>
                  </div>
                </div>
                <div className="frame-61">
                  <div className="frame-63">
                    <div className="text-wrapper-49">En</div>
                    <img
                      className="frame-64"
                      alt="Frame"
                      src="/img/divider.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-65">
              <p className="text-wrapper-52">
                Build great businesses with the best resources
              </p>
              <p className="text-wrapper-53">
                Discover and connect with the best resources from around the
                world. Share your own finds and connect with other
                entrepreneurs.
              </p>
              <button className="button-5">
                <button className="button-base-8">
                  <div className="text-7">Get Started</div>
                </button>
              </button>
            </div>
            <div className="frame-66">
              <div className="text-wrapper-32">New Products</div>
              <p className="text-wrapper-37">
                Here are some of the most in-demand deals according to our
                users.
              </p>
            </div>
            <div className="frame-67">
              <div className="frame-68">
                <div className="frame-50">
                  <div className="frame-51">
                    <div className="overlap-group-6">
                      <div className="frame-55">
                        <div className="rectangle-6" />
                        <div className="frame-53">
                          <div className="text-wrapper-38">Explore Design</div>
                          <p className="text-wrapper-39">
                            500+ exclusive perks for Entrepreneurs
                          </p>
                        </div>
                      </div>
                      <div className="text-wrapper-43">New!</div>
                    </div>
                    <div className="text-wrapper-44">Save up to ₹2000</div>
                    <p className="text-wrapper-45">
                      200 in credits for 3 months
                    </p>
                    <img
                      className="line-13"
                      alt="Line"
                      src="/img/divider.png"
                    />
                    <div className="frame-54">
                      <div className="text-wrapper-42">humble+</div>
                    </div>
                  </div>
                  <div className="frame-51">
                    <div className="overlap-group-6">
                      <div className="frame-55">
                        <div className="rectangle-6" />
                        <div className="frame-53">
                          <div className="text-wrapper-38">Explore Design</div>
                          <p className="text-wrapper-39">
                            500+ exclusive perks for Entrepreneurs
                          </p>
                        </div>
                      </div>
                      <div className="text-wrapper-43">New!</div>
                    </div>
                    <div className="text-wrapper-44">Save up to ₹2000</div>
                    <p className="text-wrapper-45">
                      200 in credits for 3 months
                    </p>
                    <img
                      className="line-14"
                      alt="Line"
                      src="/img/divider.png"
                    />
                  </div>
                  <div className="frame-51">
                    <div className="overlap-group-6">
                      <div className="frame-55">
                        <div className="rectangle-6" />
                        <div className="frame-53">
                          <div className="text-wrapper-38">Explore Design</div>
                          <p className="text-wrapper-39">
                            500+ exclusive perks for Entrepreneurs
                          </p>
                        </div>
                      </div>
                      <div className="text-wrapper-43">New!</div>
                    </div>
                    <div className="text-wrapper-44">Save up to ₹2000</div>
                    <p className="text-wrapper-45">
                      200 in credits for 3 months
                    </p>
                    <img
                      className="line-15"
                      alt="Line"
                      src="/img/divider.png"
                    />
                    <div className="frame-54">
                      <div className="text-wrapper-42">humble+</div>
                    </div>
                  </div>
                  <div className="frame-51">
                    <div className="overlap-group-6">
                      <div className="frame-55">
                        <div className="rectangle-6" />
                        <div className="frame-53">
                          <div className="text-wrapper-38">Explore Design</div>
                          <p className="text-wrapper-39">
                            500+ exclusive perks for Entrepreneurs
                          </p>
                        </div>
                      </div>
                      <div className="text-wrapper-43">New!</div>
                    </div>
                    <div className="text-wrapper-44">Save up to ₹2000</div>
                    <p className="text-wrapper-45">
                      200 in credits for 3 months
                    </p>
                    <img
                      className="line-16"
                      alt="Line"
                      src="/img/divider.png"
                    />
                  </div>
                </div>
              </div>
              <div className="frame-68">
                <div className="frame-50">
                  <div className="frame-51">
                    <div className="overlap-group-6">
                      <div className="frame-55">
                        <div className="rectangle-6" />
                        <div className="frame-53">
                          <div className="text-wrapper-38">Explore Design</div>
                          <p className="text-wrapper-39">
                            500+ exclusive perks for Entrepreneurs
                          </p>
                        </div>
                      </div>
                      <div className="text-wrapper-43">New!</div>
                    </div>
                    <div className="text-wrapper-44">Save up to ₹2000</div>
                    <p className="text-wrapper-45">
                      200 in credits for 3 months
                    </p>
                    <img
                      className="line-17"
                      alt="Line"
                      src="/img/divider.png"
                    />
                    <div className="frame-54">
                      <div className="text-wrapper-42">humble+</div>
                    </div>
                  </div>
                  <div className="frame-51">
                    <div className="overlap-group-6">
                      <div className="frame-55">
                        <div className="rectangle-6" />
                        <div className="frame-53">
                          <div className="text-wrapper-38">Explore Design</div>
                          <p className="text-wrapper-39">
                            500+ exclusive perks for Entrepreneurs
                          </p>
                        </div>
                      </div>
                      <div className="text-wrapper-43">New!</div>
                    </div>
                    <div className="text-wrapper-44">Save up to ₹2000</div>
                    <p className="text-wrapper-45">
                      200 in credits for 3 months
                    </p>
                    <img
                      className="line-18"
                      alt="Line"
                      src="/img/divider.png"
                    />
                  </div>
                  <div className="frame-51">
                    <div className="overlap-group-6">
                      <div className="frame-55">
                        <div className="rectangle-6" />
                        <div className="frame-53">
                          <div className="text-wrapper-38">Explore Design</div>
                          <p className="text-wrapper-39">
                            500+ exclusive perks for Entrepreneurs
                          </p>
                        </div>
                      </div>
                      <div className="text-wrapper-43">New!</div>
                    </div>
                    <div className="text-wrapper-44">Save up to ₹2000</div>
                    <p className="text-wrapper-45">
                      200 in credits for 3 months
                    </p>
                    <img
                      className="line-19"
                      alt="Line"
                      src="/img/divider.png"
                    />
                    <div className="frame-54">
                      <div className="text-wrapper-42">humble+</div>
                    </div>
                  </div>
                  <div className="frame-51">
                    <div className="overlap-group-6">
                      <div className="frame-55">
                        <div className="rectangle-6" />
                        <div className="frame-53">
                          <div className="text-wrapper-38">Explore Design</div>
                          <p className="text-wrapper-39">
                            500+ exclusive perks for Entrepreneurs
                          </p>
                        </div>
                      </div>
                      <div className="text-wrapper-43">New!</div>
                    </div>
                    <div className="text-wrapper-44">Save up to ₹2000</div>
                    <p className="text-wrapper-45">
                      200 in credits for 3 months
                    </p>
                    <img
                      className="line-20"
                      alt="Line"
                      src="/img/divider.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-wrapper-54">Explore the marketplace.</div>
            <div className="overlap-11">
              <div className="frame-69">
                <div className="frame-70">
                  <div className="frame-71">
                    <div className="frame-72">
                      <div className="frame-73">
                        <img
                          className="profile-user"
                          alt="Profile user"
                          src="/img/divider.png"
                        />
                        <div className="text-wrapper-55">Published in name</div>
                        <div className="ellipse-8" />
                        <div className="text-wrapper-55">Nov 12, 2022</div>
                        <div className="ellipse-8" />
                        <img
                          className="star"
                          alt="Star"
                          src="/img/divider.png"
                        />
                        <div className="text-wrapper-55">Humble+ Only</div>
                      </div>
                      <div className="frame-74">
                        <div className="text-wrapper-56">Notion</div>
                        <p className="text-wrapper-55">
                          Organize teamwork and increase productivity
                        </p>
                      </div>
                      <div className="frame-75">
                        <div className="frame-76">
                          <div className="frame-59">
                            <div className="frame-77">
                              <div className="text-wrapper-42">humble+</div>
                            </div>
                            <div className="text-wrapper-57">4 min read</div>
                          </div>
                          <img
                            className="share"
                            alt="Share"
                            src="/img/divider.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="image-gallery-wrapper">
                      <img
                        className="image-gallery"
                        alt="Image gallery"
                        src="/img/divider.png"
                      />
                    </div>
                  </div>
                  <div className="frame-71">
                    <div className="frame-72">
                      <div className="frame-73">
                        <img
                          className="profile-user-2"
                          alt="Profile user"
                          src="/img/divider.png"
                        />
                        <div className="text-wrapper-55">Published in name</div>
                        <div className="ellipse-8" />
                        <div className="text-wrapper-55">Nov 12, 2022</div>
                        <div className="ellipse-8" />
                        <img
                          className="star-2"
                          alt="Star"
                          src="/img/divider.png"
                        />
                        <div className="text-wrapper-55">Humble+ Only</div>
                      </div>
                      <div className="frame-74">
                        <div className="text-wrapper-56">Notion</div>
                        <p className="text-wrapper-55">
                          Organize teamwork and increase productivity
                        </p>
                      </div>
                      <div className="frame-75">
                        <div className="frame-76">
                          <div className="frame-59">
                            <div className="frame-77">
                              <div className="text-wrapper-42">humble+</div>
                            </div>
                            <div className="text-wrapper-57">4 min read</div>
                          </div>
                          <img
                            className="share-2"
                            alt="Share"
                            src="/img/divider.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="image-gallery-wrapper">
                      <img
                        className="image-gallery-2"
                        alt="Image gallery"
                        src="/img/divider.png"
                      />
                    </div>
                  </div>
                  <div className="frame-71">
                    <div className="frame-72">
                      <div className="frame-73">
                        <img
                          className="profile-user-3"
                          alt="Profile user"
                          src="/img/divider.png"
                        />
                        <div className="text-wrapper-55">Published in name</div>
                        <div className="ellipse-8" />
                        <div className="text-wrapper-55">Nov 12, 2022</div>
                        <div className="ellipse-8" />
                        <img
                          className="star-3"
                          alt="Star"
                          src="/img/divider.png"
                        />
                        <div className="text-wrapper-55">Humble+ Only</div>
                      </div>
                      <div className="frame-74">
                        <div className="text-wrapper-56">Notion</div>
                        <p className="text-wrapper-55">
                          Organize teamwork and increase productivity
                        </p>
                      </div>
                      <div className="frame-75">
                        <div className="frame-76">
                          <div className="frame-59">
                            <div className="frame-77">
                              <div className="text-wrapper-42">humble+</div>
                            </div>
                            <div className="text-wrapper-57">4 min read</div>
                          </div>
                          <img
                            className="share-3"
                            alt="Share"
                            src="/img/divider.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="image-gallery-wrapper">
                      <img
                        className="image-gallery-3"
                        alt="Image gallery"
                        src="/img/divider.png"
                      />
                    </div>
                  </div>
                  <div className="frame-71">
                    <div className="frame-72">
                      <div className="frame-73">
                        <img
                          className="profile-user-4"
                          alt="Profile user"
                          src="/img/divider.png"
                        />
                        <div className="text-wrapper-55">Published in name</div>
                        <div className="ellipse-8" />
                        <div className="text-wrapper-55">Nov 12, 2022</div>
                        <div className="ellipse-8" />
                        <img
                          className="star-4"
                          alt="Star"
                          src="/img/divider.png"
                        />
                        <div className="text-wrapper-55">Humble+ Only</div>
                      </div>
                      <div className="frame-74">
                        <div className="text-wrapper-56">Notion</div>
                        <p className="text-wrapper-55">
                          Organize teamwork and increase productivity
                        </p>
                      </div>
                      <div className="frame-75">
                        <div className="frame-76">
                          <div className="frame-59">
                            <div className="frame-77">
                              <div className="text-wrapper-42">humble+</div>
                            </div>
                            <div className="text-wrapper-57">4 min read</div>
                          </div>
                          <img
                            className="share-4"
                            alt="Share"
                            src="/img/divider.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="image-gallery-wrapper">
                      <img
                        className="image-gallery-4"
                        alt="Image gallery"
                        src="/img/divider.png"
                      />
                    </div>
                  </div>
                  <div className="frame-71">
                    <div className="frame-72">
                      <div className="frame-73">
                        <img
                          className="profile-user-5"
                          alt="Profile user"
                          src="/img/divider.png"
                        />
                        <div className="text-wrapper-55">Published in name</div>
                        <div className="ellipse-8" />
                        <div className="text-wrapper-55">Nov 12, 2022</div>
                        <div className="ellipse-8" />
                        <img
                          className="star-5"
                          alt="Star"
                          src="/img/divider.png"
                        />
                        <div className="text-wrapper-55">Humble+ Only</div>
                      </div>
                      <div className="frame-74">
                        <div className="text-wrapper-56">Notion</div>
                        <p className="text-wrapper-55">
                          Organize teamwork and increase productivity
                        </p>
                      </div>
                      <div className="frame-75">
                        <div className="frame-76">
                          <div className="frame-59">
                            <div className="frame-77">
                              <div className="text-wrapper-42">humble+</div>
                            </div>
                            <div className="text-wrapper-57">4 min read</div>
                          </div>
                          <img
                            className="share-5"
                            alt="Share"
                            src="/img/divider.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="image-gallery-wrapper">
                      <img
                        className="image-gallery-5"
                        alt="Image gallery"
                        src="/img/divider.png"
                      />
                    </div>
                  </div>
                </div>
                <img className="line-21" alt="Line" src="/img/divider.png" />
                <div className="frame-41">
                  <div className="frame-78">
                    <p className="text-wrapper-38">
                      Discover More of What Matters to you
                    </p>
                    <div className="div-5">
                      <div className="frame-79">
                        <div className="button-base-9">
                          <div className="text-9">Notion</div>
                        </div>
                        <div className="button-base-9">
                          <div className="text-9">Monday</div>
                        </div>
                        <div className="button-base-9">
                          <div className="text-9">Loom</div>
                        </div>
                        <div className="button-base-9">
                          <div className="text-9">Hotjar</div>
                        </div>
                      </div>
                      <div className="frame-79">
                        <div className="button-base-9">
                          <div className="text-9">Twilio</div>
                        </div>
                        <div className="button-base-9">
                          <div className="text-9">Dokey</div>
                        </div>
                        <div className="button-base-9">
                          <div className="text-9">Taskade</div>
                        </div>
                        <div className="button-base-9">
                          <div className="text-9">Salesforce</div>
                        </div>
                      </div>
                      <div className="frame-79">
                        <div className="button-base-9">
                          <div className="text-9">Notion</div>
                        </div>
                        <div className="button-base-9">
                          <div className="text-9">Monday</div>
                        </div>
                        <div className="button-base-9">
                          <div className="text-9">Loom</div>
                        </div>
                        <div className="button-base-9">
                          <div className="text-9">Hotjar</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-78">
                    <img className="line-22" alt="Line" src="/img/line-2.svg" />
                    <div className="text-wrapper-58">Coming Soon</div>
                    <div className="frame-80">
                      <div className="frame-81">
                        <div className="frame-74">
                          <div className="text-wrapper-59">Notion</div>
                          <p className="text-wrapper-60">
                            Real people make life meaningful. So open your
                            world—talk and meetup with anyone nearby on OpenMat.
                          </p>
                        </div>
                        <div className="frame-75">
                          <div className="frame-76">
                            <div className="frame-62">
                              <img
                                className="add"
                                alt="Add"
                                src="/img/divider.png"
                              />
                              <div className="text-wrapper-61">Follow</div>
                            </div>
                            <div className="frame-82">
                              <div className="frame-83">
                                <div className="overlap-group-9">
                                  <div className="ellipse-9" />
                                  <div className="ellipse-10" />
                                  <div className="ellipse-11" />
                                </div>
                              </div>
                              <div className="text-wrapper-62">+101</div>
                            </div>
                            <img
                              className="share-6"
                              alt="Share"
                              src="/img/share-1-2.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-80">
                      <div className="frame-81">
                        <div className="frame-74">
                          <div className="text-wrapper-59">Dockey</div>
                          <p className="text-wrapper-60">
                            Real people make life meaningful. So open your
                            world—talk and meetup with anyone nearby on OpenMat.
                          </p>
                        </div>
                        <div className="frame-75">
                          <div className="frame-76">
                            <div className="frame-62">
                              <img
                                className="add-2"
                                alt="Add"
                                src="/img/divider.png"
                              />
                              <div className="text-wrapper-61">Follow</div>
                            </div>
                            <div className="frame-82">
                              <div className="frame-83">
                                <div className="overlap-group-9">
                                  <div className="ellipse-9" />
                                  <div className="ellipse-10" />
                                  <div className="ellipse-11" />
                                </div>
                              </div>
                              <div className="text-wrapper-62">+10</div>
                            </div>
                            <img
                              className="share-6"
                              alt="Share"
                              src="/img/image.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-80">
                      <div className="frame-81">
                        <div className="frame-74">
                          <div className="text-wrapper-59">Monday</div>
                          <p className="text-wrapper-60">
                            Real people make life meaningful. So open your
                            world—talk and meetup with anyone nearby on OpenMat.
                          </p>
                        </div>
                        <div className="frame-75">
                          <div className="frame-76">
                            <div className="frame-62">
                              <img
                                className="add-3"
                                alt="Add"
                                src="/img/divider.png"
                              />
                              <div className="text-wrapper-61">Follow</div>
                            </div>
                            <div className="frame-82">
                              <div className="frame-83">
                                <div className="overlap-group-9">
                                  <div className="ellipse-9" />
                                  <div className="ellipse-10" />
                                  <div className="ellipse-11" />
                                </div>
                              </div>
                              <div className="text-wrapper-62">+1120</div>
                            </div>
                            <img
                              className="share-6"
                              alt="Share"
                              src="/img/share-1.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-84">
                <div className="frame-85">
                  <div className="frame-76">
                    <div className="frame-74">
                      <div className="text-wrapper-56">
                        Subscribe to our newsletters.
                      </div>
                      <p className="text-wrapper-63">
                        No FOMO here. Stay up-to-date on all the latest deals
                        and news with our monthly newsletter straight to your
                        inbox like 50,000+ entrepreneurs (+ GET 20% off on all
                        our products!)
                      </p>
                    </div>
                    <div className="frame-86">
                      <div className="frame-87">
                        <div className="text-wrapper-64">Enter your email</div>
                      </div>
                      <button className="button-7">
                        <div className="button-base-10">
                          <div className="text-7">Don’t miss a deal</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-88">
              <div className="frame-50">
                <div className="frame-89">
                  <img className="line-23" alt="Line" src="/img/divider.png" />
                  <img className="line-24" alt="Line" src="/img/divider.png" />
                  <div className="text-wrapper-65">Explore Utilities</div>
                </div>
                <div className="frame-89">
                  <img className="line-25" alt="Line" src="/img/divider.png" />
                  <img className="line-26" alt="Line" src="/img/divider.png" />
                  <div className="text-wrapper-66">Explore Productivity</div>
                  <div className="frame-90">
                    <div className="text-wrapper-42">humble+</div>
                  </div>
                </div>
                <div className="frame-89">
                  <img className="line-27" alt="Line" src="/img/divider.png" />
                  <img className="line-28" alt="Line" src="/img/divider.png" />
                  <div className="text-wrapper-65">Explore Finance</div>
                </div>
                <div className="frame-89">
                  <img className="line-29" alt="Line" src="/img/divider.png" />
                  <img className="line-30" alt="Line" src="/img/divider.png" />
                  <div className="text-wrapper-67">Explore Legal</div>
                  <div className="frame-90">
                    <div className="text-wrapper-42">humble+</div>
                  </div>
                </div>
              </div>
              <div className="frame-50">
                <div className="frame-89">
                  <img className="line-31" alt="Line" src="/img/divider.png" />
                  <img className="line-32" alt="Line" src="/img/divider.png" />
                  <div className="text-wrapper-67">Explore Sales</div>
                  <div className="frame-90">
                    <div className="text-wrapper-42">humble+</div>
                  </div>
                </div>
                <div className="frame-89">
                  <img className="line-33" alt="Line" src="/img/divider.png" />
                  <img className="line-34" alt="Line" src="/img/divider.png" />
                  <div className="text-wrapper-68">Explore Marketing</div>
                </div>
                <div className="frame-89">
                  <img className="line-35" alt="Line" src="/img/divider.png" />
                  <img className="line-36" alt="Line" src="/img/divider.png" />
                  <div className="text-wrapper-69">Explore Design</div>
                  <div className="frame-90">
                    <div className="text-wrapper-42">humble+</div>
                  </div>
                </div>
                <div className="frame-89">
                  <img className="line-37" alt="Line" src="/img/divider.png" />
                  <img className="line-38" alt="Line" src="/img/divider.png" />
                  <div className="text-wrapper-69">Explore Design</div>
                </div>
              </div>
            </div>
            <div className="rectangle-11" />
          </div>
          <div className="product-details-page">
            <div className="overlap-12">
              <div className="frame-91">
                <div className="div-4">
                  <div className="frame-58">
                    <div className="text-wrapper-48">Home</div>
                    <div className="text-wrapper-49">System</div>
                    <div className="text-wrapper-49">Trending</div>
                    <div className="text-wrapper-49">Marketplace</div>
                    <div className="text-wrapper-49">Resource Centre</div>
                    <div className="text-wrapper-49">Mental Health</div>
                    <div className="text-wrapper-49">Members</div>
                    <div className="text-wrapper-49">News</div>
                  </div>
                  <div className="frame-59">
                    <div className="text-wrapper-50">Sign In</div>
                    <button className="button-6">
                      <button className="button-base-7">
                        <div className="text-8">Sign Up</div>
                      </button>
                    </button>
                  </div>
                </div>
              </div>
              <div className="rectangle-12" />
              <p className="there-are-over-2">
                <span className="text-wrapper-34">
                  There are over 500 perks you can use with Humble+.
                  <br />
                </span>
                <span className="text-wrapper-35">
                  These perks are the result of a global collaboration between
                  business and service brands to give you access to some of the
                  most sought-after travel, entertainment, products, experiences
                  and services.
                </span>
              </p>
              <p className="humble-products">
                Humble&nbsp;&nbsp; &gt;&nbsp;&nbsp;&nbsp;&nbsp;
                Products&nbsp;&nbsp; &gt;&nbsp;&nbsp; Explore Legal
              </p>
              <div className="rectangle-13" />
              <div className="text-wrapper-70">Logo</div>
              <div className="frame-92">
                <div className="text-wrapper-71">Notion</div>
                <p className="text-wrapper-55">
                  CRM, marketing automation &amp; customer service software
                  suite
                </p>
              </div>
              <div className="rectangle-14" />
              <div className="frame-93">
                <p className="text-wrapper-72">
                  90% off the 1st year (then 50% off the 2nd year)
                </p>
                <button className="button-8">
                  <div className="button-base-11">
                    <div className="text-7">Grab the deal</div>
                  </div>
                </button>
              </div>
              <button className="button-9">
                <div className="button-base-12">
                  <img
                    className="arrow-up"
                    alt="Arrow up"
                    src="/img/arrow-up-1.svg"
                  />
                  <div className="text-10">UPVOTE 1,200</div>
                </div>
              </button>
              <div className="text-wrapper-73">Eligibility criteria</div>
              <div className="frame-94">
                <div className="frame-82">
                  <img
                    className="img-9"
                    alt="Comment"
                    src="/img/comment-1-1.svg"
                  />
                  <div className="text-wrapper-61">Discuss</div>
                </div>
                <div className="frame-82">
                  <img
                    className="img-9"
                    alt="Save instagram"
                    src="/img/save-instagram-1-1.svg"
                  />
                  <div className="text-wrapper-61">Collect</div>
                </div>
                <div className="frame-82">
                  <img
                    className="share-7"
                    alt="Share"
                    src="/img/share-1-1-1.svg"
                  />
                  <div className="text-wrapper-61">Share</div>
                </div>
                <div className="frame-82">
                  <img className="stats" alt="Stats" src="/img/stats-1-1.svg" />
                  <div className="text-wrapper-61">Stats</div>
                </div>
              </div>
            </div>
            <div className="frame-60">
              <div className="div-4">
                <div className="frame-61">
                  <div className="image-wrapper">
                    <img
                      className="image-4"
                      alt="Image"
                      src="/img/divider.png"
                    />
                  </div>
                  <div className="searchbar">
                    <div className="frame-62">
                      <IconSearchFill
                        className="icon-search-fill-instance"
                        iconSearchFill="/img/divider.png"
                      />
                      <div className="text-wrapper-51">Search products</div>
                    </div>
                  </div>
                </div>
                <div className="frame-61">
                  <div className="text-wrapper-49">
                    Unlock benefits worth $5000
                  </div>
                  <div className="frame-63">
                    <div className="text-wrapper-49">En</div>
                    <img
                      className="frame-95"
                      alt="Frame"
                      src="/img/divider.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <p className="lorem-ipsum-is-3">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry&#39;s standard dummy
              text ever since the 1500s, when an unknown printer took a galley
              of type and scrambled it to make a type specimen book.Lorem Ipsum
              is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry&#39;s standard dummy text ever
              since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.
            </p>
            <div className="overlap-13">
              <div className="overlap-14">
                <p className="lorem-ipsum-is-4">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry&#39;s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.Lorem Ipsum is simply dummy text of the printing
                  and typesetting industry. Lorem Ipsum has been the
                  industry&#39;s standard dummy text ever since the 1500s, when
                  an unknown printer took a galley of type and scrambled it to
                  make a type specimen book.
                </p>
                <p className="kevin-karthik-been">
                  <span className="text-wrapper-74">@kevin</span>
                  <span className="text-wrapper-75">&nbsp;</span>
                  <span className="text-wrapper-74">@karthik</span>
                  <span className="text-wrapper-75">
                    {" "}
                    Been using the beta, love the SAR feature. All the best team
                  </span>
                </p>
                <div className="overlap-15">
                  <div className="text-wrapper-76">@Annkur</div>
                  <div className="text-wrapper-77">Annkur P Agarwal</div>
                  <img className="line-39" alt="Line" src="/img/line-32.svg" />
                  <div className="user-wrapper">
                    <img className="user" alt="User" src="/img/user-2-2.svg" />
                  </div>
                  <div className="frame-96">
                    <div className="text-wrapper-49">Upvote</div>
                  </div>
                  <div className="frame-97">
                    <div className="text-wrapper-49">Share</div>
                  </div>
                  <div className="frame-98">
                    <div className="text-wrapper-49">Dec 14</div>
                  </div>
                  <div className="frame-99">
                    <div className="ellipse-12" />
                    <div className="ellipse-12" />
                    <div className="ellipse-12" />
                  </div>
                </div>
                <div className="frame-100">
                  <div className="text-wrapper-49">Upvote</div>
                </div>
                <div className="frame-101">
                  <div className="text-wrapper-49">Share</div>
                </div>
                <div className="frame-102">
                  <div className="text-wrapper-49">Dec 14</div>
                </div>
              </div>
              <div className="frame-103">
                <div className="ellipse-12" />
                <div className="ellipse-12" />
                <div className="ellipse-12" />
              </div>
            </div>
            <p className="kevin-thanks-for">
              <span className="text-wrapper-74">@kevin</span>
              <span className="text-wrapper-75">
                {" "}
                thanks for hunting Legal!
              </span>
            </p>
            <p className="kevin-karthik-been-2">
              <span className="text-wrapper-74">@kevin</span>
              <span className="text-wrapper-75">&nbsp;</span>
              <span className="text-wrapper-74">@karthik</span>
              <span className="text-wrapper-75">
                {" "}
                Been using the beta, love the SAR feature. All the best team
              </span>
            </p>
            <p className="kevin-karthik-been-3">
              <span className="text-wrapper-74">@kevin</span>
              <span className="text-wrapper-75">&nbsp;</span>
              <span className="text-wrapper-74">@karthik</span>
              <span className="text-wrapper-75">
                {" "}
                Been using the beta, love the SAR feature. All the best team
              </span>
            </p>
            <div className="text-wrapper-78">@Annkur</div>
            <div className="text-wrapper-79">@Annkur</div>
            <div className="text-wrapper-80">Himanshu Retarekar</div>
            <div className="text-wrapper-81">Annkur P Agarwal</div>
            <div className="text-wrapper-82">Annkur P Agarwal</div>
            <div className="overlap-16">
              <img
                className="right-arrow"
                alt="Right arrow"
                src="/img/right-arrow-2-2.svg"
              />
              <div className="overlap-17">
                <div className="rectangle-15" />
                <div className="rectangle-16" />
                <div className="rectangle-17" />
                <img
                  className="image-gallery-6"
                  alt="Image gallery"
                  src="/img/image-gallery-1-1.svg"
                />
                <img
                  className="image-gallery-7"
                  alt="Image gallery"
                  src="/img/image-gallery-2-1.svg"
                />
                <img
                  className="image-gallery-8"
                  alt="Image gallery"
                  src="/img/image-gallery-3-1.svg"
                />
                <div className="frame-104">
                  <div className="frame-86">
                    <div className="frame-105">
                      <div className="overlap-group-10">
                        <img
                          className="user-2"
                          alt="User"
                          src="/img/user-1-1.svg"
                        />
                      </div>
                    </div>
                    <div className="text-wrapper-55">What you think?</div>
                  </div>
                  <button className="button-6">
                    <div className="button-base-13">
                      <div className="text-10">Rate and Review</div>
                    </div>
                  </button>
                </div>
                <img className="line-40" alt="Line" src="/img/line-30.svg" />
                <img className="line-41" alt="Line" src="/img/line-31.svg" />
              </div>
              <img
                className="right-arrow-2"
                alt="Right arrow"
                src="/img/right-arrow-1-1.svg"
              />
            </div>
            <div className="overlap-18">
              <div className="ellipse-13" />
              <img className="user-3" alt="User" src="/img/user-1.svg" />
              <div className="rectangle-18" />
            </div>
            <div className="frame-106">
              <img className="user" alt="User" src="/img/user-2-1.svg" />
            </div>
            <div className="frame-107">
              <img className="user" alt="User" src="/img/user-2.svg" />
            </div>
            <div className="frame-108">
              <div className="text-wrapper-49">Maker</div>
            </div>
            <div className="frame-109">
              <div className="text-wrapper-49">Upvote</div>
            </div>
            <div className="frame-110">
              <div className="text-wrapper-49">Upvote</div>
            </div>
            <div className="frame-111">
              <div className="text-wrapper-49">Share</div>
            </div>
            <div className="frame-112">
              <div className="text-wrapper-49">Share</div>
            </div>
            <div className="frame-113">
              <div className="text-wrapper-49">Dec 14</div>
            </div>
            <div className="frame-114">
              <div className="text-wrapper-49">Dec 14</div>
            </div>
            <div className="frame-115">
              <img className="frame-116" alt="Frame" src="/img/divider.png" />
              <div className="frame-117">
                <div className="frame-118">
                  <div className="text-wrapper-83">Notion</div>
                  <p className="all-in-one-solution">
                    All-in-one solution for organising team retreats &amp;
                    offsites
                  </p>
                </div>
                <div className="frame-119">
                  <div className="frame-62">
                    <img
                      className="frame-120"
                      alt="Frame"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-84">250</div>
                  </div>
                  <div className="frame-121">
                    <div className="text-wrapper-49">Free</div>
                    <div className="text-wrapper-49">Design</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-122">
              <img className="frame-123" alt="Frame" src="/img/divider.png" />
              <div className="frame-117">
                <div className="frame-118">
                  <div className="text-wrapper-83">Notion</div>
                  <p className="all-in-one-solution">
                    All-in-one solution for organising team retreats &amp;
                    offsites
                  </p>
                </div>
                <div className="frame-119">
                  <div className="frame-62">
                    <img
                      className="frame-124"
                      alt="Frame"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-84">250</div>
                  </div>
                  <div className="frame-121">
                    <div className="text-wrapper-49">Free</div>
                    <div className="text-wrapper-49">Design</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-125">
              <img className="frame-126" alt="Frame" src="/img/divider.png" />
              <div className="frame-117">
                <div className="frame-118">
                  <div className="text-wrapper-83">Explore Sales</div>
                  <p className="all-in-one-solution">
                    All-in-one solution for organising team retreats &amp;
                    offsites
                  </p>
                </div>
                <div className="frame-119">
                  <div className="frame-62">
                    <img
                      className="frame-127"
                      alt="Frame"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-84">250</div>
                  </div>
                  <div className="frame-121">
                    <div className="text-wrapper-49">Humple+</div>
                    <div className="text-wrapper-49">Productivity</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-128">
              <img className="frame-129" alt="Frame" src="/img/divider.png" />
              <div className="frame-117">
                <div className="frame-118">
                  <div className="text-wrapper-83">Explore Sales</div>
                  <p className="all-in-one-solution">
                    All-in-one solution for organising team retreats &amp;
                    offsites
                  </p>
                </div>
                <div className="frame-119">
                  <div className="frame-62">
                    <img
                      className="frame-130"
                      alt="Frame"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-84">250</div>
                  </div>
                  <div className="frame-121">
                    <div className="text-wrapper-49">Humple+</div>
                    <div className="text-wrapper-49">Productivity</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-131">
              <div className="ellipse-12" />
              <div className="ellipse-12" />
              <div className="ellipse-12" />
            </div>
            <div className="frame-132">
              <div className="ellipse-12" />
              <div className="ellipse-12" />
              <div className="ellipse-12" />
            </div>
            <div className="frame-133">
              <div className="text-wrapper-85">Trending Content</div>
              <p className="text-wrapper-55">
                Here are some of the most in-demand deals according to our
                users.
              </p>
            </div>
            <div className="footers-2">
              <div className="frame-36">
                <div className="frame-37">
                  <div className="frame-38">
                    <div className="image-wrapper">
                      <img
                        className="image-5"
                        alt="Image"
                        src="/img/divider.png"
                      />
                    </div>
                    <img
                      className="social-buttons-2"
                      alt="Social buttons"
                      src="/img/divider.png"
                    />
                  </div>
                  <div className="frame-39">
                    <div className="div-5">
                      <div className="text-wrapper-28">Heading 1</div>
                      <div className="text-wrapper-29">Our Team</div>
                      <div className="text-wrapper-29">Company Vision</div>
                      <div className="text-wrapper-29">Our History</div>
                      <div className="text-wrapper-29">Instrutors</div>
                    </div>
                    <div className="div-5">
                      <div className="text-wrapper-28">Heading 2</div>
                      <div className="text-wrapper-30">The Blog</div>
                      <div className="text-wrapper-29">Downloads</div>
                      <div className="text-wrapper-30">Change Log</div>
                      <div className="text-wrapper-30">Slack Channel</div>
                    </div>
                    <div className="div-5">
                      <div className="text-wrapper-28">Heading 3</div>
                      <div className="text-wrapper-29">New Features</div>
                      <div className="text-wrapper-29">Roadmap</div>
                      <div className="text-wrapper-29">Product Demo</div>
                      <div className="text-wrapper-29">Pricing</div>
                    </div>
                    <div className="div-5">
                      <div className="text-wrapper-28">Heading 4</div>
                      <div className="text-wrapper-29">New Features</div>
                      <div className="text-wrapper-29">Roadmap</div>
                      <div className="text-wrapper-29">Product Demo</div>
                      <div className="text-wrapper-29">Pricing</div>
                    </div>
                  </div>
                </div>
                <img
                  className="divider-3"
                  alt="Divider"
                  src="/img/divider-2.png"
                />
                <div className="text-wrapper-31">Copyright 2023 humble.inc</div>
              </div>
            </div>
          </div>
          <div className="product-content-page">
            <div className="overlap-19">
              <div className="frame-91">
                <div className="div-4">
                  <div className="frame-58">
                    <div className="text-wrapper-48">Home</div>
                    <div className="text-wrapper-49">System</div>
                    <div className="text-wrapper-49">Trending</div>
                    <div className="text-wrapper-49">Marketplace</div>
                    <div className="text-wrapper-49">Resource Centre</div>
                    <div className="text-wrapper-49">Mental Health</div>
                    <div className="text-wrapper-49">Members</div>
                    <div className="text-wrapper-49">News</div>
                  </div>
                  <div className="frame-59">
                    <div className="text-wrapper-50">Sign In</div>
                    <button className="button-6">
                      <button className="button-base-7">
                        <div className="text-8">Sign Up</div>
                      </button>
                    </button>
                  </div>
                </div>
              </div>
              <div className="rectangle-19" />
              <div className="frame-134">
                <div className="button-base-14">
                  <div className="text-8">Launch</div>
                </div>
                <div className="button-base-14">
                  <div className="text-8">Schedule</div>
                </div>
              </div>
              <p className="humble-products">
                Humble&nbsp;&nbsp; &gt;&nbsp;&nbsp;&nbsp;&nbsp;
                Products&nbsp;&nbsp; &gt;&nbsp;&nbsp; Explore Legal
              </p>
              <div className="rectangle-13" />
              <div className="text-wrapper-70">Logo</div>
              <div className="frame-135">
                <div className="text-wrapper-71">Explore Legal</div>
                <p className="text-wrapper-55">
                  CRM, marketing automation &amp; customer service software
                  suite
                </p>
              </div>
              <div className="frame-136">
                <div className="frame-82">
                  <img
                    className="img-9"
                    alt="Comment"
                    src="/img/comment-1.svg"
                  />
                  <div className="text-wrapper-61">Discuss</div>
                </div>
                <div className="frame-82">
                  <img
                    className="img-9"
                    alt="Save instagram"
                    src="/img/save-instagram-1.svg"
                  />
                  <div className="text-wrapper-61">Collect</div>
                </div>
                <div className="frame-82">
                  <img
                    className="share-7"
                    alt="Share"
                    src="/img/share-1-1.svg"
                  />
                  <div className="text-wrapper-61">Share</div>
                </div>
                <div className="frame-82">
                  <img className="stats" alt="Stats" src="/img/stats-1.svg" />
                  <div className="text-wrapper-61">Stats</div>
                </div>
              </div>
              <p className="lorem-ipsum-is-5">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry&#39;s standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry&#39;s standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
              <div className="frame-137">
                <img className="frame-138" alt="Frame" src="/img/divider.png" />
                <div className="text-wrapper-86">1,456</div>
              </div>
            </div>
            <div className="frame-60">
              <div className="div-4">
                <div className="frame-61">
                  <div className="image-wrapper">
                    <img
                      className="image-6"
                      alt="Image"
                      src="/img/image-35-3.png"
                    />
                  </div>
                  <div className="searchbar">
                    <div className="frame-62">
                      <Icons1 className="frame-139" />
                      <div className="text-wrapper-51">Search products</div>
                    </div>
                  </div>
                </div>
                <div className="frame-61">
                  <div className="text-wrapper-49">
                    Unlock benefits worth $5000
                  </div>
                  <div className="frame-63">
                    <div className="text-wrapper-49">En</div>
                    <img
                      className="frame-140"
                      alt="Frame"
                      src="/img/divider.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="footers-3">
              <div className="frame-36">
                <div className="frame-37">
                  <div className="frame-38">
                    <div className="image-wrapper">
                      <img
                        className="image-7"
                        alt="Image"
                        src="/img/divider.png"
                      />
                    </div>
                    <img
                      className="social-buttons-3"
                      alt="Social buttons"
                      src="/img/divider.png"
                    />
                  </div>
                  <div className="frame-39">
                    <div className="div-5">
                      <div className="text-wrapper-28">Heading 1</div>
                      <div className="text-wrapper-29">Our Team</div>
                      <div className="text-wrapper-29">Company Vision</div>
                      <div className="text-wrapper-29">Our History</div>
                      <div className="text-wrapper-29">Instrutors</div>
                    </div>
                    <div className="div-5">
                      <div className="text-wrapper-28">Heading 2</div>
                      <div className="text-wrapper-30">The Blog</div>
                      <div className="text-wrapper-29">Downloads</div>
                      <div className="text-wrapper-30">Change Log</div>
                      <div className="text-wrapper-30">Slack Channel</div>
                    </div>
                    <div className="div-5">
                      <div className="text-wrapper-28">Heading 3</div>
                      <div className="text-wrapper-29">New Features</div>
                      <div className="text-wrapper-29">Roadmap</div>
                      <div className="text-wrapper-29">Product Demo</div>
                      <div className="text-wrapper-29">Pricing</div>
                    </div>
                    <div className="div-5">
                      <div className="text-wrapper-28">Heading 4</div>
                      <div className="text-wrapper-29">New Features</div>
                      <div className="text-wrapper-29">Roadmap</div>
                      <div className="text-wrapper-29">Product Demo</div>
                      <div className="text-wrapper-29">Pricing</div>
                    </div>
                  </div>
                </div>
                <img
                  className="divider-4"
                  alt="Divider"
                  src="/img/divider-2.png"
                />
                <div className="text-wrapper-31">Copyright 2023 humble.inc</div>
              </div>
            </div>
            <img
              className="right-arrow-3"
              alt="Right arrow"
              src="/img/right-arrow-2-1.svg"
            />
            <div className="overlap-20">
              <div className="rectangle-20" />
              <img
                className="image-gallery-9"
                alt="Image gallery"
                src="/img/image-gallery-1.svg"
              />
            </div>
            <div className="overlap-21">
              <div className="rectangle-20" />
              <img
                className="image-gallery-9"
                alt="Image gallery"
                src="/img/image-gallery-2.svg"
              />
            </div>
            <div className="overlap-22">
              <div className="rectangle-20" />
              <img
                className="image-gallery-9"
                alt="Image gallery"
                src="/img/image-gallery-3.svg"
              />
            </div>
            <img
              className="right-arrow-4"
              alt="Right arrow"
              src="/img/divider.png"
            />
            <div className="frame-141">
              <div className="div-5">
                <div className="text-wrapper-85">Product Features</div>
                <p className="text-wrapper-87">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry
                </p>
              </div>
            </div>
            <div className="frame-142">
              <img className="frame-143" alt="Frame" src="/img/frame-5.svg" />
              <div className="frame-144">
                <p className="text-wrapper-88">
                  Turn your data into actionable views
                </p>
                <p className="text-wrapper-89">
                  Your business data is always changing, the way you view it
                  needs to be flexible too. Create views get and understand the
                  data that matters and make the right business decisions.
                </p>
              </div>
            </div>
            <div className="frame-145">
              <img className="frame-143" alt="Frame" src="/img/frame-4.svg" />
              <div className="frame-146">
                <div className="text-wrapper-88">Automate your data</div>
                <p className="text-wrapper-89">
                  Easily create workflows to automate your recurring tasks and
                  business processes.
                </p>
              </div>
            </div>
            <div className="frame-147">
              <img className="frame-143" alt="Frame" src="/img/frame-3.svg" />
              <div className="frame-148">
                <p className="text-wrapper-88">
                  Get custom reports in a few clicks
                </p>
                <p className="text-wrapper-89">
                  Easily create and automate custom reports to see at a glance
                  the information that is really important to your business.
                </p>
              </div>
            </div>
            <div className="frame-149">
              <img className="frame-143" alt="Frame" src="/img/frame-2.svg" />
              <div className="frame-148">
                <p className="text-wrapper-88">
                  Get custom reports in a few clicks
                </p>
                <p className="text-wrapper-89">
                  Easily create and automate custom reports to see at a glance
                  the information that is really important to your business.
                </p>
              </div>
            </div>
            <div className="frame-150">
              <img className="frame-143" alt="Frame" src="/img/frame-1.svg" />
              <div className="frame-151">
                <p className="take-advantage-of">
                  Take advantage of hundreds of integrations and Airtable&#39;s
                  powerful API
                </p>
                <p className="text-wrapper-89">
                  Connect Airtable to 1000+ apps like the Google suite,
                  MailChimp, Dropbox, Facebook, LinkedIn, Stripe, Trello,
                  Wordpress, etc.
                </p>
              </div>
            </div>
            <div className="frame-152">
              <img className="frame-143" alt="Frame" src="/img/frame.svg" />
              <div className="frame-151">
                <p className="take-advantage-of">
                  Take advantage of hundreds of integrations and Airtable&#39;s
                  powerful API
                </p>
                <p className="text-wrapper-89">
                  Connect Airtable to 1000+ apps like the Google suite,
                  MailChimp, Dropbox, Facebook, LinkedIn, Stripe, Trello,
                  Wordpress, etc.
                </p>
              </div>
            </div>
            <div className="frame-153">
              <img
                className="frame-154"
                alt="Frame"
                src="/img/frame-40520-9.svg"
              />
              <div className="frame-117">
                <div className="frame-118">
                  <div className="text-wrapper-83">Notion</div>
                  <p className="all-in-one-solution">
                    All-in-one solution for organising team retreats &amp;
                    offsites
                  </p>
                </div>
                <div className="frame-119">
                  <div className="frame-62">
                    <img
                      className="frame-155"
                      alt="Frame"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-84">250</div>
                  </div>
                  <div className="frame-121">
                    <div className="text-wrapper-49">Free</div>
                    <div className="text-wrapper-49">Design</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-156">
              <img className="frame-157" alt="Frame" src="/img/divider.png" />
              <div className="frame-117">
                <div className="frame-118">
                  <div className="text-wrapper-83">Notion</div>
                  <p className="all-in-one-solution">
                    All-in-one solution for organising team retreats &amp;
                    offsites
                  </p>
                </div>
                <div className="frame-119">
                  <div className="frame-62">
                    <img
                      className="frame-158"
                      alt="Frame"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-84">250</div>
                  </div>
                  <div className="frame-121">
                    <div className="text-wrapper-49">Free</div>
                    <div className="text-wrapper-49">Design</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-159">
              <img className="frame-160" alt="Frame" src="/img/divider.png" />
              <div className="frame-117">
                <div className="frame-118">
                  <div className="text-wrapper-83">Explore Sales</div>
                  <p className="all-in-one-solution">
                    All-in-one solution for organising team retreats &amp;
                    offsites
                  </p>
                </div>
                <div className="frame-119">
                  <div className="frame-62">
                    <img
                      className="frame-161"
                      alt="Frame"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-84">250</div>
                  </div>
                  <div className="frame-121">
                    <div className="text-wrapper-49">Humple+</div>
                    <div className="text-wrapper-49">Productivity</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-162">
              <img className="frame-163" alt="Frame" src="/img/divider.png" />
              <div className="frame-117">
                <div className="frame-118">
                  <div className="text-wrapper-83">Explore Sales</div>
                  <p className="all-in-one-solution">
                    All-in-one solution for organising team retreats &amp;
                    offsites
                  </p>
                </div>
                <div className="frame-119">
                  <div className="frame-62">
                    <img
                      className="frame-164"
                      alt="Frame"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-84">250</div>
                  </div>
                  <div className="frame-121">
                    <div className="text-wrapper-49">Humple+</div>
                    <div className="text-wrapper-49">Productivity</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-wrapper-90">Trending Content</div>
            <p className="text-wrapper-91">
              Here are some of the most in-demand deals according to our users.
            </p>
          </div>
          <div className="product-page">
            <div className="overlap-23">
              <div className="frame-91">
                <div className="div-4">
                  <div className="frame-58">
                    <div className="text-wrapper-48">Home</div>
                    <div className="text-wrapper-49">System</div>
                    <div className="text-wrapper-49">Trending</div>
                    <div className="text-wrapper-49">Marketplace</div>
                    <div className="text-wrapper-49">Resource Centre</div>
                    <div className="text-wrapper-49">Mental Health</div>
                    <div className="text-wrapper-49">Members</div>
                    <div className="text-wrapper-49">News</div>
                  </div>
                  <div className="frame-59">
                    <div className="text-wrapper-50">Sign In</div>
                    <button className="button-6">
                      <button className="button-base-7">
                        <div className="text-8">Sign Up</div>
                      </button>
                    </button>
                  </div>
                </div>
              </div>
              <div className="frame-165">
                <div className="frame-166">
                  <div className="text-wrapper-92">All Products</div>
                  <p className="text-wrapper-55">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="frame-60">
              <div className="div-4">
                <div className="frame-61">
                  <div className="image-wrapper">
                    <img
                      className="image-8"
                      alt="Image"
                      src="/img/image-35-1.png"
                    />
                  </div>
                  <div className="searchbar">
                    <div className="frame-62">
                      <Icons className="icons-instance" />
                      <div className="text-wrapper-51">Search products</div>
                    </div>
                  </div>
                </div>
                <div className="frame-61">
                  <div className="text-wrapper-49">
                    Unlock benefits worth $5000
                  </div>
                  <div className="frame-63">
                    <div className="text-wrapper-49">En</div>
                    <img
                      className="frame-167"
                      alt="Frame"
                      src="/img/frame-40365.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-168">
              <div className="text-wrapper-85">Categories</div>
              <p className="text-wrapper-37">
                Here are some of the most in-demand deals according to our
                users.
              </p>
            </div>
            <div className="overlap-24">
              <div className="frame-169">
                <div className="frame-51">
                  <div className="frame-52">
                    <div className="rectangle-6" />
                    <div className="frame-53">
                      <div className="text-wrapper-38">Explore Legal</div>
                      <p className="text-wrapper-39">
                        500+ exclusive perks for Entrepreneurs
                      </p>
                    </div>
                  </div>
                  <div className="overlap-group-7">
                    <div className="text-wrapper-47">Save up to ₹2000</div>
                    <img
                      className="line-11"
                      alt="Line"
                      src="/img/line-1-3.svg"
                    />
                    <div className="frame-56">
                      <div className="text-wrapper-42">humble+</div>
                    </div>
                    <img
                      className="rectangle-9"
                      alt="Rectangle"
                      src="/img/rectangle-8-3.png"
                    />
                  </div>
                  <p className="text-wrapper-41">
                    90% off the 1st year (then 50% off the 2nd year)
                  </p>
                </div>
                <div className="frame-51">
                  <div className="overlap-group-6">
                    <div className="frame-55">
                      <div className="rectangle-6" />
                      <div className="frame-53">
                        <div className="text-wrapper-38">Explore Design</div>
                        <p className="text-wrapper-39">
                          500+ exclusive perks for Entrepreneurs
                        </p>
                      </div>
                    </div>
                    <div className="text-wrapper-43">New!</div>
                  </div>
                  <div className="text-wrapper-44">Save up to ₹2000</div>
                  <p className="text-wrapper-45">200 in credits for 3 months</p>
                  <div className="overlap-group-7">
                    <img
                      className="line-11"
                      alt="Line"
                      src="/img/line-1-2.svg"
                    />
                    <div className="frame-56">
                      <div className="text-wrapper-42">humble+</div>
                    </div>
                    <img
                      className="rectangle-9"
                      alt="Rectangle"
                      src="/img/rectangle-8-2.png"
                    />
                  </div>
                </div>
                <div className="frame-51">
                  <div className="frame-52">
                    <div className="rectangle-6" />
                    <div className="frame-53">
                      <div className="text-wrapper-38">Explore Sales</div>
                      <p className="text-wrapper-39">
                        500+ exclusive perks for Entrepreneurs
                      </p>
                    </div>
                  </div>
                  <div className="overlap-group-7">
                    <div className="text-wrapper-47">Save up to ₹2000</div>
                    <img
                      className="line-11"
                      alt="Line"
                      src="/img/line-1-1.svg"
                    />
                    <img
                      className="rectangle-9"
                      alt="Rectangle"
                      src="/img/rectangle-8-1.png"
                    />
                  </div>
                  <p className="text-wrapper-41">
                    90% off the 1st year (then 50% off the 2nd year)
                  </p>
                </div>
                <div className="frame-51">
                  <div className="frame-52">
                    <div className="rectangle-6" />
                    <div className="frame-53">
                      <div className="text-wrapper-38">Explore Finance</div>
                      <p className="text-wrapper-39">
                        500+ exclusive perks for Entrepreneurs
                      </p>
                    </div>
                  </div>
                  <div className="overlap-group-7">
                    <div className="text-wrapper-47">Save up to ₹2000</div>
                    <img className="line-11" alt="Line" src="/img/line-1.svg" />
                    <div className="frame-56">
                      <div className="text-wrapper-42">humble+</div>
                    </div>
                    <img
                      className="rectangle-9"
                      alt="Rectangle"
                      src="/img/rectangle-8.png"
                    />
                  </div>
                  <p className="text-wrapper-41">
                    90% off the 1st year (then 50% off the 2nd year)
                  </p>
                </div>
              </div>
              <img
                className="right-arrow-5"
                alt="Right arrow"
                src="/img/right-arrow-2.svg"
              />
              <img
                className="right-arrow-6"
                alt="Right arrow"
                src="/img/right-arrow-3.svg"
              />
            </div>
            <div className="overlap-25">
              <div className="rectangle-21" />
              <div className="frame-170">
                <div className="div-5">
                  <div className="text-wrapper-85">Product List</div>
                  <p className="text-wrapper-37">
                    Here are some of the most in-demand deals according to our
                    users.
                  </p>
                </div>
                <div className="frame-86">
                  <div className="text-wrapper-55">Order by:</div>
                  <div className="frame-62">
                    <div className="text-wrapper-93">Recommended</div>
                    <img
                      className="frame-171"
                      alt="Frame"
                      src="/img/frame-40511.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="frame-172">
                <div className="frame-173">
                  <div className="frame-174">
                    <div className="frame-175">
                      <img
                        className="frame-176"
                        alt="Frame"
                        src="/img/frame-40520-5.svg"
                      />
                      <div className="frame-177">
                        <div className="frame-74">
                          <div className="text-wrapper-83">Notion</div>
                          <p className="text-wrapper-55">
                            All-in-one solution for organising team retreats
                            &amp; offsites
                          </p>
                        </div>
                        <div className="frame-119">
                          <div className="frame-62">
                            <img
                              className="frame-139"
                              alt="Frame"
                              src="/img/frame-40515-5.svg"
                            />
                            <div className="text-wrapper-84">250</div>
                          </div>
                          <div className="frame-121">
                            <div className="text-wrapper-49">Free</div>
                            <div className="text-wrapper-49">Design</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-178">
                      <img
                        className="frame-179"
                        alt="Frame"
                        src="/img/frame-40522-5.svg"
                      />
                      <div className="text-wrapper-86">1,456</div>
                    </div>
                  </div>
                  <img className="line-42" alt="Line" src="/img/line-27.svg" />
                </div>
                <div className="frame-173">
                  <div className="frame-174">
                    <div className="frame-175">
                      <img
                        className="frame-176"
                        alt="Frame"
                        src="/img/frame-40520-4.svg"
                      />
                      <div className="frame-177">
                        <div className="frame-74">
                          <div className="text-wrapper-83">Explore Sales</div>
                          <p className="text-wrapper-55">
                            All-in-one solution for organising team retreats
                            &amp; offsites
                          </p>
                        </div>
                        <div className="frame-119">
                          <div className="frame-62">
                            <img
                              className="frame-139"
                              alt="Frame"
                              src="/img/frame-40515-4.svg"
                            />
                            <div className="text-wrapper-84">250</div>
                          </div>
                          <div className="frame-121">
                            <div className="text-wrapper-49">Humple+</div>
                            <div className="text-wrapper-49">Productivity</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-178">
                      <img
                        className="frame-179"
                        alt="Frame"
                        src="/img/frame-40522-4.svg"
                      />
                      <div className="text-wrapper-86">1,200</div>
                    </div>
                  </div>
                  <img
                    className="line-42"
                    alt="Line"
                    src="/img/line-28-2.svg"
                  />
                </div>
                <div className="frame-173">
                  <div className="frame-174">
                    <div className="frame-175">
                      <img
                        className="frame-176"
                        alt="Frame"
                        src="/img/frame-40520-3.svg"
                      />
                      <div className="frame-177">
                        <div className="frame-74">
                          <div className="text-wrapper-83">Explore Sales</div>
                          <p className="text-wrapper-55">
                            All-in-one solution for organising team retreats
                            &amp; offsites
                          </p>
                        </div>
                        <div className="frame-119">
                          <div className="frame-62">
                            <img
                              className="frame-139"
                              alt="Frame"
                              src="/img/frame-40515-3.svg"
                            />
                            <div className="text-wrapper-84">250</div>
                          </div>
                          <div className="frame-121">
                            <div className="text-wrapper-49">Humple+</div>
                            <div className="text-wrapper-49">Productivity</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-178">
                      <img
                        className="frame-179"
                        alt="Frame"
                        src="/img/frame-40522-3.svg"
                      />
                      <div className="text-wrapper-86">1,200</div>
                    </div>
                  </div>
                  <img
                    className="line-42"
                    alt="Line"
                    src="/img/line-28-1.svg"
                  />
                </div>
                <div className="frame-173">
                  <div className="frame-174">
                    <div className="frame-175">
                      <img
                        className="frame-176"
                        alt="Frame"
                        src="/img/frame-40520-2.svg"
                      />
                      <div className="frame-177">
                        <div className="frame-74">
                          <div className="text-wrapper-83">Notion</div>
                          <p className="text-wrapper-55">
                            All-in-one solution for organising team retreats
                            &amp; offsites
                          </p>
                        </div>
                        <div className="frame-119">
                          <div className="frame-62">
                            <img
                              className="frame-139"
                              alt="Frame"
                              src="/img/frame-40515-2.svg"
                            />
                            <div className="text-wrapper-84">250</div>
                          </div>
                          <div className="frame-121">
                            <div className="text-wrapper-49">Free</div>
                            <div className="text-wrapper-49">Design</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-178">
                      <img
                        className="frame-179"
                        alt="Frame"
                        src="/img/frame-40522-2.svg"
                      />
                      <div className="text-wrapper-86">1,456</div>
                    </div>
                  </div>
                  <img
                    className="line-42"
                    alt="Line"
                    src="/img/line-29-1.svg"
                  />
                </div>
                <div className="frame-173">
                  <div className="frame-174">
                    <div className="frame-175">
                      <img
                        className="frame-176"
                        alt="Frame"
                        src="/img/frame-40520-1.svg"
                      />
                      <div className="frame-177">
                        <div className="frame-74">
                          <div className="text-wrapper-83">Explore Sales</div>
                          <p className="text-wrapper-55">
                            All-in-one solution for organising team retreats
                            &amp; offsites
                          </p>
                        </div>
                        <div className="frame-119">
                          <div className="frame-62">
                            <img
                              className="frame-180"
                              alt="Frame"
                              src="/img/frame-40515-1.svg"
                            />
                            <div className="text-wrapper-84">250</div>
                          </div>
                          <div className="frame-121">
                            <div className="text-wrapper-49">Humple+</div>
                            <div className="text-wrapper-49">Productivity</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-178">
                      <img
                        className="frame-179"
                        alt="Frame"
                        src="/img/frame-40522-1.svg"
                      />
                      <div className="text-wrapper-86">1,200</div>
                    </div>
                  </div>
                  <img className="line-43" alt="Line" src="/img/line-28.svg" />
                </div>
                <div className="frame-173">
                  <div className="frame-174">
                    <div className="frame-175">
                      <img
                        className="frame-181"
                        alt="Frame"
                        src="/img/frame-40520.svg"
                      />
                      <div className="frame-177">
                        <div className="frame-74">
                          <div className="text-wrapper-83">Notion</div>
                          <p className="text-wrapper-55">
                            All-in-one solution for organising team retreats
                            &amp; offsites
                          </p>
                        </div>
                        <div className="frame-119">
                          <div className="frame-62">
                            <img
                              className="frame-182"
                              alt="Frame"
                              src="/img/social-buttons.png"
                            />
                            <div className="text-wrapper-84">250</div>
                          </div>
                          <div className="frame-121">
                            <div className="text-wrapper-49">Free</div>
                            <div className="text-wrapper-49">Design</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-178">
                      <img
                        className="frame-183"
                        alt="Frame"
                        src="/img/social-buttons.png"
                      />
                      <div className="text-wrapper-86">1,456</div>
                    </div>
                  </div>
                  <img
                    className="line-44"
                    alt="Line"
                    src="/img/divider-6.png"
                  />
                </div>
              </div>
              <div className="footers-4">
                <div className="frame-36">
                  <div className="frame-37">
                    <div className="frame-38">
                      <div className="image-wrapper">
                        <img
                          className="image-9"
                          alt="Image"
                          src="/img/social-buttons.png"
                        />
                      </div>
                      <img
                        className="social-buttons-4"
                        alt="Social buttons"
                        src="/img/social-buttons.png"
                      />
                    </div>
                    <div className="frame-39">
                      <div className="div-5">
                        <div className="text-wrapper-28">Heading 1</div>
                        <div className="text-wrapper-29">Our Team</div>
                        <div className="text-wrapper-29">Company Vision</div>
                        <div className="text-wrapper-29">Our History</div>
                        <div className="text-wrapper-29">Instrutors</div>
                      </div>
                      <div className="div-5">
                        <div className="text-wrapper-28">Heading 2</div>
                        <div className="text-wrapper-30">The Blog</div>
                        <div className="text-wrapper-29">Downloads</div>
                        <div className="text-wrapper-30">Change Log</div>
                        <div className="text-wrapper-30">Slack Channel</div>
                      </div>
                      <div className="div-5">
                        <div className="text-wrapper-28">Heading 3</div>
                        <div className="text-wrapper-29">New Features</div>
                        <div className="text-wrapper-29">Roadmap</div>
                        <div className="text-wrapper-29">Product Demo</div>
                        <div className="text-wrapper-29">Pricing</div>
                      </div>
                      <div className="div-5">
                        <div className="text-wrapper-28">Heading 4</div>
                        <div className="text-wrapper-29">New Features</div>
                        <div className="text-wrapper-29">Roadmap</div>
                        <div className="text-wrapper-29">Product Demo</div>
                        <div className="text-wrapper-29">Pricing</div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="divider-5"
                    alt="Divider"
                    src="/img/divider-6.png"
                  />
                  <div className="text-wrapper-31">
                    Copyright 2023 humble.inc
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="concrete-urban-wall"
          alt="Concrete urban wall"
          src="/img/concrete-urban-wall-glued-triple-poster-mockup-template-64d23c77.png"
        />
        <img className="notebook" alt="Notebook" src="/img/notebook-2.png" />
        <Tab
          className="tab-instance"
          frameClassName="design-component-instance-node"
          img="/img/vector-35-1.svg"
          stateProp="default"
          subtract="/img/subtract-2.svg"
          vector="/img/vector-40-1.svg"
          vector1="/img/vector-36-1.svg"
          vector2="/img/vector-37-1.svg"
          vector3="/img/vector-38-1.svg"
        />
        <StateDefaultWrapper
          className="tab-2"
          frame="/img/frame-40379-1.svg"
          frameClassName="design-component-instance-node"
          stateProp="default"
        />
        <Component
          className="component-1"
          frame="/img/frame-40379.svg"
          frameClassName="design-component-instance-node"
          property1="default"
          text="Mental well being"
        />
        <img
          className="shadow-overlayed"
          alt="Shadow overlayed"
          src="/img/shadow-overlayed-business-card-and-papers-branding-identity-stat.svg"
        />
        <div className="frame-184">
          <img className="homepage-3" alt="Homepage" src="/img/homepage.png" />
          <div className="humble">
            <div className="overlap-26">
              <div className="frame-185">
                <div className="overlap-27">
                  <div className="ellipse-14" />
                  <img
                    className="frame-186"
                    alt="Frame"
                    src="/img/frame-40775.png"
                  />
                </div>
                <div className="overlap-group-11">
                  <div className="frame-187">
                    <div className="frame-188">
                      <div className="frame-189">
                        <div className="text-wrapper-94">humble+</div>
                      </div>
                      <div className="frame-190">
                        <div className="text-wrapper-95">
                          Level up your thinking
                        </div>
                        <p className="text-wrapper-96">
                          Become a Humble+ to enjoy unlimited access to premium
                          content from experts, product deals and much more.
                        </p>
                      </div>
                    </div>
                    <button className="CTA">
                      <div className="text-wrapper-97">
                        Get Unlimited Access
                      </div>
                    </button>
                  </div>
                  <img
                    className="frame-191"
                    alt="Frame"
                    src="/img/frame-40776.png"
                  />
                </div>
              </div>
              <img className="vector-7" alt="Vector" src="/img/vector-69.svg" />
              <div className="frame-192">
                <div className="frame-193">
                  <p className="text-wrapper-98">
                    Get unlimited access to everything on humble
                  </p>
                  <p className="text-wrapper-99">
                    With no paywalls, Story limits. Plans starting at less than
                    $1/week. Cancel anytime.
                  </p>
                </div>
                <div className="frame-194">
                  <div className="frame-195">
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="overlap-group-12">
                          <img
                            className="group-4"
                            alt="Group"
                            src="/img/group-33.png"
                          />
                          <img
                            className="vector-8"
                            alt="Vector"
                            src="/img/vector-2.svg"
                          />
                          <img
                            className="vector-9"
                            alt="Vector"
                            src="/img/vector-1.svg"
                          />
                        </div>
                      </div>
                      <div className="text-wrapper-100">Products</div>
                    </div>
                    <div className="frame-196">
                      <img
                        className="frame-198"
                        alt="Frame"
                        src="/img/frame-40763.svg"
                      />
                      <div className="text-wrapper-100">Creators</div>
                    </div>
                  </div>
                  <div className="frame-199">
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="vector-wrapper">
                          <img
                            className="vector-10"
                            alt="Vector"
                            src="/img/vector.svg"
                          />
                        </div>
                      </div>
                      <div className="text-wrapper-100">Content</div>
                    </div>
                    <div className="frame-196">
                      <div className="frame-197">
                        <div className="group-5">
                          <div className="overlap-group-13">
                            <img
                              className="vector-11"
                              alt="Vector"
                              src="/img/vector-40.svg"
                            />
                            <img
                              className="vector-12"
                              alt="Vector"
                              src="/img/vector-35.svg"
                            />
                            <img
                              className="vector-13"
                              alt="Vector"
                              src="/img/vector-36.svg"
                            />
                            <img
                              className="vector-14"
                              alt="Vector"
                              src="/img/vector-37.svg"
                            />
                            <img
                              className="vector-14"
                              alt="Vector"
                              src="/img/vector-38.svg"
                            />
                            <img
                              className="subtract-2"
                              alt="Subtract"
                              src="/img/subtract-1.svg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-wrapper-100">Mentorship</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-wrapper-2">
                <div className="frame-193">
                  <div className="price">
                    <div className="frame-200">
                      <div className="div-6">
                        <div className="text-wrapper-101">$</div>
                        <div className="text-wrapper-102">19</div>
                      </div>
                      <div className="text-wrapper-103">/ month</div>
                    </div>
                  </div>
                  <div className="frame-201">
                    <p className="text-wrapper-104">
                      Take your learning and business to the next level with
                      startup friendly perks
                    </p>
                    <img
                      className="line-45"
                      alt="Line"
                      src="/img/line-60-1.svg"
                    />
                    <div className="frame-202">
                      <div className="frame-203">
                        <img
                          className="img-10"
                          alt="Check circle"
                          src="/img/check-circle-21.png"
                        />
                        <p className="text-wrapper-105">
                          Access to top 20 content creators
                        </p>
                      </div>
                      <div className="frame-203">
                        <img
                          className="img-10"
                          alt="Check circle"
                          src="/img/check-circle-20.png"
                        />
                        <div className="text-wrapper-106">
                          10 Content Creator Channels
                        </div>
                      </div>
                      <div className="frame-203">
                        <img
                          className="img-10"
                          alt="Check circle"
                          src="/img/check-circle-19.png"
                        />
                        <div className="text-wrapper-106">
                          100GB Google Drive Storage
                        </div>
                      </div>
                      <div className="frame-203">
                        <img
                          className="img-10"
                          alt="Check circle"
                          src="/img/check-circle-18.png"
                        />
                        <div className="text-wrapper-105">Rocket Lawyer</div>
                      </div>
                      <div className="frame-204">
                        <img
                          className="img-10"
                          alt="Check circle"
                          src="/img/check-circle-17.png"
                        />
                        <p className="text-wrapper-107">
                          One month Humble swag box subscription
                        </p>
                      </div>
                      <div className="frame-204">
                        <img
                          className="img-10"
                          alt="Check circle"
                          src="/img/check-circle-16.png"
                        />
                        <p className="text-wrapper-107">
                          Discount codes on monthly subscription
                        </p>
                      </div>
                      <div className="frame-204">
                        <img
                          className="img-10"
                          alt="Check circle"
                          src="/img/check-circle-15.png"
                        />
                        <p className="text-wrapper-107">
                          20% off Mentoring office hours
                        </p>
                      </div>
                      <div className="frame-204">
                        <img
                          className="img-10"
                          alt="Check circle"
                          src="/img/check-circle-14.png"
                        />
                        <p className="text-wrapper-107">
                          20% off all marketplace products
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="button-primary-with-wrapper">
                    <ButtonPrimaryWith
                      className="button-primary-with-icon"
                      divClassName="button-primary-with-icon-instance"
                      frameClassName="button-primary-with-instance"
                      hasIcon={false}
                      text="Upgrade Now"
                    />
                  </div>
                </div>
              </div>
              <div className="frame-wrapper-3">
                <div className="frame-193">
                  <div className="price-2">
                    <div className="frame-205">
                      <div className="frame-206">
                        <div className="div-6">
                          <div className="text-wrapper-101">$</div>
                          <div className="text-wrapper-102">19</div>
                        </div>
                        <div className="text-wrapper-103">/ year</div>
                      </div>
                      <div className="frame-207">
                        <div className="text-wrapper-108">$</div>
                        <div className="text-wrapper-109">19</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-201">
                    <p className="text-wrapper-104">
                      The ultimate industry and business and entrepreneur
                      intelligence engine
                    </p>
                    <img
                      className="line-46"
                      alt="Line"
                      src="/img/line-60.svg"
                    />
                  </div>
                  <div className="frame-202">
                    <div className="frame-203">
                      <img
                        className="img-10"
                        alt="Check circle"
                        src="/img/check-circle-13.png"
                      />
                      <p className="text-wrapper-110">
                        Unlimited Access to top 20 content creators
                      </p>
                    </div>
                    <div className="frame-203">
                      <img
                        className="img-10"
                        alt="Check circle"
                        src="/img/check-circle-12.png"
                      />
                      <p className="text-wrapper-111">
                        Unlimited access to all “How to” videos
                      </p>
                    </div>
                    <div className="frame-203">
                      <img
                        className="img-10"
                        alt="Check circle"
                        src="/img/check-circle-11.png"
                      />
                      <p className="text-wrapper-111">
                        10 Content creator channels per month
                      </p>
                    </div>
                    <div className="frame-203">
                      <img
                        className="img-10"
                        alt="Check circle"
                        src="/img/check-circle-10.png"
                      />
                      <div className="text-wrapper-111">
                        500GB Google Drive Storage
                      </div>
                    </div>
                    <div className="frame-203">
                      <img
                        className="img-10"
                        alt="Check circle"
                        src="/img/check-circle-9.png"
                      />
                      <div className="text-wrapper-111">
                        Rocket Lawyer Annual Subscription
                      </div>
                    </div>
                    <div className="frame-204">
                      <img
                        className="img-10"
                        alt="Check circle"
                        src="/img/check-circle-8.png"
                      />
                      <div className="text-wrapper-110">
                        Humble Swag Founder Kit
                      </div>
                    </div>
                    <div className="frame-204">
                      <img
                        className="img-10"
                        alt="Check circle"
                        src="/img/check-circle-7.png"
                      />
                      <p className="text-wrapper-110">
                        10,000 discount codes and benefits
                      </p>
                    </div>
                    <div className="frame-204">
                      <img
                        className="img-10"
                        alt="Check circle"
                        src="/img/check-circle-6.png"
                      />
                      <p className="text-wrapper-110">
                        30% off Mentoring office hours
                      </p>
                    </div>
                    <div className="frame-204">
                      <img
                        className="img-10"
                        alt="Check circle"
                        src="/img/check-circle-5.png"
                      />
                      <p className="text-wrapper-110">
                        20% off all marketplace products
                      </p>
                    </div>
                    <div className="frame-204">
                      <img
                        className="img-10"
                        alt="Check circle"
                        src="/img/check-circle-4.png"
                      />
                      <p className="text-wrapper-110">
                        20% off all subscriptions in humble Marketplace
                      </p>
                    </div>
                  </div>
                  <div className="button-primary-with-wrapper">
                    <img
                      className="button-primary-with-2"
                      alt="Button primary with"
                      src="/img/button-primary-with-icon.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <Header
              CTASClassName="header-12"
              buttonBaseClassName="header-14"
              buttonClassName="header-13"
              className="header-instance"
              divClassName="header-8"
              divClassNameOverride="header-10"
              divContainerClassName="header-9"
              frameClassName="header-4"
              frameClassNameOverride="header-2"
              humbleLogo="/img/humble-logo-1-14.png"
              humbleLogoClassName="header-3"
              navLinkContainerClassName="header-5"
              navLinkContainerClassName1="header-5"
              navLinkContainerClassName2="header-5"
              navLinkContainerClassNameOverride="header-5"
              navLinkDivClassName="header-6"
              navLinkDivClassName1="header-6"
              navLinkDivClassName2="header-6"
              navLinkDivClassNameOverride="header-6"
              navLinkStateDefaultDarkClassName="header-7"
              navLinkStateDefaultDarkClassNameOverride="header-7"
              navLinkStateHoverDarkClassName="header-7"
              navLinkStateHoverDarkClassNameOverride="header-7"
              quickLinksClassName="header-2"
              search="/img/search-8.svg"
              searchClassName="header-11"
              state="secondary-dark"
              textClassName="header-6"
            />
            <div className="quotes">
              <p className="for-me-the-access-to">
                &#34;For me, the access to a variety of ideas and perspectives
                is invaluable. Medium brings people from all over into one
                shared space where we can grow and learn together.&#34;
              </p>
              <div className="position-indicator">
                <div className="active" />
                <div className="default-2" />
                <div className="default-3" />
                <div className="default-4" />
              </div>
              <Avatar
                className="avatar-instance"
                divClassName="avatar-2"
                divClassNameOverride="avatar-3"
                image="/img/image-1.svg"
                imageClassName="avatar-4"
              />
              <p className="text-wrapper-112">
                Join 10,000+ happy humble+ users
              </p>
            </div>
            <div className="frame-208">
              <p className="text-wrapper-113">
                Take your mind in new directions.
              </p>
              <button className="CTA">
                <div className="text-wrapper-97">Get Unlimited Access</div>
              </button>
            </div>
            <div className="footers-5">
              <div className="address">
                <p className="element-upper-rupert">
                  32&nbsp;&nbsp;- 44 Upper Rupert Street
                </p>
                <div className="text-wrapper-114">W12 8DY Kensington</div>
                <div className="text-wrapper-115">United Kingdom</div>
              </div>
              <div className="company-links">
                <div className="text-wrapper-116">About us</div>
                <div className="text-wrapper-116">Privacy Policy</div>
                <div className="text-wrapper-116">Legal</div>
              </div>
              <p className="text-wrapper-117">
                Copyright 2023 99x technologies Pvt Ltd.
              </p>
              <img
                className="divider-6"
                alt="Divider"
                src="/img/divider-5.svg"
              />
              <img
                className="frame-209"
                alt="Frame"
                src="/img/frame-40362-4.svg"
              />
              <img
                className="frame-210"
                alt="Frame"
                src="/img/frame-3773-4.svg"
              />
            </div>
          </div>
          <div className="sign-up">
            <div className="overlap-28">
              <div className="overlap-29">
                <div className="overlap-30">
                  <img
                    className="rectangle-22"
                    alt="Rectangle"
                    src="/img/rectangle-26.png"
                  />
                  <img
                    className="rectangle-23"
                    alt="Rectangle"
                    src="/img/rectangle-29.png"
                  />
                  <img
                    className="frame-211"
                    alt="Frame"
                    src="/img/frame-40362-3.svg"
                  />
                  <div className="rectangle-24" />
                  <div className="container-wrapper">
                    <div className="div-4">
                      <img
                        className="quick-links"
                        alt="Quick links"
                        src="/img/quick-links-8.svg"
                      />
                      <div className="CTA-s-2">
                        <div className="text-wrapper-118">Sign In</div>
                        <button className="button-10">
                          <button className="button-base-15">
                            <div className="text-11">Sign Up</div>
                          </button>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="rectangle-25"
                  alt="Rectangle"
                  src="/img/rectangle-27.png"
                />
                <div className="frame-212">
                  <div className="frame-213">
                    <div className="frame-201">
                      <div className="frame-214">
                        <div className="frame-215">
                          <p className="ready-to-build">
                            <span className="text-wrapper-119">
                              Ready to build{" "}
                            </span>
                            <span className="text-wrapper-120">product</span>
                          </p>
                          <div className="text-wrapper-121">humblitiously?</div>
                        </div>
                        <div className="frame-216">
                          <div className="frame-217">
                            <div className="text-wrapper-122">Sign up with</div>
                            <div className="frame-218">
                              <div className="frame-219">
                                <img
                                  className="img-10"
                                  alt="Frame"
                                  src="/img/frame-40613-1.svg"
                                />
                                <div className="text-12">Google</div>
                              </div>
                              <div className="frame-219">
                                <img
                                  className="img-10"
                                  alt="Frame"
                                  src="/img/frame-40616-1.svg"
                                />
                                <div className="text-12">Facebook</div>
                              </div>
                              <div className="frame-219">
                                <img
                                  className="img-10"
                                  alt="Frame"
                                  src="/img/frame-40617-1.svg"
                                />
                                <div className="text-12">Apple</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-220">
                            <img
                              className="line-47"
                              alt="Line"
                              src="/img/line-15-1.svg"
                            />
                            <div className="text-13">Or</div>
                            <img
                              className="line-47"
                              alt="Line"
                              src="/img/line-16-1.svg"
                            />
                          </div>
                          <div className="frame-221">
                            <div className="frame-222">
                              <div className="frame-223">
                                <div className="text-wrapper-123">
                                  Email address
                                </div>
                              </div>
                              <div className="frame-224">
                                <div className="text-wrapper-123">
                                  Create a password
                                </div>
                              </div>
                            </div>
                            <div className="button-base-16">
                              <div className="text-14">Create Account</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="got-an-account">
                        <span className="text-wrapper-124">
                          Got an account already?{" "}
                        </span>
                        <span className="text-wrapper-125">Login Now!</span>
                      </p>
                    </div>
                    <p className="by-signing-up-you">
                      <span className="text-wrapper-126">
                        By signing up you agree to Humble{" "}
                      </span>
                      <span className="text-wrapper-127">Terms of Service</span>
                      <span className="text-wrapper-126"> and </span>
                      <span className="text-wrapper-127">Privacy Policy</span>
                      <span className="text-wrapper-126">. </span>
                    </p>
                  </div>
                </div>
              </div>
              <img
                className="rectangle-26"
                alt="Rectangle"
                src="/img/rectangle-32.png"
              />
              <img
                className="rectangle-27"
                alt="Rectangle"
                src="/img/rectangle-31.png"
              />
            </div>
            <img
              className="rectangle-28"
              alt="Rectangle"
              src="/img/rectangle-35.png"
            />
            <img
              className="rectangle-29"
              alt="Rectangle"
              src="/img/rectangle-34.png"
            />
            <img
              className="rectangle-30"
              alt="Rectangle"
              src="/img/rectangle-37.png"
            />
            <img
              className="rectangle-31"
              alt="Rectangle"
              src="/img/rectangle-38.png"
            />
            <img
              className="rectangle-32"
              alt="Rectangle"
              src="/img/rectangle-36.png"
            />
          </div>
          <div className="signup-interests">
            <div className="frame-225">
              <div className="frame-226">
                <div className="frame-227">
                  <div className="frame-228">
                    <div className="text-wrapper-128">
                      Choose your interests
                    </div>
                    <div className="text-15">Choose three or more!</div>
                  </div>
                  <div className="frame-229">
                    <div className="frame-216">
                      <div className="frame-230">
                        <div className="frame-231">
                          <div className="frame-232">
                            <div className="text-wrapper-129">Design</div>
                          </div>
                          <div className="frame-233">
                            <div className="text-wrapper-130">
                              Communication
                            </div>
                          </div>
                          <div className="frame-233">
                            <div className="text-wrapper-130">Finance</div>
                          </div>
                          <div className="frame-233">
                            <div className="text-wrapper-130">
                              Task Management
                            </div>
                          </div>
                          <div className="frame-233">
                            <div className="text-wrapper-130">Utilities</div>
                          </div>
                          <div className="frame-233">
                            <div className="text-wrapper-130">
                              Product Managment
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-230">
                        <div className="frame-234">
                          <div className="frame-235">
                            <div className="text-wrapper-130">Data Science</div>
                          </div>
                          <div className="frame-235">
                            <div className="text-wrapper-130">Technology</div>
                          </div>
                          <div className="frame-235">
                            <div className="text-wrapper-130">
                              Self improvement
                            </div>
                          </div>
                          <div className="frame-236">
                            <div className="text-wrapper-131">Writing</div>
                          </div>
                          <div className="frame-236">
                            <div className="text-wrapper-131">
                              Machine Learning
                            </div>
                          </div>
                          <div className="frame-235">
                            <div className="text-wrapper-130">Productivity</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-237">
                        <div className="frame-234">
                          <div className="frame-235">
                            <div className="text-wrapper-130">Politics</div>
                          </div>
                          <div className="frame-236">
                            <div className="text-wrapper-131">
                              Cryptocurrency
                            </div>
                          </div>
                          <div className="frame-235">
                            <div className="text-wrapper-130">Psychology</div>
                          </div>
                          <div className="frame-235">
                            <div className="text-wrapper-130">Money</div>
                          </div>
                          <div className="frame-235">
                            <div className="text-wrapper-130">Business</div>
                          </div>
                          <div className="frame-235">
                            <div className="text-wrapper-130">
                              Software Development
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-230">
                        <div className="frame-234">
                          <div className="frame-235">
                            <div className="text-wrapper-130">Python</div>
                          </div>
                          <div className="frame-235">
                            <div className="text-wrapper-130">Health</div>
                          </div>
                          <div className="frame-235">
                            <div className="text-wrapper-130">Science</div>
                          </div>
                          <div className="frame-235">
                            <div className="text-wrapper-130">
                              Mental Health
                            </div>
                          </div>
                          <div className="frame-235">
                            <div className="text-wrapper-130">Life</div>
                          </div>
                          <div className="frame-235">
                            <div className="text-wrapper-130">Design</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-16">See More</div>
                  </div>
                </div>
                <button className="button-11">
                  <button className="button-base-17">
                    <div className="text-17">Continue</div>
                  </button>
                </button>
              </div>
            </div>
            <img className="header-15" alt="Header" src="/img/header.svg" />
          </div>
          <div className="content-locked">
            <div className="overlap-31">
              <div className="blog">
                <div className="frame-238" />
              </div>
              <div className="header-16">
                <div className="div-4">
                  <div className="div-7">
                    <img
                      className="humble-logo-2"
                      alt="Humble logo"
                      src="/img/humble-logo-1-13.svg"
                    />
                    <div className="frame-239">
                      <div className="frame-240">
                        <div className="text-wrapper-132">Products</div>
                      </div>
                      <div className="frame-240">
                        <div className="text-wrapper-133">Guides</div>
                      </div>
                      <div className="frame-240">
                        <div className="text-wrapper-133">Topics</div>
                      </div>
                      <div className="frame-240">
                        <div className="text-wrapper-132">Humble+</div>
                      </div>
                    </div>
                  </div>
                  <div className="div-7">
                    <div className="frame-241">
                      <div className="div-container-wrapper">
                        <div className="search-wrapper">
                          <div className="div-placeholder-wrapper">
                            <div className="div-placeholder-2">
                              <div className="text-wrapper-134">Search</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="search-3"
                        alt="Search"
                        src="/img/search-7.svg"
                      />
                    </div>
                    <div className="CTA-s-3">
                      <div className="frame-242">
                        <img
                          className="edit"
                          alt="Edit"
                          src="/img/edit-11.png"
                        />
                        <div className="text-wrapper-135">Write</div>
                      </div>
                      <div className="frame-243" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-32">
              <div className="footers-6">
                <div className="address">
                  <p className="element-upper-rupert">
                    32&nbsp;&nbsp;- 44 Upper Rupert Street
                  </p>
                  <div className="text-wrapper-114">W12 8DY Kensington</div>
                  <div className="text-wrapper-115">United Kingdom</div>
                </div>
                <div className="company-links">
                  <div className="text-wrapper-116">About us</div>
                  <div className="text-wrapper-116">Privacy Policy</div>
                  <div className="text-wrapper-116">Legal</div>
                </div>
                <p className="text-wrapper-117">
                  Copyright 2023 99x technologies Pvt Ltd.
                </p>
                <img
                  className="divider-7"
                  alt="Divider"
                  src="/img/divider-2.png"
                />
                <img className="frame-244" alt="Frame" src="/img/divider.png" />
                <img className="frame-245" alt="Frame" src="/img/divider.png" />
              </div>
              <div className="frame-246">
                <div className="frame-247">
                  <div className="div-8">
                    <div className="label-container">
                      <div className="button-tall-fixed">
                        <div className="text-18">DESIGN</div>
                      </div>
                      <div className="ellipse-15" />
                      <div className="button-tall-fixed-2">
                        <div className="text-19">15 min read</div>
                      </div>
                    </div>
                    <p className="element-types-of">
                      10 types of cognitive bias to watch out for in UX research
                      &amp; design
                    </p>
                    <img
                      className="line-48"
                      alt="Line"
                      src="/img/line-9-2.svg"
                    />
                  </div>
                </div>
                <div className="frame-248">
                  <div className="ellipse-16" />
                  <img
                    className="bookmark-add"
                    alt="Bookmark add"
                    src="/img/divider.png"
                  />
                </div>
              </div>
              <div className="frame-249">
                <div className="frame-250">
                  <div className="frame-251">
                    <div className="frame-248">
                      <div className="overlap-group-14">
                        <img
                          className="ellipse-17"
                          alt="Ellipse"
                          src="/img/ellipse-51-2.png"
                        />
                        <img
                          className="ellipse-17"
                          alt="Ellipse"
                          src="/img/ellipse-51-2.png"
                        />
                      </div>
                    </div>
                    <div className="text-wrapper-136">Arthur Shelby</div>
                  </div>
                  <div className="frame-252">
                    <div className="text-20">July 14, 2022</div>
                    <div className="text-21">·</div>
                    <div className="text-20">8 min read</div>
                  </div>
                </div>
                <div className="button-tall-fixed-wrapper">
                  <div className="button-tall-fixed-3">
                    <img
                      className="img-11"
                      alt="Play circle"
                      src="/img/play-circle-2.png"
                    />
                    <div className="text-22">Listen to this article</div>
                  </div>
                </div>
              </div>
              <div className="frame-253">
                <div className="frame-254">
                  <div className="frame-254">
                    <p className="lorem-ipsum-is-6">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      industry&#39;s standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book. It has survived
                      not only five centuries, but also the leap into electronic
                      typesetting, remaining essentially unchanged. It was
                      popularised in the 1960s with the release of Letraset
                      sheets containing Lorem Ipsum passages, and more recently
                      with desktop publishing software like Aldus PageMaker
                      including versions of Lorem Ipsum.
                    </p>
                    <Text property1="default" />
                    <div className="frame-255">
                      <div className="lorem-ipsum-is-wrapper">
                        <p className="lorem-ipsum-is-7">
                          <span className="text-wrapper-137">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry&#39;s standard dummy text ever since the
                            1500s, when an unknown printer took{" "}
                          </span>
                          <span className="text-wrapper-138">
                            a galley of type and scrambled it to make a type
                            specimen book
                          </span>
                          <span className="text-wrapper-137">
                            . It has survived not only five centuries,
                            <br />
                            <br />
                            But also the leap into electronic typesetting,
                            remaining essentially unchanged. It was popularised
                            in the 1960s with the release of Letraset sheets
                            containing{" "}
                          </span>
                        </p>
                      </div>
                      <div className="frame-256">
                        <div className="rectangle-33" />
                        <p className="text-wrapper-139">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </div>
                    <p className="lorem-ipsum-is-8">
                      <span className="text-wrapper-137">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry&#39;s standard dummy text ever since the 1500s,
                        when an unknown printer took{" "}
                      </span>
                      <span className="text-wrapper-138">
                        a galley of type and scrambled it to make a type
                        specimen book
                      </span>
                      <span className="text-wrapper-137">
                        . It has survived not only five centuries, but also the
                        leap into electronic typesetting, remaining essentially
                        unchanged. It was popularised in the 1960s with the
                        release of Letraset sheets containing Lorem Ipsum
                        passages, and more recently with desktop publishing
                        software like Aldus PageMaker including versions of
                        Lorem Ipsum.
                      </span>
                    </p>
                  </div>
                  <img
                    className="line-49"
                    alt="Line"
                    src="/img/divider-2.png"
                  />
                  <div className="frame-257">
                    <button className="button-tall-fixed-4">
                      <img
                        className="maps-ugc"
                        alt="Maps ugc"
                        src="/img/divider.png"
                      />
                      <div className="text-23">Comment</div>
                    </button>
                    <button className="button-tall-fixed-4">
                      <img
                        className="ios-share"
                        alt="Ios share"
                        src="/img/divider.png"
                      />
                      <div className="text-23">Share</div>
                    </button>
                  </div>
                </div>
                <div className="frame-258">
                  <div className="text-24">Tags</div>
                  <div className="frame-259">
                    <div className="frame-260">
                      <div className="text-wrapper-140">Task Management</div>
                    </div>
                    <div className="frame-260">
                      <div className="text-wrapper-140">Finance</div>
                    </div>
                    <div className="frame-260">
                      <div className="text-wrapper-140">Utilities</div>
                    </div>
                    <div className="frame-260">
                      <div className="text-wrapper-140">Task Management</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rectangle-34" />
              <div className="overlap-wrapper">
                <div className="overlap-33">
                  <div className="overlap-34">
                    <div className="frame-261">
                      <div className="frame-262">
                        <p className="text-wrapper-141">
                          Read this story from Arthur Shelby — and everything
                          else with Humble+
                        </p>
                        <div className="frame-202">
                          <div className="frame-263">
                            <div className="frame-264">
                              <img
                                className="img-10"
                                alt="Check circle"
                                src="/img/check-circle-3.png"
                              />
                              <p className="text-wrapper-142">
                                Dive deeper into the topics that matter to you
                              </p>
                            </div>
                            <div className="frame-264">
                              <img
                                className="img-10"
                                alt="Check circle"
                                src="/img/check-circle-2.png"
                              />
                              <p className="text-wrapper-142">
                                Get Access to Experts &amp; Mentors
                              </p>
                            </div>
                            <div className="frame-265">
                              <img
                                className="img-10"
                                alt="Check circle"
                                src="/img/check-circle-1.png"
                              />
                              <p className="text-wrapper-143">
                                Achieve your personal and professional goals
                              </p>
                            </div>
                            <div className="frame-265">
                              <img
                                className="img-10"
                                alt="Check circle"
                                src="/img/check-circle.png"
                              />
                              <p className="text-wrapper-144">
                                Get Access to curated startup stash and products
                                for your business growth
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="group-6"
                      alt="Group"
                      src="/img/group-121.png"
                    />
                    <div className="group-7">
                      <div className="overlap-group-15">
                        <div className="ellipse-18" />
                        <img
                          className="subtract-3"
                          alt="Subtract"
                          src="/img/subtract.svg"
                        />
                        <img
                          className="ellipse-19"
                          alt="Ellipse"
                          src="/img/ellipse-39.svg"
                        />
                        <img
                          className="ellipse-20"
                          alt="Ellipse"
                          src="/img/ellipse-40.svg"
                        />
                      </div>
                    </div>
                    <div className="group-8">
                      <div className="overlap-35">
                        <img
                          className="vector-15"
                          alt="Vector"
                          src="/img/vector-56.svg"
                        />
                        <img
                          className="vector-16"
                          alt="Vector"
                          src="/img/vector-57.svg"
                        />
                        <img
                          className="rectangle-35"
                          alt="Rectangle"
                          src="/img/rectangle-19.svg"
                        />
                        <div className="rectangle-36" />
                      </div>
                    </div>
                  </div>
                  <ButtonPrimaryWith
                    className="button-primary-with-3"
                    divClassName="button-primary-with-4"
                    frameClassName="button-primary-with-instance"
                    hasIcon={false}
                    text="Get Unlimited Access Now!"
                  />
                </div>
              </div>
            </div>
            <div className="frame-266">
              <p className="recommended-topics">
                <span className="text-wrapper-145">Recommended </span>
                <span className="text-wrapper-146">Topics</span>
              </p>
              <div className="frame-267">
                <div className="frame-259">
                  <div className="button-tall-fixed-5">
                    <div className="text-25">Design</div>
                  </div>
                  <div className="button-tall-fixed-5">
                    <div className="text-25">Communication</div>
                  </div>
                  <div className="button-tall-fixed-5">
                    <div className="text-25">Finance</div>
                  </div>
                </div>
                <div className="frame-259">
                  <div className="button-tall-fixed-5">
                    <div className="text-25">Design</div>
                  </div>
                  <div className="button-tall-fixed-5">
                    <div className="text-25">Marketing</div>
                  </div>
                  <div className="button-tall-fixed-5">
                    <div className="text-25">Task Management</div>
                  </div>
                </div>
                <div className="frame-259">
                  <div className="button-tall-fixed-5">
                    <div className="text-25">Utilities</div>
                  </div>
                  <div className="button-tall-fixed-5">
                    <div className="text-25">Product Management</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-268">
              <div className="frame-269">
                <div className="div-9">
                  <div className="frame-270">
                    <div className="text-wrapper-147">More Like this</div>
                  </div>
                </div>
              </div>
              <div className="frame-271">
                <div className="frame-272">
                  <img
                    className="rectangle-37"
                    alt="Rectangle"
                    src="/img/divider.png"
                  />
                  <div className="frame-273">
                    <div className="frame-274">
                      <div className="frame-275">
                        <div className="frame-270">
                          <div className="button-tall-fixed-6">
                            <div className="text-26">DESIGN</div>
                          </div>
                          <div className="ellipse-21" />
                          <div className="button-tall-fixed-7">
                            <div className="text-27">15 min read</div>
                          </div>
                          <div className="ellipse-21" />
                          <div className="button-tall-fixed-7">
                            <div className="text-28">Based on your topics</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-276">
                      <div className="frame-277">
                        <p className="text-wrapper-148">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </div>
                    <div className="text-29">July 14, 2022</div>
                  </div>
                </div>
                <div className="frame-272">
                  <img
                    className="rectangle-38"
                    alt="Rectangle"
                    src="/img/divider.png"
                  />
                  <div className="frame-273">
                    <div className="frame-270">
                      <div className="button-tall-fixed-6">
                        <div className="text-26">DESIGN</div>
                      </div>
                      <div className="ellipse-21" />
                      <div className="button-tall-fixed-7">
                        <div className="text-27">15 min read</div>
                      </div>
                      <div className="ellipse-21" />
                      <div className="button-tall-fixed-7">
                        <div className="text-28">Based on your topics</div>
                      </div>
                    </div>
                    <div className="frame-276">
                      <div className="frame-277">
                        <p className="text-wrapper-148">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </div>
                    <div className="text-29">July 14, 2022</div>
                  </div>
                </div>
                <div className="frame-272">
                  <img
                    className="rectangle-39"
                    alt="Rectangle"
                    src="/img/divider.png"
                  />
                  <div className="frame-273">
                    <div className="frame-270">
                      <div className="button-tall-fixed-6">
                        <div className="text-26">DESIGN</div>
                      </div>
                      <div className="ellipse-21" />
                      <div className="button-tall-fixed-7">
                        <div className="text-27">15 min read</div>
                      </div>
                      <div className="ellipse-21" />
                      <div className="button-tall-fixed-7">
                        <div className="text-28">Based on your topics</div>
                      </div>
                    </div>
                    <div className="frame-276">
                      <div className="frame-277">
                        <p className="text-wrapper-148">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </div>
                    <div className="text-29">July 14, 2022</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="newsletter">
              <p className="text-wrapper-149">
                Get the best of Humble directly in your inbox. 💌
              </p>
              <div className="frame-28">
                <div className="frame-278">
                  <div className="text-wrapper-150">Your email</div>
                </div>
                <div className="frame-279">
                  <div className="text-wrapper-151">
                    Subscribe to the newsletter
                  </div>
                </div>
              </div>
            </div>
            <img className="line-50" alt="Line" src="/img/divider-2.png" />
          </div>
          <div className="video-unlocked">
            <div className="blog-2">
              <div className="frame-280">
                <img
                  className="frame-281"
                  alt="Frame"
                  src="/img/frame-40822-2.svg"
                />
                <div className="controls">
                  <div className="frame-282">
                    <div className="div-6">
                      <div className="frame-283">
                        <img
                          className="play-arrow"
                          alt="Play arrow"
                          src="/img/divider.png"
                        />
                      </div>
                      <div className="frame-283">
                        <img
                          className="volume-up"
                          alt="Volume up"
                          src="/img/divider.png"
                        />
                      </div>
                    </div>
                    <div className="text-wrapper-152">0:00/0:00</div>
                  </div>
                  <div className="frame-284" />
                </div>
              </div>
            </div>
            <div className="frame-285">
              <div className="frame-286">
                <div className="div-6">
                  <div className="frame-207">
                    <div className="text-wrapper-153">More Like this</div>
                  </div>
                </div>
              </div>
              <div className="frame-287">
                <div className="frame-216">
                  <img
                    className="rectangle-40"
                    alt="Rectangle"
                    src="/img/rectangle-17-16.png"
                  />
                  <div className="frame-288">
                    <div className="frame-289">
                      <div className="frame-290">
                        <div className="frame-207">
                          <div className="button-tall-fixed-8">
                            <div className="text-30">DESIGN</div>
                          </div>
                          <div className="ellipse-22" />
                          <div className="button-tall-fixed-9">
                            <div className="text-31">15 min read</div>
                          </div>
                          <div className="ellipse-22" />
                          <div className="button-tall-fixed-9">
                            <div className="text-32">Based on your topics</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-291">
                      <div className="frame-292">
                        <p className="text-wrapper-154">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </div>
                    <div className="text-33">July 14, 2022</div>
                  </div>
                </div>
                <div className="frame-216">
                  <img
                    className="rectangle-41"
                    alt="Rectangle"
                    src="/img/rectangle-17-15.png"
                  />
                  <div className="frame-288">
                    <div className="frame-207">
                      <div className="button-tall-fixed-8">
                        <div className="text-30">DESIGN</div>
                      </div>
                      <div className="ellipse-22" />
                      <div className="button-tall-fixed-9">
                        <div className="text-31">15 min read</div>
                      </div>
                      <div className="ellipse-22" />
                      <div className="button-tall-fixed-9">
                        <div className="text-32">Based on your topics</div>
                      </div>
                    </div>
                    <div className="frame-291">
                      <div className="frame-292">
                        <p className="text-wrapper-154">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </div>
                    <div className="text-33">July 14, 2022</div>
                  </div>
                </div>
                <div className="frame-216">
                  <img
                    className="rectangle-42"
                    alt="Rectangle"
                    src="/img/rectangle-17-14.png"
                  />
                  <div className="frame-288">
                    <div className="frame-207">
                      <div className="button-tall-fixed-8">
                        <div className="text-30">DESIGN</div>
                      </div>
                      <div className="ellipse-22" />
                      <div className="button-tall-fixed-9">
                        <div className="text-31">15 min read</div>
                      </div>
                      <div className="ellipse-22" />
                      <div className="button-tall-fixed-9">
                        <div className="text-32">Based on your topics</div>
                      </div>
                    </div>
                    <div className="frame-291">
                      <div className="frame-292">
                        <p className="text-wrapper-154">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </div>
                    <div className="text-33">July 14, 2022</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-293">
              <div className="frame-227">
                <div className="frame-227">
                  <p className="lorem-ipsum-is-9">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the
                    industry&#39;s standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged. It was
                    popularised in the 1960s with the release of Letraset sheets
                    containing Lorem Ipsum passages, and more recently with
                    desktop publishing software like Aldus PageMaker including
                    versions of Lorem Ipsum.
                  </p>
                </div>
                <img className="line-51" alt="Line" src="/img/line-8-1.svg" />
                <div className="frame-294">
                  <button className="button-tall-fixed-10">
                    <img
                      className="maps-ugc-2"
                      alt="Maps ugc"
                      src="/img/divider.png"
                    />
                    <div className="text-34">Comment</div>
                  </button>
                  <button className="button-tall-fixed-10">
                    <img
                      className="ios-share-2"
                      alt="Ios share"
                      src="/img/divider.png"
                    />
                    <div className="text-34">Share</div>
                  </button>
                </div>
              </div>
              <div className="frame-202">
                <div className="text-35">Tags</div>
                <div className="frame-295">
                  <div className="frame-296">
                    <div className="text-wrapper-155">Task Management</div>
                  </div>
                  <div className="frame-296">
                    <div className="text-wrapper-155">Finance</div>
                  </div>
                  <div className="frame-296">
                    <div className="text-wrapper-155">Utilities</div>
                  </div>
                  <div className="frame-296">
                    <div className="text-wrapper-155">Task Management</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footers-7">
              <div className="address">
                <p className="element-upper-rupert">
                  32&nbsp;&nbsp;- 44 Upper Rupert Street
                </p>
                <div className="text-wrapper-114">W12 8DY Kensington</div>
                <div className="text-wrapper-115">United Kingdom</div>
              </div>
              <div className="company-links">
                <div className="text-wrapper-156">About us</div>
                <div className="text-wrapper-156">Privacy Policy</div>
                <div className="text-wrapper-156">Legal</div>
              </div>
              <p className="text-wrapper-157">
                Copyright 2023 99x technologies Pvt Ltd.
              </p>
              <img
                className="divider-8"
                alt="Divider"
                src="/img/divider-3.svg"
              />
              <img className="frame-297" alt="Frame" src="/img/divider.png" />
              <img
                className="frame-210"
                alt="Frame"
                src="/img/frame-3773-2.svg"
              />
            </div>
            <img className="line-52" alt="Line" src="/img/line-10-1.svg" />
            <div className="frame-298">
              <p className="text-wrapper-158">
                10 things I knew about money, when I started out
              </p>
              <div className="frame-299">
                <div className="frame-300">
                  <div className="frame-301">
                    <div className="frame-302">
                      <div className="frame-198">
                        <div className="ellipse-23" />
                        <div className="overlap-group-16">
                          <img
                            className="ellipse-24"
                            alt="Ellipse"
                            src="/img/divider.png"
                          />
                        </div>
                      </div>
                      <div className="div-6">
                        <div className="text-wrapper-159">Arthur Shelby</div>
                        <div className="stars-wrapper">
                          <img
                            className="stars"
                            alt="Stars"
                            src="/img/divider.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="subscribe">
                      <div className="div-10">
                        <div className="text-wrapper-160">Follow</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-303">
                    <div className="text-36">Money Expert</div>
                  </div>
                </div>
                <div className="frame-304">
                  <div className="share-8">
                    <img
                      className="recommend"
                      alt="Recommend"
                      src="/img/divider.png"
                    />
                    <div className="div-10">
                      <div className="text-wrapper-161">
                        I found this helpful
                      </div>
                    </div>
                  </div>
                  <div className="share-9">
                    <img
                      className="share-10"
                      alt="Share"
                      src="/img/share.png"
                    />
                    <div className="div-10">
                      <div className="text-wrapper-162">Share</div>
                    </div>
                  </div>
                  <div className="share-11">
                    <img
                      className="img-10"
                      alt="Bookmark add"
                      src="/img/bookmark-add-1.png"
                    />
                    <div className="div-10">
                      <div className="text-wrapper-161">Bookmark</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-305">
              <div className="div-10">
                <div className="frame-306">
                  <div className="frame-307">
                    <div className="text-wrapper-163">1:32</div>
                  </div>
                </div>
                <div className="cell-name">
                  <p className="text-wrapper-164">
                    005- The nonchalant Entrepreneur of the 21st Century |
                    Financial Expertise of the modern man
                  </p>
                  <div className="div-6">
                    <div className="frame-302">
                      <div className="text-wrapper-165">
                        Amigo Madamoselline
                      </div>
                      <div className="label-2">
                        <img
                          className="stars-2"
                          alt="Stars"
                          src="/img/stars-8.png"
                        />
                      </div>
                    </div>
                    <div className="text-wrapper-165">5 years ago</div>
                  </div>
                </div>
              </div>
              <div className="div-10">
                <div className="frame-308">
                  <div className="frame-307">
                    <div className="text-wrapper-163">1:32</div>
                  </div>
                </div>
                <div className="cell-name">
                  <p className="text-wrapper-164">
                    005- The nonchalant Entrepreneur of the 21st Century |
                    Financial Expertise of the modern man
                  </p>
                  <div className="div-6">
                    <div className="frame-302">
                      <div className="text-wrapper-165">
                        Amigo Madamoselline
                      </div>
                      <div className="label-2">
                        <img
                          className="stars-2"
                          alt="Stars"
                          src="/img/stars-7.png"
                        />
                      </div>
                    </div>
                    <div className="text-wrapper-165">5 years ago</div>
                  </div>
                </div>
              </div>
              <div className="div-10">
                <div className="frame-309">
                  <div className="frame-307">
                    <div className="text-wrapper-163">1:32</div>
                  </div>
                </div>
                <div className="cell-name">
                  <p className="text-wrapper-164">
                    005- The nonchalant Entrepreneur of the 21st Century |
                    Financial Expertise of the modern man
                  </p>
                  <div className="div-6">
                    <div className="frame-302">
                      <div className="text-wrapper-165">
                        Amigo Madamoselline
                      </div>
                      <div className="label-2">
                        <img
                          className="stars-2"
                          alt="Stars"
                          src="/img/stars-6.png"
                        />
                      </div>
                    </div>
                    <div className="text-wrapper-165">5 years ago</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-310">
              <div className="frame-311">
                <div className="frame-307">
                  <div className="text-wrapper-163">1:32</div>
                </div>
              </div>
              <div className="cell-name">
                <p className="text-wrapper-164">
                  005- The nonchalant Entrepreneur of the 21st Century |
                  Financial Expertise of the modern man
                </p>
                <div className="div-6">
                  <div className="frame-302">
                    <div className="text-wrapper-165">Amigo Madamoselline</div>
                    <div className="label-2">
                      <img
                        className="stars-2"
                        alt="Stars"
                        src="/img/stars-5.png"
                      />
                    </div>
                  </div>
                  <div className="text-wrapper-165">5 years ago</div>
                </div>
              </div>
            </div>
            <div className="header-16">
              <div className="div-4">
                <div className="div-7">
                  <img
                    className="humble-logo-3"
                    alt="Humble logo"
                    src="/img/divider.png"
                  />
                  <div className="frame-239">
                    <div className="frame-240">
                      <div className="text-wrapper-132">Products</div>
                    </div>
                    <div className="frame-240">
                      <div className="text-wrapper-133">Guides</div>
                    </div>
                    <div className="frame-240">
                      <div className="text-wrapper-133">Topics</div>
                    </div>
                    <div className="frame-240">
                      <div className="text-wrapper-132">Humble+</div>
                    </div>
                  </div>
                </div>
                <div className="div-7">
                  <div className="frame-241">
                    <div className="div-container-wrapper">
                      <div className="search-wrapper">
                        <div className="div-placeholder-wrapper">
                          <div className="div-placeholder-2">
                            <div className="text-wrapper-134">Search</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="search-3"
                      alt="Search"
                      src="/img/search-6.svg"
                    />
                  </div>
                  <div className="CTA-s-3">
                    <div className="frame-242">
                      <img className="edit" alt="Edit" src="/img/edit-10.png" />
                      <div className="text-wrapper-135">Write</div>
                    </div>
                    <div className="frame-312" />
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-313">
              <div className="text-wrapper-166">Experts</div>
              <div className="frame-202">
                <div className="frame-218">
                  <div className="frame-314">
                    <img
                      className="ellipse-25"
                      alt="Ellipse"
                      src="/img/ellipse-55-2.png"
                    />
                  </div>
                  <div className="frame-315">
                    <div className="div-6">
                      <div className="text-wrapper-167">Fabrocio Texeriia</div>
                      <div className="stars-wrapper">
                        <img
                          className="stars-3"
                          alt="Stars"
                          src="/img/stars-4.png"
                        />
                      </div>
                    </div>
                    <div className="text-wrapper-168">
                      Designer at Work&amp; Co,
                    </div>
                  </div>
                </div>
                <div className="frame-218">
                  <div className="frame-314">
                    <img
                      className="ellipse-25"
                      alt="Ellipse"
                      src="/img/ellipse-55-1.png"
                    />
                  </div>
                  <div className="frame-315">
                    <div className="div-6">
                      <div className="text-wrapper-167">Caio Braga</div>
                    </div>
                    <div className="text-wrapper-168">Founder @ Acme Corp.</div>
                  </div>
                </div>
                <div className="frame-218">
                  <div className="frame-314">
                    <img
                      className="ellipse-25"
                      alt="Ellipse"
                      src="/img/ellipse-55.png"
                    />
                  </div>
                  <div className="frame-315">
                    <div className="div-6">
                      <div className="text-wrapper-167">Caio Braga</div>
                    </div>
                    <div className="text-wrapper-168">Founder @ Acme Corp.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="product-categories">
            <div className="overlap-36">
              <div className="hero-image">
                <Header
                  CTASClassName="header-30"
                  buttonBaseClassName="header-32"
                  buttonClassName="header-31"
                  className="header-22"
                  divClassName="header-26"
                  divClassName1="header-18"
                  divClassName2="header-19"
                  divClassName3="header-19"
                  divClassName4="header-18"
                  divClassNameOverride="header-28"
                  divContainerClassName="header-27"
                  divPlaceholderClassName="header-21"
                  frameClassName="header-25"
                  frameClassName1="header-17"
                  frameClassName2="header-17"
                  frameClassName3="header-17"
                  frameClassName4="header-17"
                  frameClassNameOverride="header-23"
                  humbleLogoClassName="header-24"
                  img="/img/humble-logo-1-11.svg"
                  quickLinksClassName="header-23"
                  search1="/img/search-5.png"
                  searchClassName="header-29"
                  searchClassNameOverride="header-20"
                  state="light"
                  textClassName="header-19"
                />
              </div>
              <div className="frame-316">
                <div className="text-wrapper-169">Products</div>
                <p className="text-wrapper-170">
                  Power-ups for your business growth
                </p>
              </div>
            </div>
            <div className="frame-317">
              <div className="frame-318">
                <div className="text-wrapper-171">Trending</div>
              </div>
              <div className="frame-319">
                <div className="text-wrapper-172">Featured</div>
              </div>
              <div className="frame-319">
                <div className="text-wrapper-172">Newest</div>
              </div>
            </div>
            <div className="overlap-37">
              <div className="overlap-38">
                <div className="frame-320">
                  <div className="frame-321">
                    <div className="text-wrapper-173">VIDEOS</div>
                    <div className="frame-322">
                      <div className="your-next-favourite-wrapper">
                        <p className="your-next-favourite">
                          <span className="text-wrapper-174">
                            Your next favourite thing for{" "}
                          </span>
                          <span className="text-wrapper-175">marketing</span>
                        </p>
                      </div>
                    </div>
                    <p className="text-wrapper-176">
                      Curated Resources from creators and makers just like you
                    </p>
                  </div>
                  <div className="frame-323">
                    <div className="frame-324">
                      <img
                        className="rectangle-43"
                        alt="Rectangle"
                        src="/img/rectangle-17-13.png"
                      />
                      <div className="frame-325">
                        <div className="frame-326">
                          <div className="button-tall-fixed-11">
                            <div className="text-37">DESIGN</div>
                          </div>
                          <div className="text-38">July 14, 2022</div>
                        </div>
                        <div className="frame-327">
                          <div className="frame-328">
                            <p className="text-wrapper-177">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor
                            </p>
                          </div>
                        </div>
                      </div>
                      <img
                        className="play-button"
                        alt="Play button"
                        src="/img/play-button-1-1-15.svg"
                      />
                    </div>
                    <div className="frame-324">
                      <img
                        className="rectangle-43"
                        alt="Rectangle"
                        src="/img/rectangle-17-12.png"
                      />
                      <img
                        className="play-button-2"
                        alt="Play button"
                        src="/img/play-button-1-1-14.svg"
                      />
                      <div className="frame-329">
                        <div className="frame-326">
                          <div className="button-tall-fixed-11">
                            <div className="text-37">DESIGN</div>
                          </div>
                          <div className="text-38">July 14, 2022</div>
                        </div>
                        <div className="frame-327">
                          <div className="frame-328">
                            <p className="text-wrapper-178">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-324">
                      <img
                        className="rectangle-44"
                        alt="Rectangle"
                        src="/img/divider.png"
                      />
                      <img
                        className="play-button-3"
                        alt="Play button"
                        src="/img/divider.png"
                      />
                      <div className="frame-325">
                        <div className="frame-326">
                          <div className="button-tall-fixed-11">
                            <div className="text-37">DESIGN</div>
                          </div>
                          <div className="text-38">July 14, 2022</div>
                        </div>
                        <div className="frame-327">
                          <div className="frame-328">
                            <p className="text-wrapper-178">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog-text">
                  <div className="container-product">
                    <div className="image-10">
                      <img
                        className="image-11"
                        alt="Image"
                        src="/img/image-4-18.png"
                      />
                    </div>
                    <div className="container-product-2">
                      <div className="div-11">
                        <p className="text-wrapper-179">
                          Lorem ipsum dolor sit amet,ed do
                        </p>
                        <p className="text-wrapper-180">
                          Lorem ipsum dolor sit amet,ed do
                        </p>
                      </div>
                      <div className="tags">
                        <div className="frame-330">
                          <div className="text-39">Free</div>
                        </div>
                        <div className="frame-331">
                          <div className="text-39">Productivity</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Upvote
                    className="upvote-instance"
                    favorite="/img/divider.png"
                    favoriteClassName="upvote-3"
                    frameClassName="upvote-2"
                    property1="default"
                  />
                </div>
                <div className="frame-332">
                  <div className="div-12">
                    <div className="text-wrapper-181">LATEST STORIES</div>
                    <img
                      className="arrow-forward"
                      alt="Arrow forward"
                      src="/img/divider.png"
                    />
                  </div>
                  <div className="frame-333">
                    <div className="blog-text-2">
                      <div className="frame-334">
                        <div className="frame-327">
                          <div className="frame-328">
                            <p className="text-wrapper-182">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor
                            </p>
                          </div>
                        </div>
                        <div className="label-container-2">
                          <div className="button-tall-fixed-12">
                            <div className="text-40">Design</div>
                          </div>
                          <div className="ellipse-26" />
                          <div className="button-tall-fixed-13">
                            <div className="text-41">15 min read</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-335" />
                    </div>
                    <img
                      className="line-53"
                      alt="Line"
                      src="/img/divider.png"
                    />
                    <div className="blog-text-2">
                      <div className="frame-334">
                        <div className="frame-327">
                          <div className="frame-328">
                            <p className="text-wrapper-182">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor
                            </p>
                          </div>
                        </div>
                        <div className="label-container-3">
                          <div className="button-tall-fixed-12">
                            <div className="text-40">Marketing</div>
                          </div>
                          <div className="ellipse-26" />
                          <div className="button-tall-fixed-13">
                            <div className="text-41">15 min read</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-335" />
                    </div>
                    <img
                      className="line-54"
                      alt="Line"
                      src="/img/divider.png"
                    />
                    <div className="blog-text-2">
                      <div className="frame-334">
                        <div className="frame-327">
                          <div className="frame-328">
                            <p className="text-wrapper-182">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor
                            </p>
                          </div>
                        </div>
                        <div className="label-container-3">
                          <div className="button-tall-fixed-14">
                            <div className="text-42">Startups</div>
                          </div>
                          <div className="ellipse-26" />
                          <div className="button-tall-fixed-13">
                            <div className="text-41">15 min read</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-335" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-text-3">
                <div className="container-product">
                  <div className="image-10">
                    <img
                      className="image-11"
                      alt="Image"
                      src="/img/image-4-15.png"
                    />
                  </div>
                  <div className="container-product-2">
                    <div className="div-11">
                      <p className="text-wrapper-179">
                        Lorem ipsum dolor sit amet,ed do
                      </p>
                      <p className="text-wrapper-180">
                        Lorem ipsum dolor sit amet,ed do
                      </p>
                    </div>
                    <div className="tags">
                      <div className="frame-330">
                        <div className="text-39">Free</div>
                      </div>
                      <div className="frame-331">
                        <div className="text-39">Productivity</div>
                      </div>
                    </div>
                  </div>
                </div>
                <Upvote
                  className="upvote-instance"
                  favorite="/img/divider.png"
                  favoriteClassName="upvote-4"
                  frameClassName="upvote-2"
                  property1="default"
                />
              </div>
              <div className="blog-text-4">
                <div className="container-product">
                  <div className="image-10">
                    <img
                      className="image-11"
                      alt="Image"
                      src="/img/image-4-12.png"
                    />
                  </div>
                  <div className="container-product-2">
                    <div className="div-11">
                      <p className="text-wrapper-179">
                        Lorem ipsum dolor sit amet,ed do
                      </p>
                      <p className="text-wrapper-180">
                        Lorem ipsum dolor sit amet,ed do
                      </p>
                    </div>
                    <div className="tags">
                      <div className="frame-330">
                        <div className="text-39">Free</div>
                      </div>
                      <div className="frame-331">
                        <div className="text-39">Productivity</div>
                      </div>
                    </div>
                  </div>
                </div>
                <Upvote
                  className="upvote-instance"
                  favorite="/img/divider.png"
                  favoriteClassName="upvote-5"
                  frameClassName="upvote-2"
                  property1="default"
                />
              </div>
              <div className="blog-text-5">
                <div className="container-product">
                  <div className="image-10">
                    <img
                      className="image-12"
                      alt="Image"
                      src="/img/image-4-9.png"
                    />
                  </div>
                  <div className="container-product-2">
                    <div className="div-11">
                      <p className="text-wrapper-179">
                        Lorem ipsum dolor sit amet,ed do
                      </p>
                      <p className="text-wrapper-180">
                        Lorem ipsum dolor sit amet,ed do
                      </p>
                    </div>
                    <div className="tags">
                      <div className="frame-330">
                        <div className="text-39">Free</div>
                      </div>
                      <div className="frame-331">
                        <div className="text-39">Productivity</div>
                      </div>
                    </div>
                  </div>
                </div>
                <Upvote
                  className="upvote-instance"
                  favorite="/img/favorite-8.png"
                  favoriteClassName="upvote-6"
                  frameClassName="upvote-2"
                  property1="default"
                />
              </div>
            </div>
            <div className="blog-text-6">
              <div className="container-product">
                <div className="image-10">
                  <img
                    className="image-11"
                    alt="Image"
                    src="/img/image-4-19.png"
                  />
                </div>
                <div className="container-product-2">
                  <div className="div-11">
                    <p className="text-wrapper-179">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                    <p className="text-wrapper-180">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                  </div>
                  <div className="tags">
                    <div className="frame-330">
                      <div className="text-39">Free</div>
                    </div>
                    <div className="frame-331">
                      <div className="text-39">Productivity</div>
                    </div>
                  </div>
                </div>
              </div>
              <Upvote
                className="upvote-instance"
                favorite="/img/divider.png"
                favoriteClassName="upvote-7"
                frameClassName="upvote-2"
                property1="default"
              />
            </div>
            <div className="blog-text-7">
              <div className="container-product">
                <div className="image-10">
                  <img
                    className="image-11"
                    alt="Image"
                    src="/img/image-4-17.png"
                  />
                </div>
                <div className="container-product-2">
                  <div className="div-11">
                    <p className="text-wrapper-179">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                    <p className="text-wrapper-180">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                  </div>
                  <div className="tags">
                    <div className="frame-330">
                      <div className="text-39">Free</div>
                    </div>
                    <div className="frame-331">
                      <div className="text-39">Productivity</div>
                    </div>
                  </div>
                </div>
              </div>
              <Upvote
                className="upvote-instance"
                favorite="/img/divider.png"
                favoriteClassName="upvote-8"
                frameClassName="upvote-2"
                property1="default"
              />
            </div>
            <div className="blog-text-8">
              <div className="container-product">
                <div className="image-10">
                  <img
                    className="image-11"
                    alt="Image"
                    src="/img/image-4-16.png"
                  />
                </div>
                <div className="container-product-2">
                  <div className="div-11">
                    <p className="text-wrapper-179">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                    <p className="text-wrapper-180">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                  </div>
                  <div className="tags">
                    <div className="frame-330">
                      <div className="text-39">Free</div>
                    </div>
                    <div className="frame-331">
                      <div className="text-39">Productivity</div>
                    </div>
                  </div>
                </div>
              </div>
              <Upvote
                className="upvote-instance"
                favorite="/img/divider.png"
                favoriteClassName="upvote-9"
                frameClassName="upvote-2"
                property1="default"
              />
            </div>
            <div className="blog-text-9">
              <div className="container-product">
                <div className="image-10">
                  <img
                    className="image-11"
                    alt="Image"
                    src="/img/image-4-14.png"
                  />
                </div>
                <div className="container-product-2">
                  <div className="div-11">
                    <p className="text-wrapper-179">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                    <p className="text-wrapper-180">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                  </div>
                  <div className="tags">
                    <div className="frame-330">
                      <div className="text-39">Free</div>
                    </div>
                    <div className="frame-331">
                      <div className="text-39">Productivity</div>
                    </div>
                  </div>
                </div>
              </div>
              <Upvote
                className="upvote-instance"
                favorite="/img/divider.png"
                favoriteClassName="upvote-10"
                frameClassName="upvote-2"
                property1="default"
              />
            </div>
            <div className="blog-text-10">
              <div className="container-product">
                <div className="image-10">
                  <img
                    className="image-11"
                    alt="Image"
                    src="/img/image-4-13.png"
                  />
                </div>
                <div className="container-product-2">
                  <div className="div-11">
                    <p className="text-wrapper-179">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                    <p className="text-wrapper-180">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                  </div>
                  <div className="tags">
                    <div className="frame-330">
                      <div className="text-39">Free</div>
                    </div>
                    <div className="frame-331">
                      <div className="text-39">Productivity</div>
                    </div>
                  </div>
                </div>
              </div>
              <Upvote
                className="upvote-instance"
                favorite="/img/divider.png"
                favoriteClassName="upvote-11"
                frameClassName="upvote-2"
                property1="default"
              />
            </div>
            <div className="blog-text-11">
              <div className="container-product">
                <div className="image-10">
                  <img
                    className="image-11"
                    alt="Image"
                    src="/img/image-4-11.png"
                  />
                </div>
                <div className="container-product-2">
                  <div className="div-11">
                    <p className="text-wrapper-179">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                    <p className="text-wrapper-180">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                  </div>
                  <div className="tags">
                    <div className="frame-330">
                      <div className="text-39">Free</div>
                    </div>
                    <div className="frame-331">
                      <div className="text-39">Productivity</div>
                    </div>
                  </div>
                </div>
              </div>
              <Upvote
                className="upvote-instance"
                favorite="/img/favorite-10.png"
                favoriteClassName="upvote-12"
                frameClassName="upvote-2"
                property1="default"
              />
            </div>
            <div className="blog-text-12">
              <div className="container-product">
                <div className="image-10">
                  <img
                    className="image-12"
                    alt="Image"
                    src="/img/image-4-10.png"
                  />
                </div>
                <div className="container-product-2">
                  <div className="div-11">
                    <p className="text-wrapper-179">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                    <p className="text-wrapper-180">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                  </div>
                  <div className="tags">
                    <div className="frame-330">
                      <div className="text-39">Free</div>
                    </div>
                    <div className="frame-331">
                      <div className="text-39">Productivity</div>
                    </div>
                  </div>
                </div>
              </div>
              <Upvote
                className="upvote-instance"
                favorite="/img/divider.png"
                favoriteClassName="upvote-13"
                frameClassName="upvote-2"
                property1="default"
              />
            </div>
            <div className="blog-text-13">
              <div className="container-product">
                <div className="image-10">
                  <img
                    className="image-12"
                    alt="Image"
                    src="/img/image-4-8.png"
                  />
                </div>
                <div className="container-product-2">
                  <div className="div-11">
                    <p className="text-wrapper-179">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                    <p className="text-wrapper-180">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                  </div>
                  <div className="tags">
                    <div className="frame-330">
                      <div className="text-39">Free</div>
                    </div>
                    <div className="frame-331">
                      <div className="text-39">Productivity</div>
                    </div>
                  </div>
                </div>
              </div>
              <Upvote
                className="upvote-instance"
                favorite="/img/divider.png"
                favoriteClassName="upvote-14"
                frameClassName="upvote-2"
                property1="default"
              />
            </div>
            <div className="blog-text-14">
              <div className="container-product">
                <div className="image-10">
                  <img
                    className="image-11"
                    alt="Image"
                    src="/img/image-4-7.png"
                  />
                </div>
                <div className="container-product-2">
                  <div className="div-11">
                    <p className="text-wrapper-179">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                    <p className="text-wrapper-180">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                  </div>
                  <div className="tags">
                    <div className="frame-330">
                      <div className="text-39">Free</div>
                    </div>
                    <div className="frame-331">
                      <div className="text-39">Productivity</div>
                    </div>
                  </div>
                </div>
              </div>
              <Upvote
                className="upvote-instance"
                favorite="/img/divider.png"
                favoriteClassName="upvote-15"
                frameClassName="upvote-2"
                property1="default"
              />
            </div>
            <div className="blog-text-15">
              <div className="container-product">
                <div className="image-10">
                  <img
                    className="image-11"
                    alt="Image"
                    src="/img/image-4-6.png"
                  />
                </div>
                <div className="container-product-2">
                  <div className="div-11">
                    <p className="text-wrapper-179">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                    <p className="text-wrapper-180">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                  </div>
                  <div className="tags">
                    <div className="frame-330">
                      <div className="text-39">Free</div>
                    </div>
                    <div className="frame-331">
                      <div className="text-39">Productivity</div>
                    </div>
                  </div>
                </div>
              </div>
              <Upvote
                className="upvote-instance"
                favorite="/img/divider.png"
                favoriteClassName="upvote-16"
                frameClassName="upvote-2"
                property1="default"
              />
            </div>
            <div className="blog-text-16">
              <div className="container-product">
                <div className="image-10">
                  <img
                    className="image-11"
                    alt="Image"
                    src="/img/image-4-5.png"
                  />
                </div>
                <div className="container-product-2">
                  <div className="div-11">
                    <p className="text-wrapper-179">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                    <p className="text-wrapper-180">
                      Lorem ipsum dolor sit amet,ed do
                    </p>
                  </div>
                  <div className="tags">
                    <div className="frame-330">
                      <div className="text-39">Free</div>
                    </div>
                    <div className="frame-331">
                      <div className="text-39">Productivity</div>
                    </div>
                  </div>
                </div>
              </div>
              <Upvote
                className="upvote-instance"
                favorite="/img/divider.png"
                favoriteClassName="upvote-17"
                frameClassName="upvote-2"
                property1="default"
              />
            </div>
            <div className="frame-336">
              <div className="div-12">
                <div className="text-wrapper-181">LATEST STORIES</div>
                <img
                  className="arrow-back"
                  alt="Arrow back"
                  src="/img/divider.png"
                />
              </div>
              <div className="frame-333">
                <div className="blog-text-2">
                  <div className="frame-334">
                    <div className="frame-327">
                      <div className="frame-328">
                        <p className="text-wrapper-182">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </div>
                    <div className="label-container-2">
                      <div className="button-tall-fixed-12">
                        <div className="text-40">Design</div>
                      </div>
                      <div className="ellipse-26" />
                      <div className="button-tall-fixed-13">
                        <div className="text-41">15 min read</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-335" />
                </div>
                <img className="line-55" alt="Line" src="/img/divider.png" />
                <div className="blog-text-2">
                  <div className="frame-334">
                    <div className="frame-327">
                      <div className="frame-328">
                        <p className="text-wrapper-182">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </div>
                    <div className="label-container-3">
                      <div className="button-tall-fixed-12">
                        <div className="text-40">Marketing</div>
                      </div>
                      <div className="ellipse-26" />
                      <div className="button-tall-fixed-13">
                        <div className="text-41">15 min read</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-335" />
                </div>
                <img className="line-56" alt="Line" src="/img/divider.png" />
                <div className="blog-text-2">
                  <div className="frame-334">
                    <div className="frame-327">
                      <div className="frame-328">
                        <p className="text-wrapper-182">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </div>
                    <div className="label-container-3">
                      <div className="button-tall-fixed-14">
                        <div className="text-42">Startups</div>
                      </div>
                      <div className="ellipse-26" />
                      <div className="button-tall-fixed-13">
                        <div className="text-41">15 min read</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-335" />
                </div>
              </div>
            </div>
            <img className="line-57" alt="Line" src="/img/divider-2.png" />
            <img className="line-58" alt="Line" src="/img/divider-2.png" />
            <img className="line-59" alt="Line" src="/img/line-11.svg" />
            <img className="line-60" alt="Line" src="/img/line-12.svg" />
            <div className="newsletter-2">
              <p className="text-wrapper-183">
                Get the best of Humble directly in your inbox. 💌
              </p>
              <div className="frame-337">
                <div className="frame-338">
                  <div className="text-wrapper-184">Your email</div>
                </div>
                <div className="frame-339">
                  <div className="text-wrapper-185">
                    Subscribe to the newsletter
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-340">
              <div className="div-12">
                <div className="text-wrapper-181">Newly Added Products</div>
                <img
                  className="arrow-back-2"
                  alt="Arrow back"
                  src="/img/arrow-back.png"
                />
              </div>
              <div className="frame-341">
                <div className="frame-wrapper-4">
                  <div className="frame-342">
                    <div className="frame-337">
                      <div className="div-11">
                        <div className="frame-343">
                          <div className="frame-344" />
                          <div className="frame-334">
                            <div className="text-wrapper-186">Notion</div>
                          </div>
                        </div>
                        <div className="frame-345">
                          <div className="frame-346">
                            <p className="text-wrapper-187">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor
                            </p>
                          </div>
                          <div className="button-tall-fixed-15">
                            <div className="text-43">RECOMMENDED</div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="line-61"
                        alt="Line"
                        src="/img/line-7-3.svg"
                      />
                    </div>
                    <div className="frame-347">
                      <div className="frame-348">
                        <div className="text-wrapper-188">Save up to ₹2000</div>
                        <p className="element-off-the-year">
                          90% off the 1st year (then 50% off the 2nd year)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-wrapper-4">
                  <div className="frame-342">
                    <div className="frame-349">
                      <div className="div-11">
                        <div className="frame-343">
                          <div className="frame-350" />
                          <div className="frame-334">
                            <div className="text-wrapper-186">Loom</div>
                          </div>
                        </div>
                        <div className="frame-345">
                          <div className="frame-346">
                            <p className="text-wrapper-187">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor
                            </p>
                          </div>
                        </div>
                      </div>
                      <img
                        className="line-61"
                        alt="Line"
                        src="/img/line-7-2.svg"
                      />
                    </div>
                    <div className="frame-351">
                      <div className="frame-348">
                        <div className="text-wrapper-188">Save up to ₹2000</div>
                        <p className="element-off-the-year">
                          90% off the 1st year (then 50% off the 2nd year)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-wrapper-4">
                  <div className="frame-342">
                    <div className="frame-349">
                      <div className="div-11">
                        <div className="frame-343">
                          <div className="frame-352" />
                          <div className="frame-334">
                            <div className="text-wrapper-186">Monday</div>
                          </div>
                        </div>
                        <div className="frame-345">
                          <div className="frame-346">
                            <p className="text-wrapper-187">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor
                            </p>
                          </div>
                        </div>
                      </div>
                      <img
                        className="line-62"
                        alt="Line"
                        src="/img/divider.png"
                      />
                    </div>
                    <div className="frame-351">
                      <div className="frame-348">
                        <div className="text-wrapper-188">Save up to ₹2000</div>
                        <p className="element-off-the-year">
                          90% off the 1st year (then 50% off the 2nd year)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-wrapper-4">
                  <div className="frame-342">
                    <div className="frame-349">
                      <div className="div-11">
                        <div className="frame-343">
                          <div className="frame-353" />
                          <div className="frame-334">
                            <div className="text-wrapper-186">Monday</div>
                          </div>
                        </div>
                        <div className="frame-345">
                          <div className="frame-346">
                            <p className="text-wrapper-187">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor
                            </p>
                          </div>
                        </div>
                      </div>
                      <img
                        className="line-63"
                        alt="Line"
                        src="/img/divider.png"
                      />
                    </div>
                    <div className="frame-351">
                      <div className="frame-348">
                        <div className="text-wrapper-188">Save up to ₹2000</div>
                        <p className="element-off-the-year">
                          90% off the 1st year (then 50% off the 2nd year)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="blog-text-17">
              <div className="frame-354" />
              <div className="frame-334">
                <div className="frame-327">
                  <div className="frame-328">
                    <p className="text-wrapper-182">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor
                    </p>
                  </div>
                </div>
                <div className="div-12">
                  <div className="button-tall-fixed-12">
                    <div className="text-40">Design</div>
                  </div>
                  <div className="ellipse-26" />
                  <div className="button-tall-fixed-16">
                    <div className="text-40">How to</div>
                  </div>
                  <div className="ellipse-26" />
                  <div className="button-tall-fixed-13">
                    <div className="text-41">3 min read</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footers-8">
              <div className="address-2">
                <p className="element-upper-rupert-2">
                  32&nbsp;&nbsp;- 44 Upper Rupert Street
                </p>
                <div className="text-wrapper-189">W12 8DY Kensington</div>
                <div className="text-wrapper-190">United Kingdom</div>
              </div>
              <div className="company-links-2">
                <div className="text-wrapper-191">About us</div>
                <div className="text-wrapper-191">Privacy Policy</div>
                <div className="text-wrapper-191">Legal</div>
              </div>
              <p className="text-wrapper-192">
                Copyright 2023 99x technologies Pvt Ltd.
              </p>
              <img
                className="divider-9"
                alt="Divider"
                src="/img/divider-2.png"
              />
              <img className="frame-355" alt="Frame" src="/img/divider.png" />
              <img
                className="humble-logo-4"
                alt="Humble logo"
                src="/img/divider.png"
              />
            </div>
          </div>
        </div>
        <div className="frame-356">
          <div className="overlap-39">
            <div className="rectangle-45" />
            <div className="text-wrapper-193">Content creation a breeze</div>
            <div className="create-article">
              <div className="frame-357">
                <div className="text-wrapper-194">Create Article</div>
                <div className="frame-358">
                  <div className="text-wrapper-195">Title of Post</div>
                  <div className="frame-359" />
                </div>
              </div>
              <div className="text-wrapper-196">Content</div>
              <div className="frame-360">
                <div className="frame-361">
                  <p className="lorem-ipsum-dolor">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt Lorem
                    ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt.
                    <br />
                    <br />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt
                  </p>
                  <div className="frame-362">
                    <p className="text-wrapper-197">
                      eiusmod tempor incididunt Lorem ipsum dolor sit amet,
                    </p>
                  </div>
                  <p className="lorem-ipsum-dolor-2">
                    <span className="text-wrapper-119">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt Lorem ipsum dolor sit
                      amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit, sed do eiusmod tempor incididunt Lorem
                      ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt.
                      <br />
                      <br />
                    </span>
                    <span className="text-wrapper-75">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt Lorem ipsum dolor sit
                      amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt{" "}
                    </span>
                  </p>
                  <div className="tooltip">
                    <div className="frame-wrapper-5">
                      <div className="frame-363">
                        <div className="frame-363">
                          <img
                            className="img-12"
                            alt="Format"
                            src="/img/format-h1.png"
                          />
                          <img
                            className="img-12"
                            alt="Format"
                            src="/img/format-h2.png"
                          />
                          <img
                            className="img-12"
                            alt="Format"
                            src="/img/format-h3.png"
                          />
                        </div>
                        <img
                          className="line-64"
                          alt="Line"
                          src="/img/line-20.svg"
                        />
                        <div className="frame-363">
                          <img
                            className="img-12"
                            alt="Format bold"
                            src="/img/format-bold.png"
                          />
                          <img
                            className="img-12"
                            alt="Format italic"
                            src="/img/format-italic.png"
                          />
                          <img
                            className="img-12"
                            alt="Format underlined"
                            src="/img/format-underlined.png"
                          />
                          <img
                            className="img-12"
                            alt="Format list bulleted"
                            src="/img/format-list-bulleted.png"
                          />
                        </div>
                      </div>
                    </div>
                    <img className="arrow" alt="Arrow" src="/img/arrow.svg" />
                  </div>
                </div>
                <Toolbar
                  close="/img/close.png"
                  closeClassName="toolbar-3"
                  code="/img/code.png"
                  codeClassName="toolbar-3"
                  codeWrapperClassName="toolbar-5"
                  dataObject="/img/data-object.png"
                  dataObjectClassName="toolbar-3"
                  dataObjectWrapperClassName="toolbar-5"
                  frameClassName="toolbar-2"
                  frameClassNameOverride="toolbar-4"
                  image="/img/image.png"
                  imageClassName="toolbar-3"
                  imageWrapperClassName="toolbar-5"
                  playCircle="/img/play-circle-1.png"
                  playCircleClassName="toolbar-3"
                  playCircleWrapperClassName="toolbar-5"
                  property1="frame-40765"
                  propertyFrameClassName="toolbar-instance"
                />
              </div>
              <div className="header-33">
                <div className="div-4">
                  <img
                    className="quick-links"
                    alt="Quick links"
                    src="/img/quick-links-7.svg"
                  />
                  <button className="CTA-s-wrapper">
                    <button className="CTA-s-4">
                      <div className="frame-364">
                        <div className="text-wrapper-198">Publish</div>
                      </div>
                      <div className="frame-365" />
                    </button>
                  </button>
                </div>
              </div>
              <div className="frame-366">
                <div className="check-wrapper">
                  <img className="check" alt="Check" src="/img/check-26.png" />
                </div>
                <img className="line-47" alt="Line" src="/img/line-21-8.svg" />
                <div className="frame-367">
                  <img className="check" alt="Check" src="/img/check-25.png" />
                </div>
                <img className="line-47" alt="Line" src="/img/line-22-8.svg" />
                <div className="frame-367">
                  <img className="check" alt="Check" src="/img/check-24.png" />
                </div>
              </div>
            </div>
            <div className="create-article-2">
              <div className="frame-368">
                <p className="text-wrapper-194">
                  Tag your content for optimal reach!
                </p>
                <div className="frame-369">
                  <div className="frame-370">
                    <div className="text-wrapper-195">Buckets</div>
                    <div className="nav-labels-wrapper">
                      <NavLabels
                        className="nav-labels-instance"
                        containerClassName="nav-labels-2"
                        divClassName="nav-labels-3"
                        stateProp="selected"
                        text="Money"
                        theme="dark"
                        withIcon={false}
                      />
                    </div>
                  </div>
                  <div className="frame-370">
                    <div className="text-wrapper-195">Action Items</div>
                    <div className="nav-labels-wrapper">
                      <NavLabels
                        className="nav-labels-4"
                        containerClassName="nav-labels-2"
                        divClassName="nav-labels-5"
                        stateProp="disabled"
                        text="Seed Capital"
                        theme="light"
                        withIcon={false}
                      />
                    </div>
                  </div>
                  <div className="frame-370">
                    <div className="text-wrapper-195">Channels</div>
                    <div className="nav-labels-wrapper">
                      <NavLabels
                        className="nav-labels-4"
                        containerClassName="nav-labels-2"
                        divClassName="nav-labels-5"
                        stateProp="disabled"
                        text="Seed Capital"
                        theme="light"
                        withIcon={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="header-33">
                <div className="div-4">
                  <img
                    className="quick-links"
                    alt="Quick links"
                    src="/img/quick-links-6.svg"
                  />
                  <button className="CTA-s-wrapper">
                    <button className="CTA-s-4">
                      <div className="frame-364">
                        <div className="text-wrapper-198">Publish</div>
                      </div>
                      <div className="frame-371" />
                    </button>
                  </button>
                </div>
              </div>
              <div className="frame-366">
                <div className="check-wrapper">
                  <img className="check" alt="Check" src="/img/check-23.png" />
                </div>
                <img className="line-47" alt="Line" src="/img/line-21-7.svg" />
                <div className="frame-367">
                  <img className="check" alt="Check" src="/img/check-22.png" />
                </div>
                <img className="line-47" alt="Line" src="/img/line-22-7.svg" />
                <div className="frame-367">
                  <img className="check" alt="Check" src="/img/check-21.png" />
                </div>
              </div>
            </div>
            <div className="create-podcast">
              <div className="header-34">
                <div className="div-4">
                  <img
                    className="quick-links-2"
                    alt="Quick links"
                    src="/img/divider.png"
                  />
                  <div className="frame-372">
                    <div className="CTA-s-5">
                      <div className="frame-373" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-374">
                <div className="frame-375">
                  <div className="frame-376">
                    <div className="text-wrapper-194">Set up your Podcast</div>
                    <p className="text-wrapper-199">
                      Before we can distribute your podcast to additional
                      listening platforms, you just need to fill in a few things
                      Pssst: You can always change these later
                    </p>
                  </div>
                  <button className="button-base-18">
                    <div className="text-44">Continue</div>
                    <img
                      className="img-12"
                      alt="Arrow right alt"
                      src="/img/arrow-right-alt-1.png"
                    />
                  </button>
                </div>
                <div className="frame-377">
                  <div className="text-wrapper-200">Podcast Name</div>
                  <div className="frame-378">
                    <div className="button-tall-fixed-17">
                      <div className="text-45">0/100</div>
                    </div>
                  </div>
                </div>
                <div className="frame-377">
                  <div className="text-wrapper-200">Podcast Descrption</div>
                  <div className="frame-379">
                    <p className="text-wrapper-201">
                      Tell People what your podcast is about. You can always
                      change this later.
                    </p>
                    <div className="button-tall-fixed-17">
                      <div className="text-45">0/600</div>
                    </div>
                  </div>
                </div>
                <div className="frame-377">
                  <div className="text-wrapper-200">Podcast Language</div>
                  <div className="frame-380">
                    <div className="text-wrapper-202">Choose One Option</div>
                    <img
                      className="img-12"
                      alt="Expand more"
                      src="/img/expand-more-3.png"
                    />
                  </div>
                </div>
              </div>
              <div className="frame-366">
                <div className="frame-381">
                  <img
                    className="check-2"
                    alt="Check"
                    src="/img/check-20.png"
                  />
                </div>
                <img className="line-47" alt="Line" src="/img/line-21-6.svg" />
                <div className="frame-382">
                  <img className="check" alt="Check" src="/img/check-19.png" />
                </div>
                <img className="line-47" alt="Line" src="/img/line-22-6.svg" />
                <div className="frame-382">
                  <img className="check" alt="Check" src="/img/check-18.png" />
                </div>
              </div>
            </div>
            <div className="create-podcast-2">
              <div className="header-34">
                <div className="div-4">
                  <img
                    className="quick-links"
                    alt="Quick links"
                    src="/img/quick-links-4.svg"
                  />
                  <div className="frame-383">
                    <div className="CTA-s-5">
                      <div className="frame-384" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-385">
                <div className="text-wrapper-203">Create your Episode</div>
                <div className="button-base-19">
                  <div className="text-46">Save Episode</div>
                </div>
              </div>
              <div className="frame-366">
                <div className="frame-381">
                  <img className="check" alt="Check" src="/img/check-17.png" />
                </div>
                <img className="line-47" alt="Line" src="/img/line-21-5.svg" />
                <div className="frame-382">
                  <img className="check" alt="Check" src="/img/check-16.png" />
                </div>
                <img className="line-47" alt="Line" src="/img/line-22-5.svg" />
                <div className="frame-382">
                  <img className="check" alt="Check" src="/img/check-15.png" />
                </div>
              </div>
              <div className="frame-386">
                <div className="frame-387">
                  <div className="publish-wrapper">
                    <img
                      className="publish"
                      alt="Publish"
                      src="/img/publish-2.png"
                    />
                  </div>
                  <div className="frame-388">
                    <p className="text-wrapper-204">
                      Drag and drop you files here or
                    </p>
                    <div className="text-wrapper-205">Browse</div>
                  </div>
                </div>
                <img className="line-65" alt="Line" src="/img/line-23-2.svg" />
              </div>
              <div className="frame-389">
                <div className="frame-390">
                  <img
                    className="play-button-4"
                    alt="Play button"
                    src="/img/play-button-1-1-12.svg"
                  />
                  <div className="text-wrapper-206">Preview Episode</div>
                </div>
                <div className="text-wrapper-206">Total Length</div>
              </div>
            </div>
            <div className="create-podcast-3">
              <div className="header-35">
                <div className="div-4">
                  <img
                    className="quick-links"
                    alt="Quick links"
                    src="/img/quick-links-3.svg"
                  />
                  <div className="frame-391">
                    <div className="CTA-s-6">
                      <div className="frame-392" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-393">
                <div className="text-wrapper-203">Create your Episode</div>
                <button className="button-base-20">
                  <div className="text-46">Next</div>
                </button>
              </div>
              <div className="frame-366">
                <div className="frame-394">
                  <img
                    className="check-3"
                    alt="Check"
                    src="/img/check-14.png"
                  />
                </div>
                <img className="line-47" alt="Line" src="/img/line-21-4.svg" />
                <div className="frame-395">
                  <img className="check-4" alt="Check" src="/img/divider.png" />
                </div>
                <img className="line-66" alt="Line" src="/img/divider.png" />
                <div className="frame-395">
                  <img className="check-5" alt="Check" src="/img/divider.png" />
                </div>
              </div>
              <div className="frame-396">
                <div className="frame-397">
                  <div className="upload">
                    <div className="frame-390">
                      <img
                        className="drag-indicator"
                        alt="Drag indicator"
                        src="/img/drag-indicator-5.png"
                      />
                      <div className="frame-390">
                        <div className="frame-398">
                          <div className="ellipse-27" />
                          <div className="ellipse-28" />
                          <div className="ellipse-29" />
                        </div>
                        <div className="frame-399">
                          <div className="text-wrapper-207">File Name</div>
                          <div className="text-wrapper-208">Processing</div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="more-horiz"
                      alt="More horiz"
                      src="/img/divider.png"
                    />
                  </div>
                  <div className="upload">
                    <div className="frame-390">
                      <img
                        className="drag-indicator"
                        alt="Drag indicator"
                        src="/img/drag-indicator-4.png"
                      />
                      <div className="frame-390">
                        <div className="frame-398">
                          <div className="ellipse-27" />
                          <div className="ellipse-28" />
                          <div className="ellipse-29" />
                        </div>
                        <div className="text-wrapper-209">File Name</div>
                      </div>
                    </div>
                    <img
                      className="more-horiz-2"
                      alt="More horiz"
                      src="/img/divider.png"
                    />
                  </div>
                  <div className="upload">
                    <div className="frame-390">
                      <img
                        className="drag-indicator"
                        alt="Drag indicator"
                        src="/img/drag-indicator-3.png"
                      />
                      <div className="frame-390">
                        <div className="frame-398">
                          <div className="ellipse-27" />
                          <div className="ellipse-28" />
                          <div className="ellipse-29" />
                        </div>
                        <div className="text-wrapper-209">File Name</div>
                      </div>
                    </div>
                    <img
                      className="more-horiz-3"
                      alt="More horiz"
                      src="/img/divider.png"
                    />
                  </div>
                  <div className="upload-2">
                    <div className="frame-390">
                      <img
                        className="drag-indicator"
                        alt="Drag indicator"
                        src="/img/drag-indicator-2.png"
                      />
                      <div className="frame-390">
                        <div className="frame-398">
                          <div className="ellipse-27" />
                          <div className="ellipse-28" />
                          <div className="ellipse-29" />
                        </div>
                        <div className="text-wrapper-209">File Name</div>
                      </div>
                    </div>
                    <img
                      className="more-horiz-4"
                      alt="More horiz"
                      src="/img/divider.png"
                    />
                  </div>
                </div>
                <div className="frame-400">
                  <div className="line-wrapper">
                    <img
                      className="line-67"
                      alt="Line"
                      src="/img/line-23-1.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="frame-401">
                <div className="frame-390">
                  <img
                    className="play-button-5"
                    alt="Play button"
                    src="/img/play-button-1-1-11.svg"
                  />
                  <div className="text-wrapper-206">Preview Episode</div>
                </div>
                <div className="text-wrapper-206">Total Length</div>
              </div>
            </div>
            <div className="create-video-video">
              <div className="header-36">
                <div className="div-4">
                  <img
                    className="quick-links"
                    alt="Quick links"
                    src="/img/quick-links-2.svg"
                  />
                  <div className="frame-402">
                    <div className="CTA-s-7">
                      <div className="frame-403" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-404">
                <div className="div-4">
                  <div className="text-wrapper-210">Details</div>
                  <div className="frame-405">
                    <div className="button-base-21">
                      <div className="text-47">Save Draft</div>
                    </div>
                    <div className="button-base-22">
                      <div className="text-48">Next</div>
                    </div>
                  </div>
                </div>
                <div className="frame-406">
                  <div className="frame-407">
                    <div className="frame-408">
                      <div className="frame-409">
                        <div className="text-wrapper-195">Title</div>
                        <div className="frame-410">
                          <div className="button-tall-fixed-18">
                            <div className="text-45">0/100</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-409">
                        <div className="text-wrapper-195">Description</div>
                        <div className="frame-411">
                          <p className="text-wrapper-201">
                            Tell viewers about your video. You can always change
                            this later.
                          </p>
                          <div className="button-tall-fixed-18">
                            <div className="text-45">0/600</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-409">
                        <div className="frame-412">
                          <div className="text-wrapper-211">Thumbnail</div>
                          <p className="upload-a-picture">
                            Upload a picture that shows what&#39;s in your video
                          </p>
                        </div>
                        <div className="frame-413">
                          <div className="frame-414">
                            <img
                              className="img-13"
                              alt="Add photo alternate"
                              src="/img/add-photo-alternate-1.png"
                            />
                            <div className="text-wrapper-212">
                              Upload thumbnail
                            </div>
                          </div>
                          <div className="frame-415" />
                          <div className="frame-416" />
                        </div>
                      </div>
                      <div className="frame-417">
                        <div className="frame-418">
                          <div className="text-wrapper-195">Publish Date:</div>
                          <div className="frame-410">
                            <img
                              className="expand-more"
                              alt="Expand more"
                              src="/img/divider.png"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-419">
                    <div className="frame-420">
                      <div className="frame-399">
                        <img
                          className="frame-421"
                          alt="Frame"
                          src="/img/divider.png"
                        />
                        <div className="controls-2">
                          <div className="frame-422">
                            <div className="controls-3">
                              <div className="frame-423" />
                              <div className="frame-423" />
                            </div>
                            <div className="text-wrapper-213">0:00/0:00</div>
                          </div>
                          <div className="frame-424" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-425">
                      <div className="frame-426">
                        <div className="text-wrapper-214">File Name:</div>
                        <p className="text-wrapper-215">
                          The Diary of a wimpy kid
                        </p>
                      </div>
                      <div className="frame-427">
                        <div className="text-wrapper-214">Link:</div>
                        <div className="frame-47">
                          <div className="text-wrapper-216">
                            https://youtu.be/JDQV2iXim-M
                          </div>
                          <img
                            className="content-copy"
                            alt="Content copy"
                            src="/img/divider.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-428">
                <div className="frame-429">
                  <img
                    className="check-6"
                    alt="Check"
                    src="/img/check-11.png"
                  />
                </div>
                <img className="line-47" alt="Line" src="/img/line-21-3.svg" />
                <div className="frame-429">
                  <img className="check-7" alt="Check" src="/img/divider.png" />
                </div>
                <img className="line-68" alt="Line" src="/img/divider.png" />
                <div className="frame-430">
                  <img className="check-8" alt="Check" src="/img/divider.png" />
                </div>
              </div>
            </div>
            <div className="upload-episode">
              <div className="header-36">
                <div className="div-4">
                  <img
                    className="quick-links"
                    alt="Quick links"
                    src="/img/quick-links-1.svg"
                  />
                  <div className="frame-402">
                    <div className="CTA-s-7">
                      <div className="frame-431" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-432">
                <div className="text-wrapper-217">Upload your Video</div>
                <div className="button-base-23">
                  <div className="text-46">Save Episode</div>
                </div>
              </div>
              <div className="frame-433">
                <div className="frame-434">
                  <div className="frame-435">
                    <div className="overlap-group-17">
                      <img
                        className="publish-2"
                        alt="Publish"
                        src="/img/publish-1.png"
                      />
                    </div>
                  </div>
                  <div className="frame-388">
                    <p className="text-wrapper-204">
                      Drag and drop you files here or
                    </p>
                    <div className="text-wrapper-205">Browse</div>
                  </div>
                </div>
                <div className="frame-436">
                  <img className="line-69" alt="Line" src="/img/line-23.svg" />
                </div>
              </div>
              <div className="frame-437">
                <div className="frame-438">
                  <img
                    className="img-13"
                    alt="Play button"
                    src="/img/play-button-1-1-10.svg"
                  />
                  <div className="text-wrapper-206">Preview Episode</div>
                </div>
                <div className="text-wrapper-206">Total Length</div>
              </div>
              <div className="frame-428">
                <div className="frame-429">
                  <img className="check-6" alt="Check" src="/img/check-8.png" />
                </div>
                <img className="line-47" alt="Line" src="/img/line-21-2.svg" />
                <div className="frame-430">
                  <img className="check-6" alt="Check" src="/img/check-7.png" />
                </div>
                <img className="line-47" alt="Line" src="/img/line-22-2.svg" />
                <div className="frame-430">
                  <img className="check-6" alt="Check" src="/img/check-6.png" />
                </div>
              </div>
            </div>
            <div className="create-podcast-4">
              <div className="header-36">
                <div className="div-4">
                  <img
                    className="quick-links-3"
                    alt="Quick links"
                    src="/img/divider.png"
                  />
                  <div className="frame-439">
                    <div className="CTA-s-7">
                      <div className="frame-440" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-441">
                <div className="frame-442">
                  <div className="frame-443">
                    <div className="overlap-group-18">
                      <div className="ellipse-30" />
                      <div className="ellipse-31" />
                      <div className="ellipse-32" />
                      <div className="frame-444">
                        <img
                          className="publish-3"
                          alt="Publish"
                          src="/img/divider.png"
                        />
                      </div>
                      <div className="text-wrapper-218">
                        Upload New Episode Art
                      </div>
                    </div>
                  </div>
                  <div className="frame-445">
                    <div className="frame-425">
                      <div className="text-wrapper-219">001</div>
                      <div className="frame-422">
                        <div className="button-tall-fixed-19">
                          <div className="text-49">Drafts</div>
                        </div>
                        <div className="frame-446">
                          <div className="text-wrapper-220">Mar 22</div>
                          <div className="text-wrapper-220">·</div>
                          <div className="text-wrapper-220">01:39</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-447">
                      <p className="text-wrapper-221">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum Lorem ipsum
                        dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum
                      </p>
                    </div>
                  </div>
                </div>
                <div className="edit-wrapper">
                  <img className="edit-2" alt="Edit" src="/img/edit-9.png" />
                </div>
              </div>
              <div className="overlap-40">
                <div className="upload-3">
                  <div className="div-4">
                    <div className="frame-438">
                      <img
                        className="drag-indicator-2"
                        alt="Drag indicator"
                        src="/img/divider.png"
                      />
                      <div className="frame-438">
                        <img
                          className="play-button-6"
                          alt="Play button"
                          src="/img/divider.png"
                        />
                        <div className="frame-399">
                          <div className="text-wrapper-207">File Name</div>
                          <p className="text-wrapper-208">1.59 MB | 32 min</p>
                        </div>
                      </div>
                    </div>
                    <div className="frame-448">
                      <div className="button-base-24">
                        <div className="text-50">Edit Audio</div>
                      </div>
                      <div className="button-base-25">
                        <div className="text-48">Publish</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-436">
                    <div className="overlap-group-19">
                      <img
                        className="line-70"
                        alt="Line"
                        src="/img/line-25-1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="upload-4">
                  <div className="div-4">
                    <div className="frame-438">
                      <img
                        className="drag-indicator-3"
                        alt="Drag indicator"
                        src="/img/divider.png"
                      />
                      <div className="frame-438">
                        <img
                          className="play-button-7"
                          alt="Play button"
                          src="/img/divider.png"
                        />
                        <div className="frame-399">
                          <div className="text-wrapper-207">File Name</div>
                          <p className="text-wrapper-208">1.59 MB | 32 min</p>
                        </div>
                      </div>
                    </div>
                    <div className="frame-448">
                      <div className="button-base-24">
                        <div className="text-50">Edit Audio</div>
                      </div>
                      <div className="button-base-25">
                        <div className="text-48">Publish</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-436">
                    <div className="overlap-group-20">
                      <img
                        className="line-70"
                        alt="Line"
                        src="/img/line-25.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-wrapper-222">Episode Segments</div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-449">
          <div className="text-wrapper-223">Profile Managment</div>
          <div className="profile-page-creator">
            <div className="frame-450">
              <div className="frame-451" />
              <div className="frame-452">
                <div className="frame-453">
                  <div className="frame-454">
                    <div className="text-wrapper-224">Chris Messia</div>
                  </div>
                </div>
                <div className="label-3">
                  <img className="img-14" alt="Stars" src="/img/stars-3.png" />
                  <div className="text-wrapper-225">Expert</div>
                </div>
              </div>
            </div>
            <div className="overlap-41">
              <div className="frame-455">
                <div className="div-13">
                  <div className="frame-456">
                    <img className="img-15" alt="List" src="/img/list-3.png" />
                    <div className="text-wrapper-226">About</div>
                  </div>
                  <div className="frame-457">
                    <img
                      className="img-15"
                      alt="Favorite"
                      src="/img/favorite-3.png"
                    />
                    <div className="text-wrapper-226">Upvotes</div>
                  </div>
                  <div className="frame-456">
                    <img
                      className="img-15"
                      alt="Bookmark"
                      src="/img/bookmark-1.png"
                    />
                    <div className="text-wrapper-226">Bookmarks</div>
                  </div>
                  <div className="frame-456">
                    <img
                      className="img-15"
                      alt="View quilt"
                      src="/img/view-quilt-3.png"
                    />
                    <div className="text-wrapper-226">Stacks</div>
                  </div>
                  <div className="frame-458">
                    <img
                      className="img-15"
                      alt="File copy"
                      src="/img/file-copy-3.png"
                    />
                    <div className="text-wrapper-227">Content</div>
                  </div>
                  <div className="frame-458">
                    <img
                      className="img-15"
                      alt="Brightness empty"
                      src="/img/brightness-empty.png"
                    />
                    <div className="text-wrapper-226">Your Subscription</div>
                  </div>
                </div>
              </div>
              <img className="line-71" alt="Line" src="/img/line-19-3.svg" />
            </div>
            <div className="overlap-42">
              <div className="overlap-43">
                <img className="line-72" alt="Line" src="/img/line-18-3.svg" />
                <div className="frame-459">
                  <div className="tabs">
                    <NavLabels
                      className="nav-labels-6"
                      containerClassName="nav-labels-7"
                      divClassName="nav-labels-8"
                      stateProp="default"
                      text="Content"
                      theme="light"
                      withIcon={false}
                    />
                    <NavLabels
                      className="nav-labels-9"
                      containerClassName="nav-labels-7"
                      divClassName="nav-labels-10"
                      stateProp="selected"
                      text="Podcast"
                      theme="light"
                      withIcon={false}
                    />
                    <NavLabels
                      className="nav-labels-6"
                      containerClassName="nav-labels-7"
                      divClassName="nav-labels-8"
                      stateProp="default"
                      text="Videos"
                      theme="light"
                      withIcon={false}
                    />
                  </div>
                  <div className="frame-460">
                    <div className="text-wrapper-228">Settings</div>
                    <img
                      className="img-14"
                      alt="Arrow drop down"
                      src="/img/arrow-drop-down-5.png"
                    />
                  </div>
                </div>
                <div className="frame-461">
                  <div className="frame-462">
                    <div className="text-wrapper-229">Episodes</div>
                  </div>
                  <div className="frame-463">
                    <div className="frame-464">
                      <div className="frame-465">
                        <div className="frame-466">
                          <div className="text-wrapper-230">NAME</div>
                        </div>
                        <div className="frame-467">
                          <div className="text-wrapper-231">LENGTH</div>
                        </div>
                        <div className="frame-467">
                          <div className="text-wrapper-231">PLAYS</div>
                        </div>
                        <div className="frame-467">
                          <div className="text-wrapper-231">DATE</div>
                        </div>
                        <div className="frame-467">
                          <div className="text-wrapper-231">STATUS</div>
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="cell-name-2">
                          <div className="text-wrapper-232">
                            005- The nonchalant Entrepreneur
                          </div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">32:32 min</div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">0</div>
                        </div>
                        <div className="div-14">
                          <div className="text-wrapper-234">Published</div>
                          <div className="text-wrapper-235">12 /09 /23</div>
                        </div>
                        <div className="div-14" />
                        <div className="cell-actions">
                          <img
                            className="more-horiz-5"
                            alt="More horiz"
                            src="/img/more-horiz-7.png"
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="cell-name-2">
                          <div className="text-wrapper-232">
                            005- The nonchalant Entrepreneur
                          </div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">32:32 min</div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">0</div>
                        </div>
                        <div className="div-14">
                          <div className="text-wrapper-234">Published</div>
                          <div className="text-wrapper-235">12 /09 /23</div>
                        </div>
                        <div className="div-14" />
                        <div className="cell-actions">
                          <img
                            className="more-horiz-5"
                            alt="More horiz"
                            src="/img/more-horiz-6.png"
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="cell-name-2">
                          <div className="text-wrapper-232">
                            005- The nonchalant Entrepreneur
                          </div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">32:32 min</div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">0</div>
                        </div>
                        <div className="div-14">
                          <div className="text-wrapper-234">Uploaded</div>
                          <div className="text-wrapper-235">12 /09 /23</div>
                        </div>
                        <div className="div-14" />
                        <div className="cell-actions">
                          <img
                            className="more-horiz-5"
                            alt="More horiz"
                            src="/img/more-horiz-5.png"
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="cell-name-2">
                          <div className="text-wrapper-232">
                            005- The nonchalant Entrepreneur
                          </div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">32:32 min</div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">0</div>
                        </div>
                        <div className="div-14">
                          <div className="text-wrapper-234">Published</div>
                          <div className="text-wrapper-235">12 /09 /23</div>
                        </div>
                        <div className="div-14" />
                        <div className="cell-actions">
                          <img
                            className="more-horiz-5"
                            alt="More horiz"
                            src="/img/more-horiz-4.png"
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="cell-name-2">
                          <div className="text-wrapper-232">
                            005- The nonchalant Entrepreneur
                          </div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">32:32 min</div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">0</div>
                        </div>
                        <div className="div-14">
                          <div className="text-wrapper-234">Published</div>
                          <div className="text-wrapper-235">12 /09 /23</div>
                        </div>
                        <div className="div-14" />
                        <div className="cell-actions">
                          <img
                            className="more-horiz-5"
                            alt="More horiz"
                            src="/img/more-horiz-3.png"
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="cell-name-2">
                          <div className="text-wrapper-232">
                            005- The nonchalant Entrepreneur
                          </div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">32:32 min</div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">0</div>
                        </div>
                        <div className="div-14">
                          <div className="text-wrapper-234">Published</div>
                          <div className="text-wrapper-235">12 /09 /23</div>
                        </div>
                        <div className="div-14" />
                        <div className="cell-actions">
                          <img
                            className="more-horiz-5"
                            alt="More horiz"
                            src="/img/more-horiz-2.png"
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="cell-name-2">
                          <div className="text-wrapper-232">
                            005- The nonchalant Entrepreneur
                          </div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">32:32 min</div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">0</div>
                        </div>
                        <div className="div-14">
                          <div className="text-wrapper-234">Published</div>
                          <div className="text-wrapper-235">12 /09 /23</div>
                        </div>
                        <div className="div-14" />
                        <div className="cell-actions">
                          <img
                            className="more-horiz-5"
                            alt="More horiz"
                            src="/img/more-horiz-1.png"
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="cell-name-2">
                          <div className="text-wrapper-232">
                            005- The nonchalant Entrepreneur
                          </div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">32:32 min</div>
                        </div>
                        <div className="div-wrapper-2">
                          <div className="text-wrapper-233">0</div>
                        </div>
                        <div className="div-14">
                          <div className="text-wrapper-234">Published</div>
                          <div className="text-wrapper-235">12 /09 /23</div>
                        </div>
                        <div className="div-14" />
                        <div className="cell-actions">
                          <img
                            className="more-horiz-5"
                            alt="More horiz"
                            src="/img/more-horiz.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nav">
                      <div className="label-4">
                        <div className="text-wrapper-236">12 Episodes</div>
                      </div>
                      <div className="container-nav">
                        <div className="img-wrapper-2">
                          <img
                            className="img-14"
                            alt="Arrow back ios new"
                            src="/img/arrow-back-ios-new.png"
                          />
                        </div>
                        <div className="img-wrapper-2">
                          <img
                            className="img-14"
                            alt="Arrow forward ios"
                            src="/img/arrow-forward-ios.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-468">
                <div className="overlap-group-21">
                  <div className="ellipse-33" />
                  <div className="ellipse-34" />
                  <div className="ellipse-35" />
                  <div className="frame-469">
                    <img className="edit-3" alt="Edit" src="/img/edit-7.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="header-37">
              <div className="div-4">
                <div className="div-15">
                  <img
                    className="humble-logo-5"
                    alt="Humble logo"
                    src="/img/humble-logo-1-9.svg"
                  />
                  <div className="frame-470">
                    <div className="frame-471">
                      <div className="text-wrapper-237">Products</div>
                    </div>
                    <div className="frame-471">
                      <div className="text-wrapper-238">Guides</div>
                    </div>
                    <div className="frame-471">
                      <div className="text-wrapper-238">Topics</div>
                    </div>
                    <div className="frame-471">
                      <div className="text-wrapper-237">Humble+</div>
                    </div>
                  </div>
                </div>
                <div className="div-15">
                  <div className="frame-472">
                    <div className="div-container-wrapper">
                      <div className="div-container-2">
                        <div className="search-4">
                          <div className="div-placeholder-3">
                            <div className="text-wrapper-239">Search</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="search-5"
                      alt="Search"
                      src="/img/search-4.svg"
                    />
                  </div>
                  <div className="CTA-s-8">
                    <div className="frame-473">
                      <img
                        className="edit-3"
                        alt="Edit"
                        src="/img/edit-8.png"
                      />
                      <div className="text-wrapper-240">Write</div>
                    </div>
                    <div className="frame-474" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-page-about">
            <div className="frame-475">
              <div className="frame-476">
                <img
                  className="ellipse-36"
                  alt="Ellipse"
                  src="/img/ellipse-1-11.png"
                />
              </div>
              <div className="frame-452">
                <div className="frame-453">
                  <div className="frame-454">
                    <div className="text-wrapper-224">Chris Messia</div>
                  </div>
                </div>
                <div className="label-3">
                  <img className="stars-4" alt="Stars" src="/img/divider.png" />
                  <div className="text-wrapper-225">Expert</div>
                </div>
              </div>
            </div>
            <div className="overlap-44">
              <div className="frame-477">
                <div className="div-13">
                  <div className="frame-456">
                    <img className="list" alt="List" src="/img/divider.png" />
                    <div className="text-wrapper-241">About</div>
                  </div>
                  <div className="frame-457">
                    <img
                      className="favorite-2"
                      alt="Favorite"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-226">Upvotes</div>
                  </div>
                  <div className="frame-456">
                    <img
                      className="bookmark"
                      alt="Bookmark"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-226">Bookmarks</div>
                  </div>
                  <div className="frame-456">
                    <img
                      className="view-quilt"
                      alt="View quilt"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-226">Stacks</div>
                  </div>
                  <div className="frame-458">
                    <img
                      className="file-copy"
                      alt="File copy"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-226">Content</div>
                  </div>
                </div>
              </div>
              <img className="line-73" alt="Line" src="/img/line-19-2.svg" />
            </div>
            <img className="line-74" alt="Line" src="/img/line-18-2.svg" />
            <div className="header-37">
              <div className="div-4">
                <div className="div-15">
                  <img
                    className="humble-logo-6"
                    alt="Humble logo"
                    src="/img/humble-logo-1-8.svg"
                  />
                  <div className="frame-470">
                    <div className="frame-471">
                      <div className="text-wrapper-237">Products</div>
                    </div>
                    <div className="frame-471">
                      <div className="text-wrapper-238">Guides</div>
                    </div>
                    <div className="frame-471">
                      <div className="text-wrapper-238">Topics</div>
                    </div>
                    <div className="frame-471">
                      <div className="text-wrapper-237">Humble+</div>
                    </div>
                  </div>
                </div>
                <div className="div-15">
                  <div className="frame-472">
                    <div className="div-container-wrapper">
                      <div className="div-container-2">
                        <div className="search-4">
                          <div className="div-placeholder-4">
                            <div className="text-wrapper-242">Search</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="search-5"
                      alt="Search"
                      src="/img/search-3.svg"
                    />
                  </div>
                  <div className="div-16">
                    <div className="frame-478">
                      <img
                        className="edit-3"
                        alt="Edit"
                        src="/img/edit-6.png"
                      />
                      <div className="text-wrapper-243">Write</div>
                    </div>
                    <div className="frame-479" />
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-480">
              <div className="text-wrapper-244">ABOUT ME</div>
              <p className="lorem-ipsum-dolor-3">
                <span className="text-wrapper-245">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                  <br />
                  <br />
                </span>
                <span className="text-wrapper-246">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt{" "}
                  <br />
                  <br />
                  <br />
                </span>
                <span className="text-wrapper-125">
                  https://chrismessina/humble.com{" "}
                </span>
              </p>
            </div>
            <div className="frame-481">
              <div className="text-wrapper-244">ABOUT ME</div>
              <div className="frame-482">
                <div className="div-16">
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Artificial Intelligence</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Small Business</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Marketing</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Sales</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Funding</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Growth</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Tech &amp; Tools</div>
                  </div>
                </div>
                <div className="div-16">
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Leadership</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Productivity</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Management</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Networking</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Customer Service</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Business Planning</div>
                  </div>
                </div>
                <div className="div-16">
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Innovation</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Finance</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Operations</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Business Strategy</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Human Resources</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Design</div>
                  </div>
                  <div className="button-tall-fixed-20">
                    <div className="text-51">Branding</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-483">
              <div className="text-wrapper-244">WORK</div>
              <p className="text-wrapper-247">
                Full Stack Developer, 99x Studio
              </p>
            </div>
          </div>
          <div className="profile-page-about-2">
            <div className="frame-475">
              <div className="frame-484" />
              <div className="frame-452">
                <div className="frame-453">
                  <div className="frame-454">
                    <div className="text-wrapper-224">Chris Messia</div>
                  </div>
                </div>
                <div className="label-3">
                  <img className="img-14" alt="Stars" src="/img/stars-1.png" />
                  <div className="text-wrapper-225">Expert</div>
                </div>
              </div>
            </div>
            <div className="overlap-41">
              <div className="frame-485">
                <div className="div-13">
                  <div className="frame-456">
                    <img className="img-15" alt="List" src="/img/list-1.png" />
                    <div className="text-wrapper-241">About</div>
                  </div>
                  <div className="frame-457">
                    <img
                      className="img-15"
                      alt="Favorite"
                      src="/img/favorite-1.png"
                    />
                    <div className="text-wrapper-226">Upvotes</div>
                  </div>
                  <div className="frame-456">
                    <img
                      className="img-15"
                      alt="View quilt"
                      src="/img/view-quilt-1.png"
                    />
                    <div className="text-wrapper-226">Stacks</div>
                  </div>
                  <div className="frame-458">
                    <img
                      className="img-15"
                      alt="File copy"
                      src="/img/file-copy-1.png"
                    />
                    <div className="text-wrapper-226">Content</div>
                  </div>
                  <div className="frame-458">
                    <img
                      className="img-15"
                      alt="Currency exchange"
                      src="/img/currency-exchange-1.png"
                    />
                    <div className="text-wrapper-226">Subscription</div>
                  </div>
                  <div className="frame-456">
                    <img
                      className="img-15"
                      alt="Settings"
                      src="/img/settings-1.png"
                    />
                    <div className="text-wrapper-226">Settings</div>
                  </div>
                </div>
              </div>
              <img className="line-71" alt="Line" src="/img/line-19-1.svg" />
            </div>
            <img className="line-74" alt="Line" src="/img/line-18-1.svg" />
            <div className="header-37">
              <div className="div-4">
                <div className="div-15">
                  <img
                    className="humble-logo-5"
                    alt="Humble logo"
                    src="/img/humble-logo-1-7.svg"
                  />
                  <div className="frame-470">
                    <div className="frame-471">
                      <div className="text-wrapper-237">Products</div>
                    </div>
                    <div className="frame-471">
                      <div className="text-wrapper-238">Guides</div>
                    </div>
                    <div className="frame-471">
                      <div className="text-wrapper-238">Topics</div>
                    </div>
                    <div className="frame-471">
                      <div className="text-wrapper-237">Humble+</div>
                    </div>
                  </div>
                </div>
                <div className="div-15">
                  <div className="frame-472">
                    <div className="div-container-wrapper">
                      <div className="div-container-2">
                        <div className="search-4">
                          <div className="div-placeholder-4">
                            <div className="text-wrapper-242">Search</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="search-5"
                      alt="Search"
                      src="/img/search-2.svg"
                    />
                  </div>
                  <div className="div-16">
                    <div className="frame-478">
                      <img
                        className="edit-3"
                        alt="Edit"
                        src="/img/edit-5.png"
                      />
                      <div className="text-wrapper-243">Write</div>
                    </div>
                    <div className="frame-486" />
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-487">
              <div className="frame-488">
                <div className="text-wrapper-248">Account Settings</div>
              </div>
              <div className="container-accoun">
                <div className="container-3">
                  <div className="div-13">
                    <div className="frame-489">
                      <div className="group-9">
                        <div className="frame-490">
                          <div className="text-wrapper-228">Email ID</div>
                          <div className="text-wrapper-249">
                            anusha@99x.network
                          </div>
                        </div>
                      </div>
                      <button className="button-12">
                        <div className="button-base-26">
                          <div className="text-52">Add / Verify</div>
                        </div>
                      </button>
                    </div>
                    <div className="frame-491">
                      <div className="frame-492">
                        <div className="frame-493">
                          <div className="text-wrapper-250">Password</div>
                          <div className="text-wrapper-249">***********</div>
                        </div>
                      </div>
                      <button className="button-13">
                        <div className="button-base-26">
                          <div className="text-52">Change Password</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <Divider
                  className="divider-instance"
                  mode="moderate"
                  modeModerate="/img/divider-1.svg"
                />
                <div className="div-13">
                  <div className="div-13">
                    <div className="frame-494">
                      <div className="group-10">
                        <div className="frame-495">
                          <div className="text-wrapper-250">Name</div>
                          <div className="text-wrapper-251">
                            Santosh Kalaimani
                          </div>
                        </div>
                      </div>
                      <button className="button-12">
                        <div className="button-base-26">
                          <div className="text-52">Change</div>
                        </div>
                      </button>
                    </div>
                    <div className="frame-489">
                      <div className="group-11">
                        <div className="frame-495">
                          <div className="text-wrapper-250">Phone Number</div>
                          <div className="frame-496">
                            <div className="text-wrapper-252">+91</div>
                            <div className="text-wrapper-252">987-123-7589</div>
                          </div>
                        </div>
                      </div>
                      <button className="button-12">
                        <div className="button-base-26">
                          <div className="text-52">Change</div>
                        </div>
                      </button>
                    </div>
                    <div className="frame-489">
                      <div className="group-12">
                        <div className="frame-495">
                          <div className="text-wrapper-250">Company</div>
                          <div className="frame-496">
                            <div className="text-wrapper-252">
                              99x Technologies
                            </div>
                          </div>
                        </div>
                      </div>
                      <button className="button-12">
                        <div className="button-base-26">
                          <div className="text-52">Change</div>
                        </div>
                      </button>
                    </div>
                    <div className="frame-489">
                      <div className="group-13">
                        <div className="frame-495">
                          <div className="text-wrapper-250">Website</div>
                          <div className="frame-496">
                            <div className="text-wrapper-252">
                              99xstartup.com
                            </div>
                          </div>
                        </div>
                      </div>
                      <button className="button-12">
                        <div className="button-base-26">
                          <div className="text-52">Change</div>
                        </div>
                      </button>
                    </div>
                    <div className="frame-497">
                      <div className="group-14">
                        <div className="frame-495">
                          <div className="text-wrapper-250">
                            Linkedin Profile
                          </div>
                          <div className="frame-496">
                            <div className="text-wrapper-252">
                              linkedin.com/santoshkalaimani
                            </div>
                          </div>
                        </div>
                      </div>
                      <button className="button-12">
                        <div className="button-base-26">
                          <div className="text-52">Link Now</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-page-about-3">
            <div className="frame-475">
              <div className="frame-476">
                <img
                  className="ellipse-36"
                  alt="Ellipse"
                  src="/img/ellipse-1-7.png"
                />
              </div>
              <div className="frame-452">
                <div className="frame-453">
                  <div className="frame-454">
                    <div className="text-wrapper-224">Chris Messia</div>
                  </div>
                </div>
                <div className="label-3">
                  <img className="stars-5" alt="Stars" src="/img/divider.png" />
                  <div className="text-wrapper-225">Expert</div>
                </div>
              </div>
            </div>
            <div className="overlap-45">
              <div className="frame-498">
                <div className="div-13">
                  <div className="frame-456">
                    <img className="list-2" alt="List" src="/img/divider.png" />
                    <div className="text-wrapper-241">About</div>
                  </div>
                  <div className="frame-457">
                    <img
                      className="favorite-3"
                      alt="Favorite"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-226">Upvotes</div>
                  </div>
                  <div className="frame-456">
                    <img
                      className="view-quilt-2"
                      alt="View quilt"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-226">Stacks</div>
                  </div>
                  <div className="frame-458">
                    <img
                      className="file-copy-2"
                      alt="File copy"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-226">Content</div>
                  </div>
                  <div className="frame-458">
                    <img
                      className="currency-exchange"
                      alt="Currency exchange"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-226">Subscription</div>
                  </div>
                  <div className="frame-456">
                    <img
                      className="settings"
                      alt="Settings"
                      src="/img/divider.png"
                    />
                    <div className="text-wrapper-226">Settings</div>
                  </div>
                </div>
              </div>
              <img className="line-75" alt="Line" src="/img/line-19.svg" />
            </div>
            <img className="line-74" alt="Line" src="/img/line-18.svg" />
            <div className="header-37">
              <div className="div-4">
                <div className="div-15">
                  <img
                    className="humble-logo-6"
                    alt="Humble logo"
                    src="/img/humble-logo-1-6.svg"
                  />
                  <div className="frame-470">
                    <div className="frame-471">
                      <div className="text-wrapper-237">Products</div>
                    </div>
                    <div className="frame-471">
                      <div className="text-wrapper-238">Guides</div>
                    </div>
                    <div className="frame-471">
                      <div className="text-wrapper-238">Topics</div>
                    </div>
                    <div className="frame-471">
                      <div className="text-wrapper-237">Humble+</div>
                    </div>
                  </div>
                </div>
                <div className="div-15">
                  <div className="frame-472">
                    <div className="div-container-wrapper">
                      <div className="div-container-2">
                        <div className="search-4">
                          <div className="div-placeholder-4">
                            <div className="text-wrapper-242">Search</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="search-5"
                      alt="Search"
                      src="/img/search-1.svg"
                    />
                  </div>
                  <div className="div-16">
                    <div className="frame-478">
                      <img
                        className="edit-3"
                        alt="Edit"
                        src="/img/edit-4.png"
                      />
                      <div className="text-wrapper-243">Write</div>
                    </div>
                    <div className="frame-499" />
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-487">
              <div className="frame-500">
                <div className="text-wrapper-248">Subscription</div>
              </div>
            </div>
            <div className="table-wrapper">
              <div className="table">
                <div className="table-2">
                  <div className="column">
                    <div className="head">
                      <div className="text-wrapper-253">DATE</div>
                      <Icon2 className="icon-2" />
                    </div>
                    <div className="head-2">
                      <div className="frame-501">
                        <div className="text-wrapper-254">02/19/2021</div>
                      </div>
                    </div>
                    <div className="head-2">
                      <div className="frame-501">
                        <div className="text-wrapper-254">02/19/2021</div>
                      </div>
                    </div>
                    <div className="head-2">
                      <div className="frame-501">
                        <div className="text-wrapper-254">02/19/2021</div>
                      </div>
                    </div>
                    <div className="head-3">
                      <div className="frame-501">
                        <div className="text-wrapper-254">02/19/2021</div>
                      </div>
                    </div>
                  </div>
                  <div className="column-2">
                    <div className="head-4">
                      <div className="text-wrapper-255">PAID FOR</div>
                      <Icon3 className="icon-2" />
                    </div>
                    <div className="head-5">
                      <div className="text-wrapper-256">Humble+ Yearly</div>
                    </div>
                    <div className="head-5">
                      <div className="text-wrapper-256">Humble+ Monthly</div>
                    </div>
                    <div className="head-5">
                      <div className="text-wrapper-256">Humble+ Monthly</div>
                    </div>
                    <div className="head-6">
                      <div className="text-wrapper-256">Humble+ Monthly</div>
                    </div>
                  </div>
                  <div className="column-3">
                    <div className="head-7">
                      <div className="text-wrapper-257">STATUS</div>
                      <Icon2 className="icon-4" />
                    </div>
                    <div className="button-states-wrapper">
                      <div className="button-states">
                        <ButtonBase
                          className="button-base-instance"
                          contentClassName="button-base-27"
                          divClassName="button-base-28"
                          showLeftIcon={false}
                          showRightIcon={false}
                          text="Active"
                        />
                      </div>
                    </div>
                    <div className="button-states-wrapper">
                      <div className="button-states-2">
                        <div className="content-wrapper">
                          <div className="content-3">
                            <div className="text-wrapper-258">Expired</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="button-states-wrapper">
                      <div className="button-states-2">
                        <div className="content-wrapper">
                          <div className="content-3">
                            <div className="text-wrapper-258">Expired</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="head-8">
                      <div className="button-states-2">
                        <div className="content-wrapper">
                          <div className="content-3">
                            <div className="text-wrapper-258">Expired</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column-4">
                    <div className="head-4">
                      <div className="text-wrapper-255">NEXT PAYMENT</div>
                      <Icon3 className="icon-2" />
                    </div>
                    <div className="head-5">
                      <div className="frame-502">
                        <div className="frame-452">
                          <div className="text-wrapper-230">
                            January 21, 2023
                          </div>
                          <div className="text-wrapper-259">
                            Visa Credit Card
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="head-5">
                      <div className="frame-502">
                        <div className="frame-503">
                          <div className="text-wrapper-230">
                            December 26, 2022
                          </div>
                          <div className="text-wrapper-259">
                            Visa Credit Card
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="head-9">
                      <div className="frame-502">
                        <div className="frame-503">
                          <div className="text-wrapper-230">
                            October 22, 2022
                          </div>
                          <div className="text-wrapper-259">
                            Visa Credit Card
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="head-10">
                      <div className="frame-502">
                        <div className="frame-503">
                          <div className="text-wrapper-230">
                            October 22, 2022
                          </div>
                          <div className="text-wrapper-259">
                            Visa Credit Card
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column-5">
                    <div className="head-4">
                      <div className="text-wrapper-253">AMOUNT</div>
                      <Icon2 className="icon-2" />
                    </div>
                    <div className="head-11">
                      <div className="text-wrapper-260">$30 / month</div>
                    </div>
                    <div className="head-11">
                      <div className="text-wrapper-261">$15 / month</div>
                    </div>
                    <div className="head-11">
                      <div className="text-wrapper-261">$15 / month</div>
                    </div>
                    <div className="head-12">
                      <div className="text-wrapper-261">$15 / month</div>
                    </div>
                  </div>
                  <div className="column-6">
                    <div className="head-13" />
                    <div className="button-wrapper">
                      <Button
                        buttonBaseClassName="button-14"
                        className="button-instance"
                        size="medium"
                        text="Renew"
                        textClassName="button-15"
                        type="primary"
                      />
                    </div>
                    <div className="head-14" />
                    <div className="head-14" />
                    <div className="head-15" />
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-504">
              <div className="frame-505">
                <div className="text-wrapper-262">Humble +</div>
              </div>
              <div className="frame-506">
                <div className="text-wrapper-263">Active till 02-02-2023</div>
                <p className="text-wrapper-264">
                  VISA ending in XXXX XXXX XXXX 3334
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-507">
          <div className="text-wrapper-265">Mobile Screens</div>
          <div className="profile-page">
            <div className="overlap-group-22">
              <div className="frame-508">
                <img
                  className="humble-logo-7"
                  alt="Humble logo"
                  src="/img/humble-logo-1-1.svg"
                />
                <div className="frame-22">
                  <div className="frame-509">
                    <img className="edit-4" alt="Edit" src="/img/edit-1.png" />
                    <div className="text-wrapper-266">Write</div>
                  </div>
                  <div className="frame-510">
                    <div className="frame-511">
                      <div className="frame-512">
                        <img
                          className="ellipse-37"
                          alt="Ellipse"
                          src="/img/ellipse-1.png"
                        />
                      </div>
                      <img
                        className="img-11"
                        alt="Arrow drop down"
                        src="/img/arrow-drop-down.png"
                      />
                    </div>
                    <div className="frame-513">
                      <div className="frame-514" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs-wrapper">
              <div className="div-17">
                <NavLabels
                  className="nav-labels-11"
                  divClassName="nav-labels-12"
                  stateProp="default"
                  text="Article"
                  theme="light"
                  withIcon={false}
                />
                <NavLabels
                  className="nav-labels-11"
                  divClassName="nav-labels-12"
                  stateProp="default"
                  text="Podcast Episodes"
                  theme="light"
                  withIcon={false}
                />
                <NavLabels
                  className="nav-labels-13"
                  divClassName="nav-labels-14"
                  stateProp="selected"
                  text="Videos"
                  theme="light"
                  withIcon={false}
                />
              </div>
            </div>
            <div className="frame-515">
              <div className="div-18">
                <div className="frame-516">
                  <div className="play-button-wrapper">
                    <img
                      className="play-button-8"
                      alt="Play button"
                      src="/img/play-button-1-1-7.svg"
                    />
                  </div>
                  <div className="frame-517">
                    <div className="frame-518" />
                    <div className="frame-519">
                      <p className="text-wrapper-267">
                        The Harsh Reality of Being a UX Designer
                      </p>
                      <div className="frame-520">
                        <div className="text-wrapper-268">Sam Miller</div>
                        <div className="text-53">·</div>
                        <div className="text-54">July 14, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-516">
                  <div className="frame-521">
                    <img
                      className="play-button-8"
                      alt="Play button"
                      src="/img/play-button-1-1-6.svg"
                    />
                  </div>
                  <div className="frame-517">
                    <div className="frame-518" />
                    <div className="frame-519">
                      <p className="text-wrapper-267">
                        The Harsh Reality of Being a UX Designer
                      </p>
                      <div className="frame-520">
                        <div className="text-wrapper-268">Sam Miller</div>
                        <div className="text-53">·</div>
                        <div className="text-54">July 14, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-18">
                <div className="frame-516">
                  <div className="frame-522">
                    <img
                      className="play-button-8"
                      alt="Play button"
                      src="/img/play-button-1-1-5.svg"
                    />
                  </div>
                  <div className="frame-517">
                    <div className="frame-518" />
                    <div className="frame-519">
                      <p className="text-wrapper-267">
                        The Harsh Reality of Being a UX Designer
                      </p>
                      <div className="frame-520">
                        <div className="text-wrapper-268">Sam Miller</div>
                        <div className="text-53">·</div>
                        <div className="text-54">July 14, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-516">
                  <div className="frame-521">
                    <img
                      className="play-button-8"
                      alt="Play button"
                      src="/img/play-button-1-1-4.svg"
                    />
                  </div>
                  <div className="frame-517">
                    <div className="frame-518" />
                    <div className="frame-519">
                      <p className="text-wrapper-267">
                        The Harsh Reality of Being a UX Designer
                      </p>
                      <div className="frame-520">
                        <div className="text-wrapper-268">Sam Miller</div>
                        <div className="text-53">·</div>
                        <div className="text-54">July 14, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-18">
                <div className="frame-516">
                  <div className="frame-521">
                    <img
                      className="play-button-8"
                      alt="Play button"
                      src="/img/play-button-1-1-3.svg"
                    />
                  </div>
                  <div className="frame-517">
                    <div className="frame-518" />
                    <div className="frame-519">
                      <p className="text-wrapper-267">
                        The Harsh Reality of Being a UX Designer
                      </p>
                      <div className="frame-520">
                        <div className="text-wrapper-268">Sam Miller</div>
                        <div className="text-53">·</div>
                        <div className="text-54">July 14, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-516">
                  <div className="frame-522">
                    <img
                      className="play-button-8"
                      alt="Play button"
                      src="/img/play-button-1-1-2.svg"
                    />
                  </div>
                  <div className="frame-517">
                    <div className="frame-518" />
                    <div className="frame-519">
                      <p className="text-wrapper-267">
                        The Harsh Reality of Being a UX Designer
                      </p>
                      <div className="frame-520">
                        <div className="text-wrapper-268">Sam Miller</div>
                        <div className="text-53">·</div>
                        <div className="text-54">July 14, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-18">
                <div className="frame-516">
                  <div className="frame-522">
                    <img
                      className="play-button-8"
                      alt="Play button"
                      src="/img/play-button-1-1-1.svg"
                    />
                  </div>
                  <div className="frame-517">
                    <div className="frame-518" />
                    <div className="frame-519">
                      <p className="text-wrapper-267">
                        The Harsh Reality of Being a UX Designer
                      </p>
                      <div className="frame-520">
                        <div className="text-wrapper-268">Sam Miller</div>
                        <div className="text-53">·</div>
                        <div className="text-54">July 14, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-516">
                  <div className="frame-521">
                    <img
                      className="play-button-8"
                      alt="Play button"
                      src="/img/play-button-1-1.svg"
                    />
                  </div>
                  <div className="frame-517">
                    <div className="frame-518" />
                    <div className="frame-519">
                      <p className="text-wrapper-267">
                        The Harsh Reality of Being a UX Designer
                      </p>
                      <div className="frame-520">
                        <div className="text-wrapper-268">Sam Miller</div>
                        <div className="text-53">·</div>
                        <div className="text-54">July 14, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-page-stacks">
            <div className="overlap-group-22">
              <div className="frame-508">
                <img
                  className="humble-logo-8"
                  alt="Humble logo"
                  src="/img/humble-logo-1-4.svg"
                />
                <div className="frame-22">
                  <div className="frame-509">
                    <img className="edit-5" alt="Edit" src="/img/divider.png" />
                    <div className="text-wrapper-266">Write</div>
                  </div>
                  <div className="frame-510">
                    <div className="frame-511">
                      <div className="frame-512">
                        <img
                          className="ellipse-38"
                          alt="Ellipse"
                          src="/img/divider.png"
                        />
                      </div>
                      <img
                        className="arrow-drop-down"
                        alt="Arrow drop down"
                        src="/img/divider.png"
                      />
                    </div>
                    <div className="frame-513">
                      <div className="frame-523" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-524">
              <div className="container-product-wrapper">
                <div className="container-product-3">
                  <div className="frame-525">
                    <div className="image-13">
                      <img
                        className="image-14"
                        alt="Image"
                        src="/img/image-4.png"
                      />
                    </div>
                    <div className="container-product-4">
                      <div className="div-19">
                        <p className="text-wrapper-269">
                          Lorem ipsum dolor sit amet,ed do
                        </p>
                      </div>
                      <div className="div-17">
                        <div className="frame-526">
                          <div className="text-55">Stacked 15d ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-527">
                    <div className="button-base-29">
                      <div className="text-56">I use this</div>
                    </div>
                    <div className="button-base-30">
                      <div className="text-57">I use something else</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-product-wrapper">
                <div className="container-product-3">
                  <div className="frame-525">
                    <div className="image-13">
                      <img
                        className="image-14"
                        alt="Image"
                        src="/img/image-4-2.png"
                      />
                    </div>
                    <div className="container-product-4">
                      <div className="div-19">
                        <p className="text-wrapper-269">
                          Lorem ipsum dolor sit amet,ed do
                        </p>
                      </div>
                      <div className="div-17">
                        <div className="frame-526">
                          <div className="text-55">Stacked 15d ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-527">
                    <div className="button-base-29">
                      <div className="text-56">I use this</div>
                    </div>
                    <div className="button-base-30">
                      <div className="text-57">I use something else</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-product-wrapper">
                <div className="container-product-3">
                  <div className="frame-525">
                    <div className="image-13">
                      <img
                        className="image-14"
                        alt="Image"
                        src="/img/image-4-2.png"
                      />
                    </div>
                    <div className="container-product-4">
                      <div className="div-19">
                        <p className="text-wrapper-269">
                          Lorem ipsum dolor sit amet,ed do
                        </p>
                      </div>
                      <div className="div-17">
                        <div className="frame-526">
                          <div className="text-55">Stacked 15d ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-527">
                    <div className="button-base-29">
                      <div className="text-56">I use this</div>
                    </div>
                    <div className="button-base-30">
                      <div className="text-57">I use something else</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-product-wrapper">
                <div className="container-product-3">
                  <div className="frame-525">
                    <div className="image-13">
                      <img
                        className="image-14"
                        alt="Image"
                        src="/img/image-4-1.png"
                      />
                    </div>
                    <div className="container-product-4">
                      <div className="div-19">
                        <p className="text-wrapper-269">
                          Lorem ipsum dolor sit amet,ed do
                        </p>
                      </div>
                      <div className="div-17">
                        <div className="frame-526">
                          <div className="text-55">Stacked 15d ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-527">
                    <div className="button-base-29">
                      <div className="text-56">I use this</div>
                    </div>
                    <div className="button-base-30">
                      <div className="text-57">I use something else</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-product-wrapper">
                <div className="container-product-3">
                  <div className="frame-525">
                    <div className="image-13">
                      <img
                        className="image-14"
                        alt="Image"
                        src="/img/image-4.png"
                      />
                    </div>
                    <div className="container-product-4">
                      <div className="div-19">
                        <p className="text-wrapper-269">
                          Lorem ipsum dolor sit amet,ed do
                        </p>
                      </div>
                      <div className="div-17">
                        <div className="frame-526">
                          <div className="text-55">Stacked 15d ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-527">
                    <div className="button-base-29">
                      <div className="text-56">I use this</div>
                    </div>
                    <div className="button-base-30">
                      <div className="text-57">I use something else</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="login">
            <div className="overlap-46">
              <img
                className="frame-528"
                alt="Frame"
                src="/img/frame-163052-1.svg"
              />
            </div>
            <div className="frame-529">
              <div className="frame-530">
                <div className="frame-531">
                  <div className="text-wrapper-270">Welcome back to Humble</div>
                  <p className="text-58">
                    Sign in to continue to your account.
                  </p>
                </div>
                <div className="frame-28">
                  <div className="div-8">
                    <div className="frame-28">
                      <div className="frame-532">
                        <div className="frame-28">
                          <div className="frame-533">
                            <img
                              className="frame-534"
                              alt="Frame"
                              src="/img/frame-40613.svg"
                            />
                            <div className="text-59">Continue with Google</div>
                          </div>
                          <div className="frame-533">
                            <img
                              className="frame-534"
                              alt="Frame"
                              src="/img/frame-40616.svg"
                            />
                            <div className="text-59">
                              Continue with Facebook
                            </div>
                          </div>
                          <div className="frame-533">
                            <img
                              className="frame-534"
                              alt="Frame"
                              src="/img/frame-40617.svg"
                            />
                            <div className="text-59">Continue with Apple</div>
                          </div>
                        </div>
                        <div className="frame-535">
                          <img
                            className="line-76"
                            alt="Line"
                            src="/img/line-16.svg"
                          />
                          <div className="text-60">Or</div>
                          <img
                            className="line-76"
                            alt="Line"
                            src="/img/line-16.svg"
                          />
                        </div>
                      </div>
                      <div className="frame-28">
                        <div className="frame-536">
                          <div className="frame-537">
                            <div className="text-wrapper-271">
                              Email address
                            </div>
                          </div>
                          <div className="frame-537">
                            <div className="text-wrapper-271">
                              Create a password
                            </div>
                          </div>
                        </div>
                        <div className="frame-510">
                          <div className="checkbox" />
                          <p className="text-61">
                            Keep me signed in until I sign out
                          </p>
                        </div>
                      </div>
                    </div>
                    <button className="button-16">
                      <button className="button-base-31">
                        <div className="text-62">Sign Up</div>
                      </button>
                    </button>
                  </div>
                  <div className="text-63">Forgot password?</div>
                </div>
                <p className="text-64">
                  <span className="text-wrapper-272">
                    You already have an account
                  </span>
                  <span className="text-wrapper-273">&nbsp;&nbsp;</span>
                  <span className="text-wrapper-274">Sign In.</span>
                </p>
              </div>
            </div>
          </div>
          <div className="login-details">
            <div className="overlap-46">
              <img
                className="frame-538"
                alt="Frame"
                src="/img/frame-163052.svg"
              />
            </div>
            <div className="frame-539">
              <div className="frame-531">
                <div className="text-wrapper-270">Almost there!</div>
                <p className="text-wrapper-275">
                  Tell us a little bit more about you, and to make your own
                  world in humble
                </p>
              </div>
              <div className="frame-28">
                <div className="frame-536">
                  <div className="frame-537">
                    <div className="text-wrapper-271">Name</div>
                  </div>
                  <div className="frame-537">
                    <div className="text-wrapper-271">Designation</div>
                  </div>
                  <div className="frame-537">
                    <div className="text-wrapper-271">Comapny</div>
                  </div>
                </div>
              </div>
              <div className="button-base-32">
                <div className="text-62"> Continue</div>
              </div>
            </div>
          </div>
          <img
            className="all-topics"
            alt="All topics"
            src="/img/all-topics.png"
          />
          <div className="profile-page-2">
            <div className="overlap-group-22">
              <div className="frame-508">
                <img
                  className="humble-logo-7"
                  alt="Humble logo"
                  src="/img/humble-logo-1-3.svg"
                />
                <div className="frame-22">
                  <div className="frame-509">
                    <img className="edit-4" alt="Edit" src="/img/edit-1.png" />
                    <div className="text-wrapper-266">Write</div>
                  </div>
                  <div className="frame-510">
                    <div className="frame-511">
                      <div className="frame-512">
                        <img
                          className="ellipse-37"
                          alt="Ellipse"
                          src="/img/ellipse-1.png"
                        />
                      </div>
                      <img
                        className="img-11"
                        alt="Arrow drop down"
                        src="/img/arrow-drop-down.png"
                      />
                    </div>
                    <div className="frame-513">
                      <div className="frame-514" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs-wrapper">
              <div className="div-17">
                <NavLabels
                  className="nav-labels-11"
                  divClassName="nav-labels-12"
                  stateProp="default"
                  text="Article"
                  theme="light"
                  withIcon={false}
                />
                <NavLabels
                  className="nav-labels-13"
                  divClassName="nav-labels-14"
                  stateProp="selected"
                  text="Podcast Episodes"
                  theme="light"
                  withIcon={false}
                />
                <NavLabels
                  className="nav-labels-11"
                  divClassName="nav-labels-12"
                  stateProp="default"
                  text="Videos"
                  theme="light"
                  withIcon={false}
                />
              </div>
            </div>
            <div className="frame-540">
              <div className="div-18">
                <div className="frame-541">
                  <div className="frame-542" />
                  <div className="frame-543">
                    <p className="text-wrapper-276">
                      The Harsh Reality of Being a UX Designer
                    </p>
                    <div className="frame-270">
                      <div className="frame-544">
                        <div className="text-wrapper-277">EP 12</div>
                      </div>
                      <div className="text-65">·</div>
                      <div className="text-66">July 14, 2022</div>
                    </div>
                  </div>
                </div>
                <div className="frame-541">
                  <div className="frame-545" />
                  <div className="frame-543">
                    <p className="text-wrapper-276">
                      The Harsh Reality of Being a UX Designer
                    </p>
                    <div className="frame-270">
                      <div className="frame-544">
                        <div className="text-wrapper-277">EP 12</div>
                      </div>
                      <div className="text-65">·</div>
                      <div className="text-66">July 14, 2022</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-18">
                <div className="frame-541">
                  <div className="frame-546" />
                  <div className="frame-543">
                    <p className="text-wrapper-276">
                      The Harsh Reality of Being a UX Designer
                    </p>
                    <div className="frame-270">
                      <div className="frame-544">
                        <div className="text-wrapper-277">EP 12</div>
                      </div>
                      <div className="text-65">·</div>
                      <div className="text-66">July 14, 2022</div>
                    </div>
                  </div>
                </div>
                <div className="frame-541">
                  <div className="frame-547" />
                  <div className="frame-543">
                    <p className="text-wrapper-276">
                      The Harsh Reality of Being a UX Designer
                    </p>
                    <div className="frame-270">
                      <div className="frame-544">
                        <div className="text-wrapper-277">EP 12</div>
                      </div>
                      <div className="text-65">·</div>
                      <div className="text-66">July 14, 2022</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-18">
                <div className="frame-541">
                  <div className="frame-542" />
                  <div className="frame-543">
                    <p className="text-wrapper-276">
                      The Harsh Reality of Being a UX Designer
                    </p>
                    <div className="frame-270">
                      <div className="frame-544">
                        <div className="text-wrapper-277">EP 12</div>
                      </div>
                      <div className="text-65">·</div>
                      <div className="text-66">July 14, 2022</div>
                    </div>
                  </div>
                </div>
                <div className="frame-541">
                  <div className="frame-545" />
                  <div className="frame-543">
                    <p className="text-wrapper-276">
                      The Harsh Reality of Being a UX Designer
                    </p>
                    <div className="frame-270">
                      <div className="frame-544">
                        <div className="text-wrapper-277">EP 12</div>
                      </div>
                      <div className="text-65">·</div>
                      <div className="text-66">July 14, 2022</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-18">
                <div className="frame-541">
                  <div className="frame-546" />
                  <div className="frame-543">
                    <p className="text-wrapper-276">
                      The Harsh Reality of Being a UX Designer
                    </p>
                    <div className="frame-270">
                      <div className="frame-544">
                        <div className="text-wrapper-277">EP 12</div>
                      </div>
                      <div className="text-65">·</div>
                      <div className="text-66">July 14, 2022</div>
                    </div>
                  </div>
                </div>
                <div className="frame-541">
                  <div className="frame-547" />
                  <div className="frame-543">
                    <p className="text-wrapper-276">
                      The Harsh Reality of Being a UX Designer
                    </p>
                    <div className="frame-270">
                      <div className="frame-544">
                        <div className="text-wrapper-277">EP 12</div>
                      </div>
                      <div className="text-65">·</div>
                      <div className="text-66">July 14, 2022</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="create-podcast-5">
            <div className="overlap-group-22">
              <div className="frame-508">
                <img
                  className="humble-logo-7"
                  alt="Humble logo"
                  src="/img/humble-logo-1-2.svg"
                />
                <div className="frame-548">
                  <div className="frame-548">
                    <div className="frame-510">
                      <div className="frame-511">
                        <div className="frame-512">
                          <img
                            className="ellipse-37"
                            alt="Ellipse"
                            src="/img/ellipse-1.png"
                          />
                        </div>
                        <img
                          className="img-11"
                          alt="Arrow drop down"
                          src="/img/arrow-drop-down.png"
                        />
                      </div>
                      <div className="frame-513">
                        <div className="frame-514" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-47">
              <div className="frame-549" />
              <div className="frame-550">
                <div className="frame-551">
                  <div className="text-wrapper-278">Podcast Name</div>
                  <div className="frame-552">
                    <div className="button-tall-fixed-21">
                      <div className="text-67">0/100</div>
                    </div>
                  </div>
                </div>
                <div className="frame-551">
                  <div className="text-wrapper-278">Podcast Descrption</div>
                  <div className="frame-553">
                    <p className="text-wrapper-279">
                      Tell People what your podcast is about. You can always
                      change this later.
                    </p>
                    <div className="button-tall-fixed-21">
                      <div className="text-67">0/600</div>
                    </div>
                  </div>
                </div>
                <div className="frame-551">
                  <div className="text-wrapper-278">Podcast Language</div>
                  <div className="frame-554">
                    <div className="text-wrapper-280">Choose One Option</div>
                    <img
                      className="img-11"
                      alt="Expand more"
                      src="/img/expand-more.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-555">
              <div className="frame-556">
                <img className="check-9" alt="Check" src="/img/check-1.png" />
              </div>
              <img className="line-76" alt="Line" src="/img/line-22.svg" />
              <div className="frame-557">
                <img className="check-9" alt="Check" src="/img/check-1.png" />
              </div>
              <img className="line-76" alt="Line" src="/img/line-22.svg" />
              <div className="frame-558">
                <img className="check-9" alt="Check" src="/img/check.png" />
              </div>
            </div>
            <div className="frame-559">
              <div className="frame-28">
                <div className="text-wrapper-281">Set up your Podcast</div>
                <p className="text-wrapper-275">
                  Before we can distribute your podcast to additional listening
                  platforms, you just need to fill in a few things Pssst: You
                  can always change these later
                </p>
              </div>
            </div>
            <button className="button-base-33">
              <div className="text-68">Continue</div>
              <img
                className="img-11"
                alt="Arrow right alt"
                src="/img/arrow-right-alt.png"
              />
            </button>
          </div>
          <div className="create-video-video-2">
            <div className="overlap-group-22">
              <div className="frame-508">
                <img
                  className="humble-logo-7"
                  alt="Humble logo"
                  src="/img/humble-logo-1-1.svg"
                />
                <div className="frame-510">
                  <div className="frame-511">
                    <div className="frame-512">
                      <img
                        className="ellipse-37"
                        alt="Ellipse"
                        src="/img/ellipse-1.png"
                      />
                    </div>
                    <img
                      className="img-11"
                      alt="Arrow drop down"
                      src="/img/arrow-drop-down.png"
                    />
                  </div>
                  <div className="frame-513">
                    <div className="frame-514" />
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-48">
              <div className="frame-560" />
              <div className="frame-550">
                <div className="frame-551">
                  <div className="text-wrapper-278">Podcast Name</div>
                  <div className="frame-552">
                    <div className="button-tall-fixed-21">
                      <div className="text-67">0/100</div>
                    </div>
                  </div>
                </div>
                <div className="frame-551">
                  <div className="text-wrapper-278">Description</div>
                  <div className="frame-553">
                    <p className="text-wrapper-279">
                      Tell People what your podcast is about. You can always
                      change this later.
                    </p>
                    <div className="button-tall-fixed-21">
                      <div className="text-67">0/600</div>
                    </div>
                  </div>
                </div>
                <div className="frame-28">
                  <div className="div-19">
                    <div className="text-wrapper-282">Thumbnail</div>
                    <p className="upload-a-picture-2">
                      Upload a picture that shows what&#39;s in your video
                    </p>
                  </div>
                  <div className="frame-561">
                    <div className="frame-562">
                      <img
                        className="img-11"
                        alt="Add photo alternate"
                        src="/img/add-photo-alternate.png"
                      />
                      <div className="text-wrapper-283">Upload thumbnail</div>
                    </div>
                    <div className="frame-563" />
                    <div className="frame-564" />
                  </div>
                </div>
                <div className="frame-551">
                  <div className="text-wrapper-278">Publish Date:</div>
                  <div className="expand-more-wrapper">
                    <img
                      className="img-11"
                      alt="Expand more"
                      src="/img/expand-more.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-555">
              <div className="frame-556">
                <img className="check-9" alt="Check" src="/img/check-1.png" />
              </div>
              <img className="line-76" alt="Line" src="/img/line-22.svg" />
              <div className="frame-556">
                <img className="check-9" alt="Check" src="/img/check-1.png" />
              </div>
              <img className="line-76" alt="Line" src="/img/line-22.svg" />
              <div className="frame-558">
                <img className="check-9" alt="Check" src="/img/check.png" />
              </div>
            </div>
            <div className="text-wrapper-284">Details</div>
            <div className="frame-565">
              <div className="frame-272">
                <div className="frame-566">
                  <div className="frame-567">
                    <img
                      className="frame-568"
                      alt="Frame"
                      src="/img/frame-40822.svg"
                    />
                    <div className="controls-4">
                      <div className="frame-510">
                        <div className="div-9">
                          <div className="frame-534">
                            <img
                              className="img-16"
                              alt="Play arrow"
                              src="/img/play-arrow.png"
                            />
                          </div>
                          <div className="frame-534">
                            <img
                              className="img-16"
                              alt="Volume up"
                              src="/img/volume-up.png"
                            />
                          </div>
                        </div>
                        <div className="text-wrapper-285">0:00/0:00</div>
                      </div>
                      <div className="frame-534">
                        <img
                          className="fullscreen"
                          alt="Fullscreen"
                          src="/img/fullscreen.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-256">
                  <div className="frame-569">
                    <div className="text-wrapper-286">File Name:</div>
                    <p className="text-wrapper-287">The Diary of a wimpy kid</p>
                  </div>
                  <div className="frame-570">
                    <div className="text-wrapper-286">Link:</div>
                    <div className="frame-47">
                      <div className="text-wrapper-288">
                        https://youtu.be/JDQV2iXim-M
                      </div>
                      <img
                        className="content-copy-2"
                        alt="Content copy"
                        src="/img/divider.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-571">
                <div className="button-base-34">
                  <div className="text-69">Save Draft</div>
                </div>
                <button className="button-base-35">
                  <div className="text-62">Next</div>
                </button>
              </div>
            </div>
          </div>
          <div className="content-unlocked">
            <div className="overlap-46">
              <div className="frame-572">
                <img
                  className="humble-logo-7"
                  alt="Humble logo"
                  src="/img/humble-logo-1.svg"
                />
                <div className="frame-548">
                  <div className="frame-509">
                    <img className="edit-4" alt="Edit" src="/img/edit.png" />
                    <div className="text-wrapper-266">Write</div>
                  </div>
                  <div className="frame-573" />
                  <div className="frame-513">
                    <div className="frame-514" />
                  </div>
                </div>
              </div>
            </div>
            <div className="blog-3">
              <div className="frame-574" />
            </div>
            <div className="frame-575">
              <div className="div-container-wrapper">
                <div className="div-container-3">
                  <div className="search-6">
                    <div className="div-placeholder-5">
                      <div className="text-wrapper-289">Search</div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="search-7" alt="Search" src="/img/search.svg" />
            </div>
            <div className="frame-576">
              <div className="content-container">
                <div className="frame-577">
                  <div className="label-container">
                    <div className="button-tall-fixed-22">
                      <div className="text-70">DESIGN</div>
                    </div>
                    <div className="ellipse-15" />
                    <div className="text-71">15 min read</div>
                  </div>
                  <p className="element-types-of-2">
                    10 types of cognitive bias to watch out for in UX research
                    &amp; design
                  </p>
                </div>
                <img className="line-77" alt="Line" src="/img/line-9.svg" />
              </div>
              <div className="frame-578">
                <div className="bookmark-add-wrapper">
                  <img
                    className="bookmark-add-2"
                    alt="Bookmark add"
                    src="/img/bookmark-add.png"
                  />
                </div>
              </div>
            </div>
            <div className="frame-579">
              <div className="frame-580">
                <div className="overlap-49">
                  <div className="frame-581">
                    <div className="frame-578">
                      <div className="overlap-group-23">
                        <img
                          className="ellipse-39"
                          alt="Ellipse"
                          src="/img/ellipse-51.png"
                        />
                        <img
                          className="ellipse-39"
                          alt="Ellipse"
                          src="/img/ellipse-51.png"
                        />
                      </div>
                    </div>
                    <div className="text-wrapper-290">Arthur Shelby</div>
                  </div>
                  <div className="frame-582">
                    <div className="text-72">July 14, 2022</div>
                    <div className="text-21">·</div>
                    <div className="text-72">8 min read</div>
                  </div>
                </div>
              </div>
              <div className="button-tall-fixed-wrapper">
                <div className="button-tall-fixed-3">
                  <img
                    className="frame-534"
                    alt="Play circle"
                    src="/img/play-circle.png"
                  />
                  <div className="text-73">Listen to this article</div>
                </div>
              </div>
            </div>
            <div className="frame-583">
              <p className="lorem-ipsum-is-10">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry&#39;s standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <div className="text-74">
                <p className="lorem-ipsum-is-11">
                  <span className="text-wrapper-291">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the
                    industry&#39;s standard dummy text ever since the 1500s,
                    when an unknown printer took{" "}
                  </span>
                  <span className="text-wrapper-292">
                    a galley of type and scrambled it to make a type specimen
                    book
                  </span>
                  <span className="text-wrapper-291">
                    . It has survived not only five centuries, but also the leap
                    into electronic typesetting, remaining essentially
                    unchanged. It was popularised in the 1960s with the release
                    of Letraset sheets containing Lorem Ipsum passages, and more
                    recently with desktop publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum.
                  </span>
                </p>
              </div>
              <div className="frame-584">
                <p className="lorem-ipsum-is-12">
                  <span className="text-wrapper-291">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the
                    industry&#39;s standard dummy text ever since the 1500s,
                    when an unknown printer took{" "}
                  </span>
                  <span className="text-wrapper-292">
                    a galley of type and scrambled it to make a type specimen
                    book
                  </span>
                  <span className="text-wrapper-291">
                    . It has survived not only five centuries,
                    <br />
                    <br />
                    But also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing{" "}
                  </span>
                </p>
              </div>
              <div className="frame-256">
                <div className="rectangle-33" />
                <p className="text-wrapper-293">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor
                </p>
              </div>
              <p className="lorem-ipsum-is-13">
                <span className="text-wrapper-291">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry&#39;s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took{" "}
                </span>
                <span className="text-wrapper-292">
                  a galley of type and scrambled it to make a type specimen book
                </span>
                <span className="text-wrapper-291">
                  . It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                  It was popularised in the 1960s with the release of Letraset
                  sheets containing Lorem Ipsum passages, and more recently with
                  desktop publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum.
                </span>
              </p>
              <div className="frame-256">
                <img className="line-78" alt="Line" src="/img/ios-share.png" />
                <div className="frame-256">
                  <div className="frame-585">
                    <button className="button-tall-fixed-4">
                      <img
                        className="maps-ugc-3"
                        alt="Maps ugc"
                        src="/img/ios-share.png"
                      />
                      <div className="text-59">Comment</div>
                    </button>
                    <button className="button-tall-fixed-4">
                      <img
                        className="ios-share-3"
                        alt="Ios share"
                        src="/img/ios-share.png"
                      />
                      <div className="text-59">Share</div>
                    </button>
                  </div>
                  <div className="frame-586">
                    <div className="text-75">Tags</div>
                    <div className="frame-258">
                      <div className="frame-259">
                        <div className="frame-260">
                          <div className="text-wrapper-294">
                            Task Management
                          </div>
                        </div>
                        <div className="frame-260">
                          <div className="text-wrapper-294">Finance</div>
                        </div>
                        <div className="frame-260">
                          <div className="text-wrapper-294">Utilities</div>
                        </div>
                      </div>
                      <div className="frame-260">
                        <div className="text-wrapper-294">Task Management</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-587">
              <p className="recommended-topics-2">
                <span className="text-wrapper-295">Recommended </span>
                <span className="text-wrapper-296">Topics</span>
              </p>
              <div className="frame-258">
                <div className="frame-535">
                  <div className="button-tall-fixed-23">
                    <div className="text-76">Design</div>
                  </div>
                  <div className="button-tall-fixed-23">
                    <div className="text-76">Communication</div>
                  </div>
                  <div className="button-tall-fixed-23">
                    <div className="text-76">Task Management</div>
                  </div>
                </div>
                <div className="frame-561">
                  <div className="button-tall-fixed-24">
                    <div className="text-76">Finance</div>
                  </div>
                  <div className="button-tall-fixed-24">
                    <div className="text-76">Product Management</div>
                  </div>
                  <div className="button-tall-fixed-23">
                    <div className="text-76">Utilities</div>
                  </div>
                </div>
                <div className="frame-588">
                  <div className="button-tall-fixed-25">
                    <div className="text-76">Design</div>
                  </div>
                  <div className="button-tall-fixed-25">
                    <div className="text-76">Marketing</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="newsletter-3">
              <p className="text-wrapper-297">
                Get the best of Humble directly in your inbox. 💌
              </p>
              <div className="frame-28">
                <div className="frame-589">
                  <div className="text-wrapper-298">Your email</div>
                </div>
                <div className="frame-590">
                  <div className="text-wrapper-299">
                    Subscribe to the newsletter
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-591">
              <div className="frame-592">
                <div className="text-wrapper-300">More Like this</div>
                <div className="frame-259">
                  <div className="frame-593">
                    <div className="frame-594" />
                    <div className="div-19">
                      <div className="frame-595">
                        <div className="frame-270">
                          <div className="button-tall-fixed-26">
                            <div className="text-77">DESIGN</div>
                          </div>
                          <div className="frame-596">
                            <div className="text-78">·</div>
                            <div className="text-wrapper-301">Sam Miller</div>
                            <div className="text-78">·</div>
                          </div>
                        </div>
                      </div>
                      <p className="text-wrapper-302">
                        The Harsh Reality of Being a UX Designer
                      </p>
                      <div className="frame-270">
                        <div className="text-79">July 14, 2022</div>
                        <div className="text-78">·</div>
                        <div className="text-80">Based on your topics</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-593">
                    <div className="frame-597" />
                    <div className="frame-598">
                      <div className="frame-595">
                        <div className="frame-270">
                          <div className="button-tall-fixed-26">
                            <div className="text-77">DESIGN</div>
                          </div>
                          <div className="frame-596">
                            <div className="text-78">·</div>
                            <div className="text-wrapper-301">Sam Miller</div>
                            <div className="text-78">·</div>
                          </div>
                        </div>
                      </div>
                      <p className="text-wrapper-303">
                        The Harsh Reality of Being a UX Designer
                      </p>
                      <div className="frame-270">
                        <div className="text-79">July 14, 2022</div>
                        <div className="text-78">·</div>
                        <div className="text-80">Based on your topics</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-599">
                    <div className="frame-600" />
                    <div className="frame-598">
                      <div className="frame-595">
                        <div className="frame-270">
                          <div className="button-tall-fixed-26">
                            <div className="text-77">DESIGN</div>
                          </div>
                          <div className="frame-596">
                            <div className="text-78">·</div>
                            <div className="text-wrapper-301">Sam Miller</div>
                            <div className="text-78">·</div>
                          </div>
                        </div>
                      </div>
                      <p className="text-wrapper-303">
                        The Harsh Reality of Being a UX Designer
                      </p>
                      <div className="frame-270">
                        <div className="text-79">July 14, 2022</div>
                        <div className="text-78">·</div>
                        <div className="text-80">Based on your topics</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-601">
              <div className="frame-602">
                <div className="frame-272">
                  <div className="frame-603">
                    <img
                      className="frame-604"
                      alt="Frame"
                      src="/img/divider.png"
                    />
                    <div className="frame-605">
                      <p className="text-wrapper-304">
                        32&nbsp;&nbsp;- 44 Upper Rupert Street
                      </p>
                      <div className="text-wrapper-305">W12 8DY Kensington</div>
                      <div className="text-wrapper-305">United Kingdom</div>
                    </div>
                  </div>
                  <div className="frame-258">
                    <div className="div-18">
                      <div className="text-wrapper-304">About us</div>
                      <div className="text-wrapper-304">Privacy Policy</div>
                      <div className="text-wrapper-304">Legal</div>
                    </div>
                    <img
                      className="frame-606"
                      alt="Frame"
                      src="/img/divider.png"
                    />
                  </div>
                </div>
                <div className="frame-607">
                  <img
                    className="divider-10"
                    alt="Divider"
                    src="/img/divider.png"
                  />
                  <p className="text-wrapper-306">
                    Copyright 2023 99x technologies Pvt Ltd.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-608">
          <div className="text-wrapper-307">The Result</div>
          <p className="text-wrapper-308">
            The outcome: an ambitiously humble brand focused on the future,
            blending a seamless product experience with a more engaging creative
            expression. A humble that motivates people to think and build a
            better ecosystem for moving humanity forward.
          </p>
        </div>
        <div className="group-15">
          <div className="mask-group-wrapper">
            <img
              className="mask-group"
              alt="Mask group"
              src="/img/mask-group.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
