/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Avatar = ({
  className,
  divClassName,
  divClassNameOverride,
  imageClassName,
  image = "/img/image-2.svg",
}) => {
  return (
    <div className={`avatar ${className}`}>
      <div className={`text-wrapper-9 ${divClassName}`}>Elliot Rosenberg</div>
      <div className={`text-wrapper-10 ${divClassNameOverride}`}>Product Lead</div>
      <img className={`image ${imageClassName}`} alt="Image" src={image} />
    </div>
  );
};

Avatar.propTypes = {
  image: PropTypes.string,
};
