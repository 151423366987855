/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons4 = ({ className }) => {
  return (
    <svg
      className={`icons-4 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="20"
        id="mask0_14_24356"
        maskUnits="userSpaceOnUse"
        style="mask-type:alpha"
        width="20"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="20" width="20" />
      </mask>
      <g className="g" mask="url(#mask0_14_24356)">
        <path
          className="path"
          d="M10 13.4203C10.9322 13.4203 11.7246 13.0879 12.3774 12.4231C13.0296 11.7578 13.3557 10.9501 13.3557 10.0001C13.3557 9.05003 13.0296 8.24235 12.3774 7.57706C11.7246 6.91228 10.9322 6.57989 10 6.57989C9.06788 6.57989 8.27544 6.91228 7.62269 7.57706C6.97044 8.24235 6.64432 9.05003 6.64432 10.0001C6.64432 10.9501 6.97044 11.7578 7.62269 12.4231C8.27544 13.0879 9.06788 13.4203 10 13.4203ZM10 11.9572C9.46559 11.9572 9.01195 11.7672 8.6391 11.3872C8.26624 11.0071 8.07981 10.5448 8.07981 10.0001C8.07981 9.45538 8.26624 8.99302 8.6391 8.613C9.01195 8.23298 9.46559 8.04297 10 8.04297C10.5344 8.04297 10.9881 8.23298 11.3609 8.613C11.7338 8.99302 11.9202 9.45538 11.9202 10.0001C11.9202 10.5448 11.7338 11.0071 11.3609 11.3872C10.9881 11.7672 10.5344 11.9572 10 11.9572ZM10 15.8334C8.14817 15.8334 6.46733 15.3044 4.95752 14.2464C3.4472 13.189 2.35026 11.7735 1.66669 10.0001C2.35026 8.22665 3.4472 6.81094 4.95752 5.75296C6.46733 4.69549 8.14817 4.16675 10 4.16675C11.8519 4.16675 13.533 4.69549 15.0433 5.75296C16.5531 6.81094 17.6498 8.22665 18.3334 10.0001C17.6498 11.7735 16.5531 13.189 15.0433 14.2464C13.533 15.3044 11.8519 15.8334 10 15.8334ZM10 14.1803C11.4044 14.1803 12.6908 13.8033 13.8591 13.0494C15.0274 12.2959 15.9222 11.2795 16.5436 10.0001C15.9222 8.72068 15.0274 7.70399 13.8591 6.95003C12.6908 6.19657 11.4044 5.81984 10 5.81984C8.5956 5.81984 7.30924 6.19657 6.14096 6.95003C4.97268 7.70399 4.07782 8.72068 3.4564 10.0001C4.07782 11.2795 4.97268 12.2959 6.14096 13.0494C7.30924 13.8033 8.5956 14.1803 10 14.1803Z"
          fill="#7C7B7B"
        />
      </g>
    </svg>
  );
};
