/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const StyleFilled = ({ className }) => {
  return (
    <svg
      className={`style-filled ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M11.3333 2H4.66667C3.93333 2 3.34 2.6 3.34 3.33333L3.33333 14L8 12L12.6667 14V3.33333C12.6667 2.6 12.0667 2 11.3333 2Z"
        fill="black"
      />
    </svg>
  );
};
