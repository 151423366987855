/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons = ({ className }) => {
  return (
    <svg
      className={`icons ${className}`}
      fill="none"
      height="10"
      viewBox="0 0 12 10"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.01007 7.98488L7.06496 5.3654M8.10676 3.5759C7.8895 5.04645 6.52126 6.06244 5.05071 5.84518C3.58016 5.62792 2.56417 4.25968 2.78143 2.78913C2.99869 1.31858 4.36693 0.302589 5.83748 0.519849C7.30803 0.737108 8.32402 2.10535 8.10676 3.5759Z"
        stroke="#7C7B7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.961274"
      />
    </svg>
  );
};
