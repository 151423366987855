/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons1 = ({ className }) => {
  return (
    <svg
      className={`icons-1 ${className}`}
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8.84673 9.98513L6.90163 7.36564M7.94342 5.57614C7.72616 7.04669 6.35792 8.06268 4.88737 7.84542C3.41682 7.62816 2.40083 6.25992 2.61809 4.78937C2.83535 3.31882 4.20359 2.30283 5.67414 2.52009C7.14469 2.73735 8.16068 4.10559 7.94342 5.57614Z"
        stroke="#7C7B7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.961274"
      />
    </svg>
  );
};
