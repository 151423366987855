/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Component22 = ({ className }) => {
  return (
    <svg
      className={`component-2-2 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="24"
        id="mask0_2_348"
        maskUnits="userSpaceOnUse"
        style="mask-type:alpha"
        width="24"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="24" width="24" />
      </mask>
      <g className="g" mask="url(#mask0_2_348)">
        <path
          className="path"
          d="M5.625 20C5.15833 20 4.771 19.846 4.463 19.538C4.15433 19.2293 4 18.8417 4 18.375V5.625C4 5.15833 4.15433 4.771 4.463 4.463C4.771 4.15433 5.15833 4 5.625 4H15.575L20 8.425V18.375C20 18.8417 19.846 19.2293 19.538 19.538C19.2293 19.846 18.8417 20 18.375 20H5.625ZM5.625 19H18.375C18.5583 19 18.7083 18.9417 18.825 18.825C18.9417 18.7083 19 18.5583 19 18.375V9H15V5H5.625C5.44167 5 5.29167 5.05833 5.175 5.175C5.05833 5.29167 5 5.44167 5 5.625V18.375C5 18.5583 5.05833 18.7083 5.175 18.825C5.29167 18.9417 5.44167 19 5.625 19ZM7.5 16H16.5V15H7.5V16ZM7.5 9H12V8H7.5V9ZM7.5 12.5H16.5V11.5H7.5V12.5Z"
          fill="#696462"
        />
      </g>
    </svg>
  );
};
