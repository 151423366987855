/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "../NavLink";
import "./style.css";

export const Header = ({
  state,
  className,
  quickLinksClassName,
  humbleLogoClassName,
  humbleLogo = "/img/humble-logo-1-17.svg",
  frameClassName,
  navLinkContainerClassName,
  navLinkDivClassName,
  navLinkStateDefaultDarkClassName,
  navLinkContainerClassNameOverride,
  navLinkDivClassNameOverride,
  navLinkStateDefaultDarkClassNameOverride,
  navLinkContainerClassName1,
  navLinkDivClassName1,
  navLinkStateHoverDarkClassName,
  navLinkContainerClassName2,
  navLinkDivClassName2,
  navLinkStateHoverDarkClassNameOverride,
  frameClassNameOverride,
  divClassName,
  divContainerClassName,
  divClassNameOverride,
  searchClassName,
  search = "/img/search-11.svg",
  CTASClassName,
  buttonClassName,
  buttonBaseClassName,
  textClassName,
  img = "/img/humble-logo-1-16.svg",
  frameClassName1,
  divClassName1,
  frameClassName2,
  divClassName2,
  frameClassName3,
  divClassName3,
  frameClassName4,
  divClassName4,
  searchClassNameOverride,
  divPlaceholderClassName,
  search1 = "/img/search-10.svg",
}) => {
  return (
    <div className={`header ${state} ${className}`}>
      <div className="container-2">
        <div className={`div-2 ${quickLinksClassName}`}>
          <img
            className={`humble-logo ${humbleLogoClassName}`}
            alt="Humble logo"
            src={
              state === "secondary-dark"
                ? humbleLogo
                : state === "light"
                ? img
                : state === "secondary-light"
                ? "/img/humble-logo-1-15.svg"
                : "/img/humble-logo-1-18.svg"
            }
          />
          <div className={`frame-12 ${frameClassName}`}>
            {["primary-dark", "secondary-dark"].includes(state) && (
              <>
                <NavLink
                  className={navLinkStateDefaultDarkClassName}
                  containerClassName={navLinkContainerClassName}
                  dark
                  divClassName={navLinkDivClassName}
                  stateProp="default"
                  text="Products"
                />
                <NavLink
                  className={navLinkStateDefaultDarkClassNameOverride}
                  containerClassName={navLinkContainerClassNameOverride}
                  dark
                  divClassName={navLinkDivClassNameOverride}
                  stateProp="default"
                  text="Guides"
                />
                <NavLink
                  className={navLinkStateHoverDarkClassName}
                  containerClassName={navLinkContainerClassName1}
                  dark
                  divClassName={navLinkDivClassName1}
                  stateProp="default"
                  text="Topics"
                />
                <NavLink
                  className={navLinkStateHoverDarkClassNameOverride}
                  containerClassName={navLinkContainerClassName2}
                  dark
                  divClassName={navLinkDivClassName2}
                  stateProp="default"
                  text="Humble+"
                />
              </>
            )}

            {["light", "secondary-light"].includes(state) && (
              <>
                <div className={`frame-13 ${frameClassName1}`}>
                  <div className={`text-wrapper-4 ${divClassName1}`}>Products</div>
                </div>
                <div className={`frame-13 ${frameClassName2}`}>
                  <div className={`text-wrapper-5 ${divClassName2}`}>Guides</div>
                </div>
                <div className={`frame-13 ${frameClassName3}`}>
                  <div className={`text-wrapper-5 ${divClassName3}`}>Topics</div>
                </div>
                <div className={`frame-13 ${frameClassName4}`}>
                  <div className={`text-wrapper-6 ${divClassName4}`}>Humble+</div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={`div-2 ${frameClassNameOverride}`}>
          <div className={`frame-14 ${divClassName}`}>
            <div className="form-search-form">
              <div className={`div-container ${divContainerClassName}`}>
                <div className={`search ${searchClassNameOverride}`}>
                  <div className={`div-placeholder ${divPlaceholderClassName}`}>
                    <div className={`text-wrapper-7 ${divClassNameOverride}`}>Search</div>
                  </div>
                </div>
              </div>
            </div>
            <img
              className={`search-2 ${searchClassName}`}
              alt="Search"
              src={
                state === "secondary-dark"
                  ? search
                  : state === "light"
                  ? search1
                  : state === "secondary-light"
                  ? "/img/search-9.svg"
                  : "/img/search-12.svg"
              }
            />
          </div>
          <div className={`CTA-s ${CTASClassName}`}>
            <div className="text-wrapper-8">Sign In</div>
            <button className={`button-4 ${buttonClassName}`}>
              <button className={`button-base-4 ${buttonBaseClassName}`}>
                <div className={`text-5 ${textClassName}`}>Sign Up</div>
              </button>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  state: PropTypes.oneOf(["primary-dark", "secondary-light", "secondary-dark", "light"]),
  humbleLogo: PropTypes.string,
  search: PropTypes.string,
  img: PropTypes.string,
  search1: PropTypes.string,
};
