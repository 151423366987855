/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Divider = ({ mode, className, modeModerate = "/img/mode-moderate.svg" }) => {
  return (
    <img
      className={`divider ${className}`}
      alt="Mode dark"
      src={mode === "moderate" ? modeModerate : mode === "light" ? "/img/mode-light.svg" : "/img/mode-dark.svg"}
    />
  );
};

Divider.propTypes = {
  mode: PropTypes.oneOf(["dark", "moderate", "light"]),
  modeModerate: PropTypes.string,
};
