/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Component = ({
  property1,
  className,
  frameClassName,
  frame = "/img/frame-40379-5.svg",
  text = "Content",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <div
      className={`component property-1-${state.property1} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className={`frame-7 ${frameClassName}`}>
        <img className="frame-8" alt="Frame" src={state.property1 === "hover" ? "/img/frame-40379-4.svg" : frame} />
        <div className="frame-9">
          <div className="frame-10">
            <div className="content">{text}</div>
            <p className="text-wrapper-3">
              Discover content, tips, tricks across topics from curated creators and makers
            </p>
          </div>
          <button className="button-3">
            <div className="button-base-3">
              <div className="text-4">Explore Now</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "default",
      };
  }

  return state;
}

Component.propTypes = {
  property1: PropTypes.oneOf(["hover", "default"]),
  frame: PropTypes.string,
  text: PropTypes.string,
};
