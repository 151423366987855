/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconSearchFill = ({ className, iconSearchFill = "/img/icon-search-fill-false.png" }) => {
  return <img className={`icon-search-fill ${className}`} alt="Icon search fill" src={iconSearchFill} />;
};

IconSearchFill.propTypes = {
  iconSearchFill: PropTypes.string,
};
