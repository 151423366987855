/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Property1Frame40721 } from "../../icons/Property1Frame40721";
import "./style.css";

export const Toolbar = ({
  property1,
  propertyFrameClassName,
  frameClassName,
  closeClassName,
  close = "/img/close-1.png",
  frameClassNameOverride,
  imageWrapperClassName,
  imageClassName,
  image = "/img/image-3.png",
  codeWrapperClassName,
  codeClassName,
  code = "/img/code-1.png",
  playCircleWrapperClassName,
  playCircleClassName,
  playCircle = "/img/play-circle-3.png",
  dataObjectWrapperClassName,
  dataObjectClassName,
  dataObject = "/img/data-object-1.png",
}) => {
  return (
    <>
      {property1 === "frame-40721" && <Property1Frame40721 className="property-frame" />}

      {property1 === "frame-40765" && (
        <div className={`toolbar ${propertyFrameClassName}`}>
          <div className={`close-wrapper ${frameClassName}`}>
            <img className={`img-2 ${closeClassName}`} alt="Close" src={close} />
          </div>
          <div className={`frame-19 ${frameClassNameOverride}`}>
            <div className={`img-wrapper ${imageWrapperClassName}`}>
              <img className={`img-2 ${imageClassName}`} alt="Image" src={image} />
            </div>
            <div className={`img-wrapper ${codeWrapperClassName}`}>
              <img className={`img-2 ${codeClassName}`} alt="Code" src={code} />
            </div>
            <div className={`img-wrapper ${playCircleWrapperClassName}`}>
              <img className={`img-2 ${playCircleClassName}`} alt="Play circle" src={playCircle} />
            </div>
            <div className={`img-wrapper ${dataObjectWrapperClassName}`}>
              <img className={`img-2 ${dataObjectClassName}`} alt="Data object" src={dataObject} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Toolbar.propTypes = {
  property1: PropTypes.oneOf(["frame-40765", "frame-40721"]),
  close: PropTypes.string,
  image: PropTypes.string,
  code: PropTypes.string,
  playCircle: PropTypes.string,
  dataObject: PropTypes.string,
};
