/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Tab = ({
  stateProp,
  className,
  frameClassName,
  vector = "/img/vector-40-3.svg",
  img = "/img/vector-35-3.svg",
  vector1 = "/img/vector-36-3.svg",
  vector2 = "/img/vector-37-3.svg",
  vector3 = "/img/vector-38-3.svg",
  subtract = "/img/subtract-3.svg",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "default",
  });

  return (
    <div
      className={`tab ${state.state} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className={`frame ${frameClassName}`}>
        <div className="group-wrapper">
          <div className="group">
            <div className="overlap-group">
              <img className="vector" alt="Vector" src={state.state === "hover" ? "/img/vector-40-2.svg" : vector} />
              <img className="img" alt="Vector" src={state.state === "hover" ? "/img/vector-35-2.svg" : img} />
              <img className="vector-2" alt="Vector" src={state.state === "hover" ? "/img/vector-36-2.svg" : vector1} />
              <img className="vector-3" alt="Vector" src={state.state === "hover" ? "/img/vector-37-2.svg" : vector2} />
              <img className="vector-4" alt="Vector" src={state.state === "hover" ? "/img/vector-38-2.svg" : vector3} />
              <img className="subtract" alt="Subtract" src={subtract} />
            </div>
          </div>
        </div>
        <div className="div">
          <div className="frame-2">
            <div className="text-wrapper">Guide</div>
            <p className="p">Find out where you are today and let us help you get you where you want to go.</p>
          </div>
          <button className="button-base-wrapper">
            <div className="div-wrapper">
              <div className="text-2">Explore Now</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };
  }

  return state;
}

Tab.propTypes = {
  stateProp: PropTypes.oneOf(["hover", "default"]),
  vector: PropTypes.string,
  img: PropTypes.string,
  vector1: PropTypes.string,
  vector2: PropTypes.string,
  vector3: PropTypes.string,
  subtract: PropTypes.string,
};
