/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { ShopiconsRegularPlus4 } from "../../icons/ShopiconsRegularPlus4";
import "./style.css";

export const ShopiconsRegular = () => {
  return <ShopiconsRegularPlus4 className="shopicons-regular-plus-4" />;
};
