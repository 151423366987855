/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconOutlineArrowRight1 } from "../../icons/IconOutlineArrowRight1";
import "./style.css";

export const ButtonPrimaryWith = ({
  className,
  frameClassName,
  divClassName,
  text = "Button name",
  hasIcon = true,
}) => {
  return (
    <div className={`button-primary-with ${className}`}>
      <div className={`frame-11 ${frameClassName}`}>
        <div className={`button-name ${divClassName}`}>{text}</div>
        {hasIcon && (
          <div className="icon">
            <IconOutlineArrowRight1 className="icon-outline-arrow" />
          </div>
        )}
      </div>
    </div>
  );
};

ButtonPrimaryWith.propTypes = {
  text: PropTypes.string,
  hasIcon: PropTypes.bool,
};
