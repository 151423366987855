/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Property1Frame40721 = ({ className }) => {
  return (
    <svg
      className={`property-1-frame-40721 ${className}`}
      fill="none"
      height="68"
      viewBox="0 0 64 68"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" filter="url(#filter0_d_14_17141)">
        <rect className="rect" fill="#1F1C1A" height="48" rx="24" shapeRendering="crispEdges" width="48" />
        <mask
          className="mask"
          height="24"
          id="mask0_14_17141"
          maskUnits="userSpaceOnUse"
          style="mask-type:alpha"
          width="24"
          x="12"
          y="12"
        >
          <rect className="rect" fill="#D9D9D9" height="24" width="24" x="12" y="12" />
        </mask>
        <g className="g" mask="url(#mask0_14_17141)">
          <path className="path" d="M23 31V25H17V23H23V17H25V23H31V25H25V31H23Z" fill="white" />
        </g>
      </g>
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="80"
          id="filter0_d_14_17141"
          width="80"
          x="-16"
          y="-12"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="4" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="8" />
          <feComposite className="fe-composite" in2="hardAlpha" operator="out" />
          <feColorMatrix
            className="fe-color-matrix"
            type="matrix"
            values="0 0 0 0 0.579167 0 0 0 0 0.579167 0 0 0 0 0.579167 0 0 0 0.12 0"
          />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_14_17141" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_14_17141"
            mode="normal"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
