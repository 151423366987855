/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Component2 } from "../../icons/Component2";
import { Component21 } from "../../icons/Component21";
import { Component22 } from "../../icons/Component22";
import { Component23 } from "../../icons/Component23";
import "./style.css";

export const NavLabels = ({
  stateProp,
  theme,
  withIcon,
  className,
  containerClassName,
  divClassName,
  text = "Placeholder",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "selected",
    theme: theme || "dark",
    withIcon: withIcon,
  });

  return (
    <div
      className={`nav-labels with-icon-${state.withIcon} state-9-${state.state} theme-${state.theme} ${className}`}
      onClick={() => {
        dispatch("click");
      }}
    >
      {!state.withIcon && (
        <div className={`placeholder-wrapper ${containerClassName}`}>
          <div className={`placeholder-2 ${divClassName}`}>{text}</div>
        </div>
      )}

      {state.state === "selected" && state.withIcon && <Component2 className="instance-node" />}

      {state.withIcon && state.state === "hover" && <Component23 className="instance-node" />}

      {state.withIcon && ["hover", "selected"].includes(state.state) && (
        <div className="placeholder-wrapper">
          <div className="placeholder-3">{text}</div>
        </div>
      )}

      {state.state === "default" && state.withIcon && (
        <>
          <Component22 className="instance-node" />
          <div className="placeholder-wrapper">
            <div className="placeholder-4">{text}</div>
          </div>
        </>
      )}

      {state.state === "disabled" && state.withIcon && (
        <>
          <Component21 className="instance-node" />
          <div className="placeholder-wrapper">
            <div className="placeholder-5">{text}</div>
          </div>
        </>
      )}
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "click":
      return {
        ...state,
        state: "selected",
      };
  }

  return state;
}

NavLabels.propTypes = {
  stateProp: PropTypes.oneOf(["default", "disabled", "selected", "hover"]),
  theme: PropTypes.oneOf(["dark", "light"]),
  withIcon: PropTypes.bool,
  text: PropTypes.string,
};
