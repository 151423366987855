/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Upvote = ({
  property1,
  className,
  frameClassName,
  favoriteClassName,
  favorite = "/img/favorite-19.png",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <div
      className={`upvote property-1-6-${state.property1} ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onClick={() => {
        dispatch("click");
      }}
    >
      <div className={`frame-17 ${frameClassName}`}>
        {["default", "variant-3"].includes(state.property1) && (
          <>
            <img className={`favorite ${favoriteClassName}`} alt="Favorite" src={favorite} />
            <div className="i-use-this">
              {state.property1 === "default" && <>I use this</>}

              {state.property1 === "variant-3" && <>89</>}
            </div>
          </>
        )}

        {state.property1 === "selected" && (
          <div className="frame-18">
            <img className="favorite" alt="Favorite" src="/img/favorite-20.png" />
            <div className="text-wrapper-14">I use this</div>
          </div>
        )}
      </div>
    </div>
  );
};

function reducer(state, action) {
  if (state.property1 === "default") {
    switch (action) {
      case "mouse_enter":
        return {
          property1: "selected",
        };

      case "click":
        return {
          property1: "variant-3",
        };
    }
  }

  return state;
}

Upvote.propTypes = {
  property1: PropTypes.oneOf(["variant-3", "selected", "default"]),
  favorite: PropTypes.string,
};
