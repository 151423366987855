/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const NavLink = ({ stateProp, dark, className, containerClassName, divClassName, text = "Placeholder" }) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "default",
    dark: dark || true,
  });

  return (
    <div
      className={`nav-link ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
    >
      <div className={`container ${state.state} dark-${state.dark} ${containerClassName}`}>
        <div className={`placeholder ${divClassName}`}>{text}</div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };
  }

  return state;
}

NavLink.propTypes = {
  stateProp: PropTypes.oneOf(["disabled", "hover", "selected", "default"]),
  dark: PropTypes.bool,
  text: PropTypes.string,
};
