/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { StyleFilled } from "../../icons/StyleFilled";
import { Visibility } from "../../icons/Visibility";
import "./style.css";

export const ButtonBase = ({
  showRightIcon = true,
  showLeftIcon = true,
  text = "Label",
  className,
  contentClassName,
  divClassName,
}) => {
  return (
    <div className={`button-base ${className}`}>
      <div className={`content-2 ${contentClassName}`}>
        {showLeftIcon && <StyleFilled className="icon-instance-node" />}

        <div className={`label ${divClassName}`}>{text}</div>
        {showRightIcon && <Visibility className="icon-instance-node" />}
      </div>
    </div>
  );
};

ButtonBase.propTypes = {
  showRightIcon: PropTypes.bool,
  showLeftIcon: PropTypes.bool,
  text: PropTypes.string,
};
